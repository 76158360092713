import { colors } from 'settings/style';
import styled from 'styled-components';

export const SearchBarWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const SearchBarInputWrapper = styled.div`
  border-radius: 25px;
  display: flex;
  background-color: ${colors.primary300};
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  i {
    color: ${colors.font};
  }
  padding: 0.1rem 1rem;
  overflow: hidden;
  input,
  .ant-input {
    background-color: transparent;
    border: none;
    outline: none;
    &:focus,
    .ant-input-focused {
      border: none;
      box-shadow: none;
      outline: none;
    }
    ::placeholder {
      color: ${colors.font};
      opacity: 1;
    }
  }
`;
