import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { Tabs } from 'components/elements';
import { useGetMyAccountQuery } from 'graphql/types';
import { StyledWrapper, StyledTabsWrap, StyledLayout } from './index.styled';
import AccountRouter from './router';

export default function Account({ match }: RouteComponentProps) {
  const history = useHistory();

  const clickHandler = (path: string) => {
    history.push(`${match.url}${path}`);
  };

  const { data } = useGetMyAccountQuery();
  const accountTreeId = data?.myAccount?.data?.attributes?.myTreeId;

  return (
    <StyledWrapper>
      <StyledLayout>
        <StyledTabsWrap>
          <Tabs
            items={[
              { text: 'Profile', path: '/profile' },
              { text: 'Groups', path: '/groups' },
              { text: 'Events', path: '/events' },
              { text: 'Settings', path: '/settings' },
              { text: 'Messages', path: '/Messages' },
              { text: 'Family Tree', path: `/family-tree/${accountTreeId}` },
            ]}
            matchParent
            width="105px"
            height="100px"
            onClick={clickHandler}
          />
        </StyledTabsWrap>
        <AccountRouter url={match.url} />
      </StyledLayout>
    </StyledWrapper>
  );
}
