import React from 'react';
import { useGetUserByIdQuery } from 'graphql/types';
import { Avatar, Container } from 'components/elements';
import { dateFormat } from 'settings/configs';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import {
  StyledWrapper,
  StyledProfile,
  StyledHead,
  StyledContent,
  StyledImgWrap,
  StyledBlock,
  StyledName,
  StyledInfo,
  StyledText,
  StyledSubText,
  StyledNameBold,
  StyledTextWrap,
  InnerStyledWrapper,
} from './profile.styled';

type MatchParams = { id: string };
type PublicProfileProps = RouteComponentProps<MatchParams>;

const PublicProfile = (props: PublicProfileProps) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { data: profileResult } = useGetUserByIdQuery({
    variables: { id },
  });

  return (
    <Container>
      <StyledWrapper>
        <StyledHead>
          <StyledProfile>
            <InnerStyledWrapper>
              <StyledBlock>
                <StyledTextWrap>
                  <StyledName>{profileResult?.usersPermissionsUser?.data?.attributes?.firstname}</StyledName>
                  <StyledNameBold>{profileResult?.usersPermissionsUser?.data?.attributes?.lastname}</StyledNameBold>
                </StyledTextWrap>
              </StyledBlock>
              <StyledBlock>
                <StyledInfo>
                  <i className="icon-calendar2" />
                  <StyledText>Joined date : </StyledText>
                  <StyledSubText>
                    {moment(profileResult?.usersPermissionsUser?.data?.attributes?.createdAt).format(dateFormat)}
                  </StyledSubText>
                </StyledInfo>
              </StyledBlock>
              <StyledImgWrap>
                <Avatar avatarUrl={profileResult?.usersPermissionsUser?.data?.attributes?.avatarUrl} size="lg" />
              </StyledImgWrap>
            </InnerStyledWrapper>
          </StyledProfile>
        </StyledHead>
        <StyledContent />
      </StyledWrapper>
    </Container>
  );
};

export default PublicProfile;
