import { SliderList } from 'components/elements';
import UserList, { UserItem } from 'components/userList';
import React, { useMemo } from 'react';
import EventCard from 'components/eventCard';
import { EventEntity } from 'graphql/types';
import { StyledWrapper, StyledContent } from './singleGroupContent.styled';

type BasicEventType = EventEntity[];

interface SingleGroupContentProps {
  users?: UserItem[];
  events: BasicEventType;
}

const SingleGroupContent = ({ users, events }: SingleGroupContentProps) => {
  const groupEvents = useMemo(() => {
    return (events || []).reduce(
      (acc, event) => {
        if (event.attributes.isPast) {
          acc.past.push(event);
        } else {
          acc.upcoming.push(event);
        }
        return acc;
      },
      { past: [] as BasicEventType, upcoming: [] as BasicEventType },
    );
  }, [events]);

  return (
    <StyledWrapper>
      <StyledContent id="upCommingEvents">
        <SliderList
          renderList={(props) => <EventCard {...props?.attributes} bookings={props?.attributes?.bookings?.data} />}
          title="New events Coming up"
          items={groupEvents.upcoming}
          loading={!events}
        />
      </StyledContent>
      <StyledContent id="pastEvents">
        <SliderList
          renderList={(props) => <EventCard {...props?.attributes} bookings={props?.attributes?.bookings?.data} />}
          title="Past events"
          items={groupEvents.past}
          loading={!events}
        />
      </StyledContent>
      <StyledContent id="members">
        <UserList items={users} size={3} bgColor="primary" title="Members" />
      </StyledContent>
    </StyledWrapper>
  );
};

export default SingleGroupContent;
