import React, { useMemo, useState, useEffect, useCallback } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { Authenticatable } from 'components/elements';
import {
  useGetEventByIdQuery,
  useUserTokenInfoQuery,
  useEventRsvpMutation,
  useEventInvitationRsvpMutation,
  useVisitorInvitationBookingLazyQuery,
  useRemoveRsvpMutation,
} from 'graphql/types';
import { loginModalVisible } from 'cache';
import ButtonComponent from 'components/elements/button';
import { InputNumber, message } from 'antd';
import {
  StyledHeadDetail,
  StyledGuestText,
  StyledGuestCount,
  StyledGuestWrap,
  AttendingStatus,
  StyledSeatsLeft,
  GuestInvitationTitle,
  VisitorInvitaionResponseTitle,
} from './singleEventHead.styled';

interface RsvpEventFormProps {
  eventId: string;
  remainingSeats?: number;
}

const bookingBtnMessage = {
  pending: 'waiting for approval',
  rejected: 'RSVP Not approved',
  approved: '',
};
export default function RsvpEventForm({ eventId, remainingSeats }: RsvpEventFormProps) {
  const [eventRsvp, { loading }] = useEventRsvpMutation();
  const [removeRsvp, { loading: removeRsvpLoading }] = useRemoveRsvpMutation();
  const [invitationRsvp, { loading: invitationRsvpLoading }] = useEventInvitationRsvpMutation();
  const [getVisitorBooking, { data: visitorBooking }] = useVisitorInvitationBookingLazyQuery();

  const { search } = useLocation();
  const searchQuery = useMemo(() => qs.parse(search), [search]);
  const visitorToken = searchQuery.token as string;

  useEffect(() => {
    if (visitorToken) {
      getVisitorBooking({ variables: { eventId, visitorToken } });
    }
  }, [eventId, getVisitorBooking, visitorToken]);

  const [guestInput, setGuestInput] = useState(0);
  const [visitorRsvpForm, setVisitorRsvpForm] = useState(false);

  const userTokenInfoResult = useUserTokenInfoQuery();
  const userId = userTokenInfoResult?.data?.userTokenInfo?.id;
  const eventByIdResult = useGetEventByIdQuery({
    variables: { id: eventId },
  });
  const bookings = eventByIdResult?.data?.event?.data?.attributes?.bookings?.data;

  const currentBooking = useMemo(() => {
    return (bookings || []).find((booking) => String(booking.attributes.user?.data?.id) === String(userId));
  }, [userId, bookings]);

  useEffect(() => {
    const guest = currentBooking?.attributes?.guests;
    if (guest) {
      setGuestInput(guest);
    }
  }, [currentBooking]);

  const onVisitorRsvp = useCallback(
    async (going: boolean) => {
      try {
        await invitationRsvp({
          variables: { input: { guests: guestInput, going }, eventId, visitorToken },
        });
        setVisitorRsvpForm(false);
      } catch (err) {
        if (err instanceof Error) {
          message.error(err.message);
        }
      }
    },
    [invitationRsvp, guestInput, eventId, visitorToken],
  );

  const onEventRsvp = useCallback(async () => {
    try {
      await eventRsvp({
        variables: { input: { guests: guestInput }, eventId },
      });
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
      }
    }
  }, [eventRsvp, guestInput, eventId]);

  const onRemoveEventRsvp = useCallback(async () => {
    try {
      await removeRsvp({
        variables: { bookingId: currentBooking.id },
      });
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
      }
    }
  }, [currentBooking, removeRsvp]);

  const renderRemainingSeat = () => {
    if (!!remainingSeats && remainingSeats !== 0) {
      return (
        <StyledSeatsLeft>
          {remainingSeats === 0 ? 'Event is Full' : `${remainingSeats} seats are left!`}
        </StyledSeatsLeft>
      );
    }
    return null;
  };

  const visitorBookingStatus = visitorBooking?.visitorInvitationBooking?.data?.attributes?.status;
  return (
    <StyledHeadDetail>
      {currentBooking ? (
        <StyledGuestText>{guestInput + 1} people attending</StyledGuestText>
      ) : (
        <>
          {renderRemainingSeat()}
          <StyledGuestWrap>
            <StyledGuestText>Guest :</StyledGuestText>
            <StyledGuestCount>
              <InputNumber value={guestInput} onChange={setGuestInput} />
            </StyledGuestCount>
          </StyledGuestWrap>
        </>
      )}
      {visitorBooking?.visitorInvitationBooking?.data ? (
        <div>
          <GuestInvitationTitle>You are invited to this event</GuestInvitationTitle>

          {visitorBookingStatus === 'pending' || visitorRsvpForm ? (
            <>
              <GuestInvitationTitle>Will you attend?</GuestInvitationTitle>
              <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 20 }}>
                <ButtonComponent
                  block
                  type="primary"
                  onClick={() => onVisitorRsvp(true)}
                  loading={invitationRsvpLoading}
                  style={{ marginRight: 10 }}
                >
                  Yes
                </ButtonComponent>
                <ButtonComponent
                  block
                  type="primary"
                  danger
                  onClick={() => onVisitorRsvp(false)}
                  loading={invitationRsvpLoading}
                >
                  No
                </ButtonComponent>
              </div>
            </>
          ) : (
            <>
              <VisitorInvitaionResponseTitle>
                You replied{' '}
                {visitorBookingStatus === 'approved' ? (
                  <span className="approved">Yes</span>
                ) : (
                  <span className="rejected">No</span>
                )}
              </VisitorInvitaionResponseTitle>
              <ButtonComponent
                block
                type="primary"
                onClick={() => setVisitorRsvpForm(true)}
                loading={invitationRsvpLoading}
                style={{ marginRight: 10 }}
              >
                Change Reply
              </ButtonComponent>
            </>
          )}
        </div>
      ) : (
        <>
          <Authenticatable
            title="For attend to the Event you need to login first!"
            okText="Login"
            onConfirm={() => loginModalVisible(true)}
          >
            <ButtonComponent
              type="primary"
              onClick={currentBooking ? onRemoveEventRsvp : onEventRsvp}
              loading={loading || removeRsvpLoading}
            >
              {currentBooking ? 'Cancel' : 'Attend'}
            </ButtonComponent>
          </Authenticatable>
          <AttendingStatus>
            {(currentBooking && bookingBtnMessage[currentBooking.attributes.status]) || ''}
          </AttendingStatus>
        </>
      )}
    </StyledHeadDetail>
  );
}
