import React from 'react';
import { Button } from 'components/elements';
import StyledButton from './addBoxButton.styled';

interface AddBoxButtonProp {
  onClick?: () => void;
  btnText?: string;
}

const AddBoxButton = ({ onClick = () => {}, btnText }: AddBoxButtonProp) => {
  return (
    <StyledButton onClick={onClick}>
      {btnText && (
        <Button onClick={onClick} type="primary" block>
          {btnText}
        </Button>
      )}
      <i className="icon-expanded-add-circle" />
    </StyledButton>
  );
};

export default AddBoxButton;
