import React, { useRef, useCallback, useEffect, useState } from 'react';
import { loadMapApi, parseGoogleAddress, ParseGoogleAddressResult } from 'helpers';
import { Address } from 'graphql/types';

interface SearchBoxProps {
  placeholder?: string;
  onChange?: (place: ParseGoogleAddressResult) => void;
  value?: Address;
}

function SearchBox({ onChange = () => {}, value, placeholder = 'Enter your Location Address ...' }: SearchBoxProps) {
  const input = useRef<HTMLInputElement>(null);
  const searchBox = useRef<any>();
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const onPlacesChanged = useCallback(() => {
    const places = searchBox.current.getPlaces();
    const result = parseGoogleAddress(places[0]);
    if (result) {
      onChange(result);
    }
  }, [onChange]);

  useEffect(() => {
    const { script, loaded } = loadMapApi();
    const setScript = () => {
      setScriptLoaded(true);
    };
    if (loaded) {
      setScript();
    } else {
      script.addEventListener('load', setScript);
    }
  }, []);

  useEffect(() => {
    if (scriptLoaded && input.current && (window as any)?.google && (window as any)?.google?.maps?.places) {
      searchBox.current = (window as any).google && new (window as any).google.maps.places.SearchBox(input.current);
      searchBox.current.addListener('places_changed', onPlacesChanged);
    }
  }, [onPlacesChanged, scriptLoaded]);

  return (
    <input
      ref={input}
      className="ant-input css-dev-only-do-not-override-17z9ld4"
      placeholder={placeholder}
      type="text"
      defaultValue={value?.address}
    />
  );
}

export default SearchBox;
