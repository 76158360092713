import React, { useState, useMemo } from 'react';
import { AlbumSlider, ImageInput, VideoImportFromUrl } from 'components/elements';
import { ComponentMediaGallery } from 'graphql/types';
import { Popconfirm } from 'antd';
import { ImageInputAction } from 'components/elements/imageInput';
import { PhotoType } from 'components/elements/albumSlider/albumSlider';
import { PlayCircleOutlined } from '@ant-design/icons';
import { MediaStatusEnum } from 'graphql/types';

import {
  StyledWrapper,
  StyledItem,
  StyledImg,
  StyledUploadWrap,
  StyledUploadContainer,
  MediaUploadButton,
  StyledTitle,
  StyledHead,
  StyledAlbumWrap,
  ItemRemoveButton,
  VideoPlaceHolder,
  StyledItemWrapper,
  ApproveButton,
  PendingApproval,
} from './singleEventAlbum.styled';

interface SingleEventAlbumProp {
  gallery: ComponentMediaGallery[];
  onImport?: ImageInputAction;
  removePhoto?: (mediaId: string) => void;
  approvePhoto?: (mediaId: string) => void;
  uploadLoading?: boolean;
  removeLoading?: boolean;
  isEventOrganizer?: boolean;
  userId?: string;
}

const SingleEventAlbum = ({
  gallery = [],
  onImport,
  uploadLoading = false,
  removePhoto,
  approvePhoto,
  removeLoading = false,
  isEventOrganizer,
  userId,
}: SingleEventAlbumProp) => {
  const [ModalComponent, openVideoImportModal] = VideoImportFromUrl({ onChange: (data) => onImport(null, data) });
  const photos = useMemo(() => {
    const media: PhotoType[] = [];
    if (gallery && gallery.length) {
      gallery.forEach((item) => {
        item.media.forEach((mediaItem) => {
          const isApproved = mediaItem?.status === MediaStatusEnum.Approved;
          const hasMediaAccess = isEventOrganizer || item.owner.data.id === userId;

          if (isApproved || hasMediaAccess) {
            media.push({
              id: mediaItem?.id,
              url: mediaItem?.image?.data?.attributes?.url,
              ownerId: item.owner.data.id,
              ownerName: item.owner.data.attributes.username,
              caption: mediaItem.caption,
              mediaDate: mediaItem.date,
              videoUrl: mediaItem.videoUrl,
              isApproved,
            });
          }
        });
      });
    }
    return media;
  }, [gallery, isEventOrganizer, userId]);

  // const [modalVisible, setModal] = useState(false);
  const [removeRequestId, setRemoveRequestId] = useState('');
  const [showSlider, setShowSlider] = useState(false);
  const [selectedSlideIndex, setSliderIndex] = useState(0);
  const toggleSlider = () => {
    setShowSlider(!showSlider);
  };

  // const onConfirmCropping = () => {
  //   setModal(false);
  // };

  const onPhoto = (index?: number) => {
    toggleSlider();
    setSliderIndex(index);
  };
  const renderRemoveButton = (ownerId: string, mediaId: string) => {
    const hasRemoveAccess = isEventOrganizer || ownerId === userId;
    const loading = removeRequestId === mediaId && removeLoading;
    if (!loading && hasRemoveAccess) {
      return (
        <Popconfirm
          title="Are you Sure you want to Remove this Media?"
          placement="top"
          onConfirm={(e) => {
            e.stopPropagation();
            setRemoveRequestId(mediaId);
            removePhoto(mediaId);
          }}
          okText="Confirm"
          cancelText="Cancel"
          onCancel={(e) => {
            e.stopPropagation();
          }}
        >
          <ItemRemoveButton
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <i className="icon-close" />
          </ItemRemoveButton>
        </Popconfirm>
      );
    }
    return null;
  };
  const renderApproveButton = (ownerId: string, mediaId: string) => {
    const hasApproveAccess = isEventOrganizer;
    if (hasApproveAccess) {
      return (
        <ApproveButton
          onClick={(e) => {
            e.stopPropagation();
            approvePhoto && approvePhoto(mediaId);
          }}
        >
          Approve
        </ApproveButton>
      );
    }
    return <PendingApproval>Pending for Approve</PendingApproval>;
  };

  return (
    <StyledWrapper>
      <StyledHead>
        <StyledTitle>Pictures</StyledTitle>
        {/* TODO: this button should generate one time link and send out for upload image from users are not RSVPed to the event */}
        {/* <Button type="primary" ghost size="large" onClick={() => {}} leftIcon="icon-plus">
          Generate upload link
        </Button> */}
      </StyledHead>

      <StyledAlbumWrap>
        {onImport && (
          <StyledUploadContainer>
            <StyledUploadWrap>
              <ImageInput
                width={500}
                height={500}
                cropping={false}
                onChange={onImport}
                disabled={uploadLoading}
                withInfo
              >
                <MediaUploadButton>Upload photo</MediaUploadButton>
              </ImageInput>
              <MediaUploadButton onClick={() => openVideoImportModal()}>Import Video</MediaUploadButton>
              {ModalComponent}
            </StyledUploadWrap>
          </StyledUploadContainer>
        )}

        {/* <StyledUploadContainer>
          <StyledUploadWrap>
            <ImportFromURLButton type="primary"  size="md" onClick={() => setModal(true)}>
              Create a new collection
            </ImportFromURLButton>
            <Modal
              title="Create a new collection"
              open={modalVisible}
              onRightText="Confirm"
              onCancel={() => setModal(false)}
              onRight={onConfirmCropping}
            >
              <Input style={{ marginBottom: '10px' }} placeholder="Choose a name for a collection" />
              <StyledUploadContainer>
                <StyledUploadWrap style={{ display: 'flex', justifyContent: 'center' }}>
                  <ImageInput
                    width={300}
                    height={300}
                    cropping={false}
                    onChange={() => {}}
                    disabled={uploadLoading}
                    withEditor
                  >
                    <UploadImage>Choose a cover photo</UploadImage>
                  </ImageInput>
                </StyledUploadWrap>
              </StyledUploadContainer>
            </Modal>
          </StyledUploadWrap>
        </StyledUploadContainer> */}

        {photos.map(({ id, url, ownerId, videoUrl, isApproved }, idx) => {
          return (
            <StyledItem key={id} onClick={() => onPhoto(idx)}>
              {renderRemoveButton(ownerId, id)}
              {!isApproved && renderApproveButton(ownerId, id)}
              <StyledItemWrapper isApproved={isApproved}>
                {videoUrl ? (
                  <VideoPlaceHolder>
                    <PlayCircleOutlined />
                  </VideoPlaceHolder>
                ) : (
                  <StyledImg src={url} />
                )}
              </StyledItemWrapper>
            </StyledItem>
          );
        })}
      </StyledAlbumWrap>
      <AlbumSlider
        photos={photos}
        toggleSlider={toggleSlider}
        showSlider={showSlider}
        defaultIndex={selectedSlideIndex}
      />
    </StyledWrapper>
  );
};

export default SingleEventAlbum;
