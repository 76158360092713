import { colors, fontSize, spacer } from 'settings/style';
import styled from 'styled-components';
import { media } from '../../../settings/style';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 80vh;
  padding: 2.5rem;
`;

export const StyledSlider = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #1e1f21;
  max-height: 100%;
  ${media.md`
    display: block;
  `}
`;

export const StyledThumbSlider = styled.div`
  position: relative;
  display: flex;
  height: 20%;
  ${media.xs`
     height: 16%;
   `}
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-wrapper {
    width: 50%;
  }
  .swiper-slide {
    background-color: ${colors.black};
    text-align: center;
    width: auto;
  }
  .slide-image {
    height: 400px;
    width: auto;
  }
  .my-gallery figure {
    margin: 0px;
  }
`;

export const StyledArrow = styled.div`
  border: 1px solid ${colors.gray1600};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  margin: ${spacer.md};
  transform: translateY(-50%);
  z-index: 30;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    opacity: 0.7;
  }
  &.nextElm {
    right: 0;
  }
  &.prevElm {
    left: 0;
  }
  i {
    color: ${colors.gray1600};
    font-size: ${fontSize.xl};
    transform: translateY(5%);
  }
  ${media.xs`
    width: 30px;
    height: 30px;
    i{
          font-size: ${fontSize.base};
    }
  `}
`;

export const StyledImg = styled.img`
  display: block;
  object-fit: contain;
  margin: 0px auto;
  overflow: hidden;
  transition: all 0.2s;
`;

export const VideoPlaceHolder = styled.div`
  width: 100%;
  height: 100%;
  background: ${`linear-gradient(200deg, ${colors.red100} 20%,${colors.primary300} 50%)`};
  padding: 8rem;
  cursor: pointer;
  .anticon {
    font-size: 80px;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    color: ${colors.primary400};
    &:hover {
      color: ${colors.primary500};
    }
  }
`;

export const StyledVideoCont = styled.div`
  padding: 0 4rem;
  height: 100%;
  width: 100%;
`;

export const ImagePreviewInfo = styled.div`
  display: 'block';
  width: '40%';
  color: ${colors.white};
`;

export const Description = styled.div`
  display:flex;
  flex-direction:column; 
  width:40%;
  height:100%;
  padding: 1.5rem;
  margin :1.5rem
  border: '1px solid gray';
`;
