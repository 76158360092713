import React from 'react';

import { Button } from 'components/elements';
import { AddressEntityResponse, EventTypeEnum } from 'graphql/types';
import { eventDateTimeFormat } from 'settings/configs';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import {
  StyledWrapper,
  StyledImgWrap,
  StyledContent,
  StyledBottom,
  StyledBottomWrap,
  StyledBottomText,
  StyledBottomCount,
  StyledBottomContent,
  StyledImgContent,
  StyledImgDateWrap,
  StyledImgTitle,
  StyledImgDate,
} from './card.styled';

interface EventCardProps {
  coverPhotoUrl?: string;
  title?: string;
  address?: AddressEntityResponse;
  startTime?: string;
  id?: string;
  editable?: boolean;
  eventType?: EventTypeEnum;
  bookings?: { id?: string }[];
}

const EventCardButtons = ({ id = '1' }) => {
  const history = useHistory();

  return (
    <>
      <Button
        type="primary"
        ghost
        onClick={(e?: React.MouseEvent) => {
          if (e) {
            e.preventDefault();
          }
          history.push(`/account/events/update/${id}`);
        }}
      >
        Edit
      </Button>
      <Button
        type="primary"
        ghost
        onClick={(e?: React.MouseEvent) => {
          if (e) {
            e.preventDefault();
          }
          history.push(`/account/events/bookings/${id}`);
        }}
      >
        Manage Bookings
      </Button>
    </>
  );
};

const EventCard = ({ id, eventType, coverPhotoUrl, title, address, startTime, editable, bookings }: EventCardProps) => {
  const renderLocation = () => {
    const result = [];
    if ((EventTypeEnum.Physical === eventType || EventTypeEnum.Both === eventType) && address) {
      result.push(
        `${address?.data?.attributes?.country ? address?.data?.attributes?.country : ''}${
          address?.data?.attributes?.city ? `, ${address?.data?.attributes?.city}` : ''
        }`,
      );
    }
    if (EventTypeEnum.Virtual === eventType || EventTypeEnum.Both === eventType) {
      result.push('Online');
    }
    return result.join(' | ');
  };

  return (
    <StyledWrapper to={`/events/${id}`}>
      <StyledImgWrap url={coverPhotoUrl}>
        <StyledImgContent>
          <StyledImgDateWrap>
            <i className="icon-calendar2" />
            <StyledImgDate>{moment(startTime).format(eventDateTimeFormat)}</StyledImgDate>
          </StyledImgDateWrap>
        </StyledImgContent>
      </StyledImgWrap>
      <StyledContent>
        <StyledImgTitle>{title}</StyledImgTitle>
        <StyledBottom>
          <StyledBottomContent>
            <StyledBottomWrap>
              <i className="icon-pin" />
              <StyledBottomText>{renderLocation()}</StyledBottomText>
            </StyledBottomWrap>
            {editable && (
              <StyledBottomWrap>
                <StyledBottomText>{bookings ? bookings.length : ''}</StyledBottomText>
                <StyledBottomCount>Members going</StyledBottomCount>
              </StyledBottomWrap>
            )}
          </StyledBottomContent>
          {editable && <EventCardButtons id={id} />}
        </StyledBottom>
      </StyledContent>
    </StyledWrapper>
  );
};
export default EventCard;
