import { InMemoryCache, makeVar } from '@apollo/client';
import { AUTH_TOKEN } from 'constant';
import jwt from 'jwt-decode';
import LocalStore from 'helpers/localStore';

export type UserRoleType = 'user' | 'admin';
export type UserTokenType = { id: string };

const defaultToken = LocalStore.get(AUTH_TOKEN);
let defaultUser;
try {
  defaultUser = jwt(defaultToken || '') as UserTokenType;
} catch (err) {
  //
}
export interface BlogContent {
  id: string;
  slug: string;
  status: 'publish' | 'pending';
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
}

export type BlogContentResult = BlogContent[];

export const loggedInState = makeVar(!!defaultToken);
export const loginModalVisible = makeVar(false);
export const signupModalVisible = makeVar(false);
export const forgotPasswordModalVisible = makeVar(false);
export const userTokenInfo = makeVar<UserTokenType>(defaultUser || ({} as UserTokenType));
export const blogContent = makeVar<BlogContentResult>([]);

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        loggedInState: {
          read() {
            return loggedInState();
          },
        },
        loginModalVisible: {
          read() {
            return loginModalVisible();
          },
        },
        signupModalVisible: {
          read() {
            return signupModalVisible();
          },
        },
        forgotPasswordModalVisible: {
          read() {
            return forgotPasswordModalVisible();
          },
        },
        userTokenInfo: {
          read() {
            return userTokenInfo();
          },
        },
        blogContent: {
          read() {
            return blogContent();
          },
        },
      },
    },
  },
});
