import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useGetAllPostsQuery } from 'graphql/types';
import { NotFound } from 'components/elements';
import moment from 'moment';
import { StyledWrapper, StyledImgTitle, StyledContent, StyledPostDate } from './index.styled';

type MatchParams = { slug: string };
type SinglePost = RouteComponentProps<MatchParams>;

export default function PostsView(props: SinglePost) {
  const {
    match: {
      params: { slug },
    },
  } = props;

  const { data: postResult, loading } = useGetAllPostsQuery({
    variables: { filters: { slug: { eq: slug } } },
  });

  const selectedGroupResult = useMemo(() => {
    return postResult?.posts.data && postResult?.posts.data.length ? postResult?.posts.data[0]?.attributes : null;
  }, [postResult]);

  if (loading) {
    return <StyledWrapper />;
  }
  console.log(selectedGroupResult?.content, 'xxxx');

  return selectedGroupResult ? (
    <StyledWrapper>
      <StyledImgTitle>{selectedGroupResult?.title}</StyledImgTitle>
      <StyledPostDate>
        {selectedGroupResult?.author?.data?.attributes?.username
          ? `Author: ${selectedGroupResult?.author?.data?.attributes?.username}  |  `
          : ''}
        Last Update: {moment(selectedGroupResult?.updatedAt).format('ll')}
      </StyledPostDate>
      <StyledContent>{ReactHtmlParser(selectedGroupResult?.content || '')}</StyledContent>
    </StyledWrapper>
  ) : (
    <StyledWrapper>
      <NotFound>Blog Post Not Found!</NotFound>
    </StyledWrapper>
  );
}
