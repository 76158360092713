import { spacer, radius, colors, fontType, snippet } from 'settings/style';
import styled from 'styled-components';

export const StyledItem = styled.div`
  display: inline-flex !important;
  flex-flow: column;
  height: 100%;
  width: 100%;
  padding-bottom: ${spacer.xl};
  border-radius: ${radius.lg};
`;

export const StyledWrapper = styled.div``;

export const StyledTitle = styled.span`
  color: ${colors.font};
  margin-bottom: ${spacer.xl};
  ${fontType.lg}
  ${snippet.underline()}
`;

export const SwiperWrapper = styled.div`
  position: relative;
`;
export const NavController = styled.div`
  position: absolute;
  top: calc(50% - 0.75rem - 30px);
  z-index: 2;
  width: 23px;
  height: 60px;
  right: 0;
  border-radius: ${radius.lg} 0 0 ${radius.lg};
  background-color: ${colors.gray300};
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0.7;
  &.swiper-button-disabled {
    display: none;
  }
  &:hover {
    opacity: 1;
  }
  &:last-of-type {
    left: 0;
    border-radius: 0 ${radius.lg} ${radius.lg} 0;
  }
`;
