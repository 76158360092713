import React, { useState } from 'react';
import { Input, Form, message } from 'antd';
import { Modal, FormItem, ListItemInput } from 'components/elements';
import { emailValidator } from 'helpers';
import { useSendEventInvitationMutation } from 'graphql/types';

const { TextArea } = Input;

interface InvitationModalProps {
  modalVisible?: boolean;
  setModal?: (visible: boolean) => void;
  eventId: string;
}

interface messageProps {
  email: {
    valueLists: string[];
    currentValue: string;
  };
  subject: string;
  message: string;
}

const InvitationModal = ({ modalVisible, setModal, eventId }: InvitationModalProps) => {
  const [form] = Form.useForm();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [sendEventInvitation, { loading: sendInvitationLoading }] = useSendEventInvitationMutation();

  const onFinish = async (values?: messageProps) => {
    try {
      await sendEventInvitation({
        variables: {
          eventId,
          input: {
            emails: values.email.valueLists,
            message: values.message,
            subject: values.subject,
          },
        },
      });
      setModal(false);
      form.resetFields();
      message.success('Messages Sent your invitation to All emails successfully!');
    } catch (err) {
      if (err instanceof Error) {
        message.error(err?.message);
      }
    }
  };

  return (
    <>
      <Modal
        title="New message "
        open={modalVisible}
        onRightText="Confirm"
        onCancel={() => setModal(false)}
        onRight={() => form.submit()}
        onRightProps={{ loading: sendInvitationLoading }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish} validateTrigger="onChange">
          <FormItem
            theme="dark"
            label="Recipients"
            name="email"
            rules={[
              () => ({
                validator(_, value) {
                  if ((!!value.valueLists && value.valueLists.length) || !!value.currentValue) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Email cannot be empty'));
                },
              }),
              () => ({
                validator(_, value) {
                  if (emailValidator(value.currentValue)) {
                    setIsValidEmail(true);
                    return Promise.resolve();
                  }
                  setIsValidEmail(false);
                  return Promise.reject(new Error('Email is incorrect format!'));
                },
              }),
            ]}
            initialValue={{
              currentValue: '',
              valueLists: [],
            }}
          >
            <ListItemInput isValid={isValidEmail} />
          </FormItem>
          <FormItem
            theme="dark"
            label="Subject"
            name="subject"
            rules={[{ required: true, message: 'Please input the subject of the invitation!' }]}
          >
            <Input />
          </FormItem>

          <FormItem
            theme="dark"
            label="Invitation Message"
            name="message"
            rules={[{ required: true, message: 'Please fill invitation message ...' }]}
          >
            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} bordered={false} style={{ color: 'white' }} />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default InvitationModal;
