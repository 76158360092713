import React from 'react';
import { Modal, FormItem } from 'components/elements';
import { Input, Form, message } from 'antd';
import { loginModalVisible, signupModalVisible, forgotPasswordModalVisible } from 'cache';
import { useForgotPasswordVisibilityQuery, useForgotPasswordMutation } from 'graphql/types';

interface ResetPasswordFormField {
  email: string;
}

function Login() {
  const [loginForm] = Form.useForm();
  const useForgotPasswordVisibilityResult = useForgotPasswordVisibilityQuery();
  const [forgotPassword, { loading }] = useForgotPasswordMutation();

  const onFinish = async (values: ResetPasswordFormField) => {
    try {
      await forgotPassword({
        variables: { email: values.email },
      });
      forgotPasswordModalVisible(false);
      message.success('Reset link sent to your email!');
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
      }
    }
  };

  const toSignup = () => {
    forgotPasswordModalVisible(false);
    signupModalVisible(true);
  };

  const toLogin = () => {
    forgotPasswordModalVisible(false);
    loginModalVisible(true);
  };

  return (
    <Modal
      title="Reset Your Password"
      open={useForgotPasswordVisibilityResult.data?.forgotPasswordModalVisible}
      onRight={() => loginForm.submit()}
      onRightProps={{ loading: loading }}
      onRightText="Reset Password"
      onLeft={[toSignup, toLogin]}
      onLeftText={['I don’t have account', 'I already have an account']}
      onCancel={() => forgotPasswordModalVisible(false)}
      destroyOnClose
    >
      <Form layout="vertical" form={loginForm} onFinish={onFinish} preserve={false}>
        <FormItem
          name="email"
          label="Email"
          theme="dark"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your Email!',
            },
          ]}
        >
          <Input placeholder="Enter your email" />
        </FormItem>
      </Form>
    </Modal>
  );
}

export default Login;
