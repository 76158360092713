import React, { useMemo, useEffect, useCallback } from 'react';
import { useGetAllPostsLazyQuery, useGetAllPostCategoriesQuery, GetAllPostsQueryVariables } from 'graphql/types';
import { PaginatingItems, Container, SearchSidebar } from 'components/elements';
import styled from 'styled-components';
import PostItem from 'components/postItem';
import { WithSiderWrapper } from 'components/elements/searchSidebar';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { spacer } from '../../settings/style';

const pageSize = 10;

export default function PostsView() {
  const [getPosts, { data: postsResult, loading: postsLoading }] = useGetAllPostsLazyQuery();
  const { data: categoriesResult, loading: categoriesLoading } = useGetAllPostCategoriesQuery();
  const categories = useMemo(
    () =>
      (categoriesResult?.postCategories?.data || []).map((category) => ({
        label: category?.attributes?.name,
        value: category.id,
        parentId: null,
      })),
    [categoriesResult],
  );

  const { search } = useLocation();
  const history = useHistory();
  const searchQuery = useMemo(() => qs.parse(search, { arrayFormat: 'bracket' }), [search]);

  const updatePageParams = (searchParams: any) => {
    history.push(`/posts?${qs.stringify(searchParams, { arrayFormat: 'bracket' })}`);
  };

  const getPagePost = useCallback(
    (page: number) => {
      const searchFilter = {
        sort: 'updatedAt:desc',
        pagination: { page, pageSize },
      } as GetAllPostsQueryVariables;
      const searchFilterProps = {} as any;
      if (searchQuery?.categories) {
        searchFilterProps.categories = { id: { in: searchQuery?.categories } };
      }
      if (searchQuery?.search) {
        searchFilterProps.title = { containsi: searchQuery?.search as string };
      }

      getPosts({ variables: { ...searchFilter, filters: searchFilterProps } });
    },
    [getPosts, searchQuery],
  );

  useEffect(() => {
    getPagePost(1);
  }, [getPagePost, getPosts, searchQuery]);

  const postsData = postsResult?.posts?.data || [];
  const pagination = postsResult?.posts?.meta?.pagination;

  return (
    <Container>
      <WithSiderWrapper>
        <SearchSidebar
          searchDefaultValue={(searchQuery.search as string) || ''}
          onSearch={(value) => updatePageParams({ ...searchQuery, search: value })}
          filterByCategories={[
            {
              title: 'category',
              key: 'category',
              onChange: (values) => updatePageParams({ ...searchQuery, categories: values }),
              options: categories,
              defaultValue: (searchQuery.categories as string[]) || [],
            },
          ]}
        />
        <EventsListWrapper>
          <PaginatingItems
            items={postsData}
            loading={postsLoading || categoriesLoading}
            renderList={(props) => <PostItem {...props?.attributes} />}
            perView={1}
            pageSize={pagination?.pageSize}
            total={pagination?.total}
            current={pagination?.page}
            onChange={(page) => getPagePost(page)}
          />
        </EventsListWrapper>
      </WithSiderWrapper>
    </Container>
  );
}

const EventsListWrapper = styled.div`
  padding: ${spacer.xl};
  flex: 1;
`;
