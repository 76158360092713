import React from 'react';

import { Button, ListItem } from 'components/elements';
import {
  StyledHead,
  StyledTitle,
  StyledContent,
  StyledCount,
  StyledWrap,
  StyledItem,
  StyledHeadWrap,
  StyledLinkText,
} from './userList.styled';

export type UserItem = {
  id?: string;
  firstname?: string;
  lastname?: string;
  avatarUrl?: string;
  username?: string;
};

export interface UserListProp {
  items?: UserItem[];
  bgColor?: 'primary' | 'accent100';
  title: string;
  size?: number;
}

const UserList = ({ size = 2, items = [], bgColor, title }: UserListProp) => {
  return (
    <>
      <StyledHead>
        <StyledContent>
          <StyledTitle>{title}</StyledTitle>
          <StyledHeadWrap bgColor={bgColor}>
            <i className="icon-user" />
            <StyledCount>{items.length}</StyledCount>
          </StyledHeadWrap>
        </StyledContent>
        <StyledHeadWrap>
          <Button type="link" color="font" onClick={() => {}}>
            <StyledLinkText>See all</StyledLinkText>
            <i className="icon-chevron-right" />
          </Button>
        </StyledHeadWrap>
      </StyledHead>
      <StyledWrap>
        {items.map(
          (item) =>
            item?.id && (
              <StyledItem size={size} key={item.id}>
                <ListItem
                  bgColor={bgColor}
                  avatarUrl={item.avatarUrl}
                  title={`${item.firstname} ${item.lastname}`}
                  id={item.id}
                />
              </StyledItem>
            ),
        )}
      </StyledWrap>
    </>
  );
};

export default UserList;
