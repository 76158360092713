import { useGetMyAccountQuery } from 'graphql/types';

export default function useEventAccessInfo(eventId: string) {
  const result = useGetMyAccountQuery();
  const events = result?.data?.myAccount?.data?.attributes?.events || [];
  const hasActiveBooking = (result?.data?.myAccount?.data?.attributes?.bookings?.data || []).some(
    (booking) => booking.attributes.event.data.id === String(eventId) && booking.attributes.status === 'approved',
  );

  return {
    hasActiveBooking,
    isEventOrganizer: events.some((event) => String(event.id) === String(eventId)),
    userId: result?.data?.myAccount?.data.id,
  };
}
