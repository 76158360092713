import React from 'react';
import qs from 'query-string';
import { Button, FormItem } from 'components/elements';
import { Input, Form } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import useResetPassword from 'hooks/useResetPassword';
import { ResetPassword } from 'services/auth';
import { StyledWrapper, StyledTitle } from './settings.styled';

const ResetAccountPassword = () => {
  const { search } = useLocation();
  const history = useHistory();
  const parsed = qs.parse(search);
  const [loading, onResetPassword] = useResetPassword();
  const [changePassForm] = Form.useForm();

  const onSuccess = () => {
    history.push('/account');
  };

  const onFinish = (values: ResetPassword) => {
    const { code, password, passwordConfirmation } = values;
    onResetPassword({ code, password, passwordConfirmation }, onSuccess);
  };

  return (
    <StyledWrapper>
      <section>
        <StyledTitle>Reset Password</StyledTitle>
        <Form layout="vertical" form={changePassForm} onFinish={onFinish} preserve={false}>
          <FormItem
            style={{ display: 'none' }}
            theme="primary"
            name="code"
            initialValue={parsed?.code}
            label="Code"
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
            ]}
            hasFeedback
          >
            <Input />
          </FormItem>
          <FormItem
            theme="primary"
            name="password"
            label="New password"
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </FormItem>
          <FormItem
            theme="primary"
            name="passwordConfirmation"
            label="Confirm new password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your new password!',
              },
              ({ getFieldValue }: { getFieldValue: any }) => ({
                validator(rule: any, value: string) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </FormItem>
          <Button onClick={() => changePassForm.submit()} type="primary" loading={loading}>
            Confirm
          </Button>
        </Form>
      </section>
      <section />
    </StyledWrapper>
  );
};

export default ResetAccountPassword;
