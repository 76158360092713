import React, { useMemo, useCallback, useState } from 'react';
import { v4 } from 'uuid';
import { Skeleton, Row, Col, Pagination, PaginationProps } from 'antd';
import { StyledWrapper, FooterWrapper, StyledTitle } from './paginatingItems.styled';

const rows = [1, 2, 3, 4, 6];

function WrapperCol({ perView, children }: { perView: number; children: React.ReactNode }) {
  const previewIndex = rows.indexOf(perView) < 0 ? 3 : rows.indexOf(perView);
  const lg = rows[previewIndex];
  const md = rows[previewIndex - 1] < 0 ? 0 : rows[previewIndex - 1];
  return (
    <Col xs={24} md={24 / md} lg={24 / lg}>
      {children}
    </Col>
  );
}

function LoadingCards(count: number) {
  return [...Array(count)].map(() => (
    <WrapperCol perView={count} key={v4()}>
      <Skeleton active title={false} avatar={{ shape: 'square' }} paragraph={{ rows: 4 }} />
    </WrapperCol>
  ));
}

interface PaginatingItemsProp<T> extends PaginationProps {
  items: T[];
  renderList: (item: T) => React.ReactNode;
  title?: string;
  perView?: number; // this is for showing the grid system
  loading?: boolean;
  pageSize?: number;
  firstItem?: React.ReactNode;
  controlledPaginating?: boolean; // means pagination handled from backend or it should be handle from front-end. its not good idea to handle pagination from front-end but this is just for long lists that we want to show in pages
}

function PaginatingItems<T>({
  items = [],
  title,
  renderList,
  perView = 4,
  loading = false,
  pageSize = 18,
  controlledPaginating,
  firstItem,
  ...paginationProps
}: PaginatingItemsProp<T>) {
  const [currentPage, setPage] = useState(1);

  const onPageChange = useCallback((page: any) => {
    setPage(page);
  }, []);

  const renderedListItems = useMemo(() => {
    if (!controlledPaginating) {
      return items;
    }
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;

    return items.slice(start, end);
  }, [controlledPaginating, currentPage, items, pageSize]);

  return (
    <StyledWrapper>
      {title && <StyledTitle>{title}</StyledTitle>}
      <Row gutter={[24, 24]}>
        {firstItem && <WrapperCol perView={perView}>{firstItem}</WrapperCol>}
        {loading
          ? LoadingCards(perView)
          : renderedListItems.map((item) => (
              <WrapperCol key={v4()} perView={perView}>
                {renderList(item)}
              </WrapperCol>
            ))}
      </Row>
      <FooterWrapper>
        {controlledPaginating ? (
          <Pagination pageSize={pageSize} total={items.length} current={currentPage} onChange={onPageChange} />
        ) : (
          <Pagination pageSize={pageSize} {...paginationProps} />
        )}
      </FooterWrapper>
    </StyledWrapper>
  );
}

export default PaginatingItems;
