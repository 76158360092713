import React from 'react';
import { FormItem, SearchBox, EditorInput } from 'components/elements';
import { Input, Select, Form } from 'antd';
import { EventTypeEnum } from 'graphql/types';

function InformationStep() {
  return (
    <>
      <FormItem
        name="name"
        label="Title"
        theme="primary"
        rules={[
          {
            required: true,
            message: 'Please input your Email!',
          },
        ]}
      >
        <Input placeholder="Enter Your Group Title" />
      </FormItem>
      <FormItem
        name="groupType"
        label="What is your Group Type"
        theme="primary"
        rules={[
          {
            required: true,
            message: 'Please Select your Group Type!',
          },
        ]}
      >
        <Select
          options={[
            { label: 'Physical Event', value: EventTypeEnum.Physical },
            { label: 'Virtual Event', value: EventTypeEnum.Virtual },
            { label: 'Both', value: EventTypeEnum.Both },
          ]}
        />
      </FormItem>
      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.groupType !== currentValues.groupType}>
        {({ getFieldValue }) => {
          const groupType = getFieldValue('groupType');

          if ([EventTypeEnum.Physical, EventTypeEnum.Both].includes(groupType)) {
            return (
              <FormItem
                name="address"
                label="Location Address"
                theme="primary"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Location Address!',
                  },
                ]}
              >
                <SearchBox />
              </FormItem>
            );
          }
          return null;
        }}
      </Form.Item>
      <FormItem
        name="description"
        label="Description"
        theme="primary"
        fullRounded={false}
        rules={[
          {
            required: true,
            message: 'Please input your Email!',
          },
        ]}
      >
        <EditorInput />
      </FormItem>
    </>
  );
}

export default InformationStep;
