import React, { useCallback } from 'react';
import { Checkbox } from 'antd';
import { useCategoriesQuery, CategoriesQuery } from 'graphql/types';
import CategoryCollapseTree from '../categoryCollapseTree';

interface CheckboxOption {
  label: string;
  description?: string;
  value: string;
}

interface DetailedCheckboxProps {
  onChange?: (ids: string[]) => void;
  options: CheckboxOption[];
  defaultValue?: string[];
  disabled?: boolean;
  value?: string[];
}

type Category = CategoriesQuery['categories']['data'][0];

function DetailedCheckbox({ onChange = () => {}, value = [] }: DetailedCheckboxProps) {
  const { data } = useCategoriesQuery();

  const onValueChange = useCallback(
    (id: string) => {
      if (value.includes(id)) {
        onChange(value.filter((item) => item !== id));
      } else {
        onChange([...value, id]);
      }
    },
    [onChange, value],
  );

  const renderCategoryCheckbox = (category: Category) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ marginBottom: '15px', marginRight: '25px' }}>{category.attributes.description}</p>
        <Checkbox onClick={() => onValueChange(category.id)} checked={value.includes(category.id)} key={category.id} />
      </div>
    );
  };

  return <CategoryCollapseTree categories={data?.categories?.data || []} renderItem={renderCategoryCheckbox} />;
}

export default DetailedCheckbox;
