import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { AUTH_TOKEN } from 'constant';
import LocalStore from 'helpers/localStore';
import { message } from 'antd';

const skipErrors = [{ path: 'myAccount', code: 401 }];
function shouldSkipError(err) {
  if (!err || !err.code || !err.path || !err.path.length) {
    return false;
  }
  return skipErrors.some((skip) => skip.code === err.code && err.path.includes(skip.path));
}

export const authLink = new ApolloLink((operation, forward) => {
  const token = LocalStore.get(AUTH_TOKEN);
  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }));
  return forward(operation);
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // const token = LocalStore.get(AUTH_TOKEN);
    // const isTokenExpired = token && graphQLErrors.some(({ code, status }) => code === 401 || status === 'unuthorized');
    // if (isTokenExpired) {
    //   return new Observable((observer) => {
    //     refreshToken()
    //       .then((newToken) => {
    //         operation.setContext(({ headers = {} }) => ({
    //           headers: {
    //             ...headers,
    //             Authorization: `Bearer ${newToken}` || null,
    //           },
    //         }));
    //       })
    //       .then(() => {
    //         const subscriber = {
    //           next: observer.next.bind(observer),
    //           error: observer.error.bind(observer),
    //           complete: observer.complete.bind(observer),
    //         };
    //         // Retry last failed request
    //         forward(operation).subscribe(subscriber);
    //       })
    //       .catch((error) => {
    //         // No refresh or client token available, we force user to login
    //         observer.error(error);
    //       });
    //   });
    // }
    graphQLErrors.forEach((err) => {
      if (err.message && !shouldSkipError(err)) {
        // message.error(err.message);
      }
    });
  }
  if (networkError) {
    message.error('Network error');
  }
  return null;
});
