import React, { useState, useEffect } from 'react';
import { TabBar, SafeArea } from 'antd-mobile';
import Logo from 'assets/images/logo-light.svg';
import styled, { css } from 'styled-components';
import { rgba, colors, spacer, fontWeight, media } from 'settings/style';
import { Container, Button } from 'components/elements';
import { Drawer } from 'antd';
import { NavLink, Link, useHistory, useLocation } from 'react-router-dom';
import LoginModal from 'components/login';
import SginupModal from 'components/signup';
import ForgotPasswordModal from 'components/forgotPassword';
import { loginModalVisible, signupModalVisible } from 'cache';
import { logout } from 'services/auth';
import { useLoggedInStateQuery, useGetMyAccountLazyQuery } from 'graphql/types';
import { HomeOutlined, FileTextOutlined, CalendarOutlined, SearchOutlined, GlobalOutlined } from '@ant-design/icons';

const headerRoutes = [
  { name: 'Home', path: '/', icon: <HomeOutlined /> },
  { name: 'Calendar', path: '/calendar', icon: <CalendarOutlined /> },
  { name: 'Explore Groups', path: '/groups', icon: <GlobalOutlined /> },
  { name: 'Explore Events', path: '/events', icon: <SearchOutlined /> },
  { name: 'Blog Posts', path: '/posts', icon: <FileTextOutlined /> },
];

export default function Header({ light = true }) {
  const useLoggedInStateResult = useLoggedInStateQuery();
  const isLoggedIn = useLoggedInStateResult.data?.loggedInState;
  const history = useHistory();
  const location = useLocation();

  const [drawer, setDrawer] = useState(false);
  const [getMyProfile] = useGetMyAccountLazyQuery();

  const setRouteActive = (value: string) => {
    history.push(value);
  };

  useEffect(() => {
    if (isLoggedIn) {
      getMyProfile();
    }
  }, [getMyProfile, isLoggedIn]);

  const onSignup = () => {
    signupModalVisible(true);
  };

  const onLogin = () => {
    loginModalVisible(true);
  };

  return (
    <HeaderWrapper light={light}>
      <Drawer title="Basic Drawer" placement="left" closable={false} onClose={() => setDrawer(false)} open={drawer}>
        <Menu light />
      </Drawer>
      <div className="mobile-menu">
        <TabBar onChange={(value) => setRouteActive(value)} activeKey={location.pathname}>
          {headerRoutes.map((item) => (
            <TabBar.Item key={item.path} icon={item.icon} title={item.name} />
          ))}
        </TabBar>
        <SafeArea position="bottom" />
      </div>

      <Container>
        <LoginModal />
        <SginupModal />
        <ForgotPasswordModal />
        <div className="header-row">
          {/* <Button type="link" className="hamburger-menu" onClick={() => setDrawer(true)}>
            <ThreeBarsIcon size="medium" />
          </Button> */}
          <div className="logo-cont">
            <Link to="/">
              <img src={Logo} alt="polgozar" />
            </Link>
          </div>
          <nav className="menu-container">
            <Menu light={light} />
            <div className="login-signup">
              {isLoggedIn ? (
                <Button type="link" onClick={() => logout()}>
                  Logout
                </Button>
              ) : (
                <Button type="link" onClick={onLogin}>
                  Login
                </Button>
              )}
              {isLoggedIn ? (
                <Button type="primary" onClick={() => history.push('/account')}>
                  My Profile
                </Button>
              ) : (
                <Button type="primary" onClick={onSignup}>
                  Join us
                </Button>
              )}
            </div>
          </nav>
        </div>
      </Container>
    </HeaderWrapper>
  );
}

interface MenuProps {
  light: boolean;
}

function isActive(path: string) {
  return (match: any, location: any) => {
    if (!location) return false;
    const { pathname } = location;
    return pathname === path;
  };
}

const Menu = ({ light }: MenuProps) => {
  return (
    <ul className="menu-links">
      {headerRoutes.map((item) => (
        <li className="menu-links__item" key={item.name}>
          <MenuLink isActive={isActive(item.path)} to={item.path} light={light} activeClassName="selected">
            {item.name}
          </MenuLink>
        </li>
      ))}
    </ul>
  );
};

const MenuLink = styled(({ light, ...props }) => <NavLink {...props} />)`
  color: ${({ light }) => (light ? colors.white : colors.primary)};
  font-weight: ${fontWeight.bold};
  padding: ${spacer.sm};
  padding-bottom: ${spacer.sm};
  &:hover {
    color: ${colors.accent};
  }
  &.selected {
    color: ${colors.accent};
  }
`;

interface HeaderWrapperProps {
  light: boolean;
}

const HeaderWrapper = styled.header<HeaderWrapperProps>`
  ${({ light }) => css`
    position: ${light ? 'absolute' : 'relative'};
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: ${spacer.lg} 0;
    background-color: ${light ? 'transparent' : colors.gray600};

    .mobile-menu {
      display: none;
    }
    ${media.md`
      .mobile-menu{
        display: block;
        position: fixed;
        bottom: 0;
        background-color: white;
        width: 100%;
        padding: 10px 0; 
        border-top: 1px solid ${colors.gray200};
      }
    `}
    .header-row {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo-cont {
        ${media.lg`
          flex: 1;
        `}
        img {
          width: 11rem;
          margin: auto;
        }
      }
      .hamburger-menu {
        ${media.lg`
          flex: 1;
          text-align: left;
        `}
        display: none;
        color: white;
        ${media.lg`
          display: block;
        `}
      }
      .menu-container {
        .login-signup {
          align-items: center;
          display: flex;
        }
        ${media.lg`
          flex: 1;
          justify-content: flex-end;
        `}

        ${light &&
        css`
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: ${rgba(colors.accent, 0.4)};
            position: absolute;
            bottom: -${spacer.md};
            ${media.lg`
              height: 0;
            `}
          }
        `}

        display: flex;
        .menu-links {
          ${media.lg`
            display: none;
          `}
          border-right: 1px solid ${light ? colors.white : colors.primary};

          display: flex;
          .menu-links__item {
            margin: 0 ${spacer.sm};
          }
        }
      }
    }
  `}
`;
