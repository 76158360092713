import React from 'react';
import styled from 'styled-components';
import { radius, colors, breakpoints, spacer, shadow, fontSize, rgba } from 'settings/style';
import { Container } from 'components/elements';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCategoriesQuery } from 'graphql/types';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Paragraph } = Typography;

const sliderBreakpoints = {
  [breakpoints.xs]: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  [breakpoints.lg]: {
    slidesPerView: 3,
    spaceBetween: 30,
  },
  [breakpoints.lg]: {
    slidesPerView: 4,
    spaceBetween: 40,
  },
  [breakpoints.xl]: {
    slidesPerView: 5,
    spaceBetween: 40,
  },
};

const CategoriesList = () => {
  const { data } = useCategoriesQuery();
  const parentCategories = (data?.categories?.data || [])
    .filter((category) => !category?.attributes?.parent?.data)
    .sort((a, b) => (a?.attributes?.order > b?.attributes?.order ? 1 : -1));

  return (
    <CategoriesWrapper>
      <Container>
        <div className="item-containers">
          <Swiper spaceBetween={10} slidesPerView={1} breakpoints={sliderBreakpoints}>
            {data &&
              parentCategories.map((category) => (
                <SwiperSlide key={category?.attributes?.slug}>
                  <CategoriesItem
                    key={category?.attributes?.slug}
                    img={category?.attributes?.coverPhotoUrl || ''}
                    to={`/category/${category.id}`}
                  >
                    <div className="img-container">
                      <div className="title">{category?.attributes?.title} </div>
                    </div>
                    <Paragraph ellipsis={{ rows: 7, expandable: false }}>{category?.attributes?.description}</Paragraph>
                  </CategoriesItem>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </Container>
    </CategoriesWrapper>
  );
};

export default CategoriesList;

const CategoriesWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.05);
  color: ${colors.white};
  padding: ${spacer.lg};
  .item-containers {
    padding-top: 200px;
    overflow: hidden;
    margin-top: -200px;
  }
  .swiper-container {
    overflow: visible;
  }
`;

interface CategoriesItemProps {
  img: string;
}

const CategoriesItem = styled(Link)<CategoriesItemProps>`
  background: ${colors.gray600};
  position: relative;
  min-height: 150px;
  border-radius: ${radius.lg};
  padding: ${spacer.sm};
  font-size: ${fontSize.sm};
  color: ${colors.white};
  .ant-typography {
    color: ${colors.white};
  }
  .img-container {
    transition: transform 0.8s ease-in-out;
    border-radius: ${radius.lg};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: ${shadow.md};
    overflow: hidden;
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat, repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .title {
      padding: ${spacer.sm} ${spacer.md};
      background-color: ${rgba(colors.gray600, 0.9)};
      border-radius: ${radius.xl};
    }
  }
  &:hover {
    color: ${colors.white};
    .img-container {
      transform: translateY(-100%);
    }
  }
`;
