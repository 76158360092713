import React, { useState } from 'react';
import { Button } from 'components/elements';
import { message } from 'antd';
import { useForgotPasswordMutation, useGetMyAccountQuery } from 'graphql/types';
import { StyledWrapper, StyledTitle, StyledText } from './settings.styled';

export default function ResetPassword() {
  const [forgotPassword, { loading }] = useForgotPasswordMutation();
  const { data: accountResult } = useGetMyAccountQuery();

  const [codeSent, setCodeSent] = useState(false);

  const onPasswordRequest = async () => {
    const email = accountResult?.myAccount?.data?.attributes?.email;
    try {
      await forgotPassword({
        variables: { email },
      });
      setCodeSent(true);
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
      }
    }
  };

  return (
    <StyledWrapper>
      <StyledTitle>Change Password</StyledTitle>
      <div>
        {codeSent ? (
          <StyledText>Verification Code for Change password sent to your email address</StyledText>
        ) : (
          <Button onClick={onPasswordRequest} type="primary" loading={loading}>
            Change Password Request
          </Button>
        )}
      </div>
    </StyledWrapper>
  );
}
