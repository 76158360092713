import React, { useState } from 'react';
import Logo from 'assets/images/logo-light.svg';
import blurLogo from 'assets/images/footer_blur_logo.svg';
import styled from 'styled-components';
import { rgba, colors, spacer, fontSize } from 'settings/style';
import { Container, Button, Modal } from 'components/elements';
import ZCalendar from 'components/zCalendar';
import { Row, Col } from 'antd';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import DonateForm from 'components/donateForm';

const Footer = () => {
  const [donateModalVisible, setDonateModalVisible] = useState(false);

  return (
    <FooterWrapper>
      <Modal
        open={donateModalVisible}
        onCancel={() => {
          setDonateModalVisible(false);
        }}
      >
        <DonateForm paypalEmail="admin@polgozar.org" onFormSubmitted={() => setDonateModalVisible(false)} />
      </Modal>
      <Container>
        <Row className="content" gutter={[24, 24]}>
          <Col xs={24} md={7}>
            <div className="logo-cont">
              <img src={Logo} alt="polgozar" />
            </div>
            <div className="details-wrapper">
              <ul>
                <li>
                  <MailOutlined />
                  <a href="mailto: contact@polgozar.org">Contact us: contact@polgozar.org</a>
                </li>
                <li>
                  <PhoneOutlined />
                  <a href="tel:+1916-792-2512">Call us: +1 916-792-2512</a>
                </li>
              </ul>
              <div className="donate-btn-cont">
                <Button type="primary" ghost onClick={() => setDonateModalVisible(true)}>
                  Donate here
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <ZCalendar />
          </Col>
          <Col xs={24} md={9}>
            <p>
              Polgozār is an online Daremehr for meeting friends and sharing stories, scholarship, celebrations, and
              rituals, thus strengthening our Zoroastrian community and identity.
            </p>
          </Col>
        </Row>
      </Container>
      <div className="copy-right-Wrapper">
        <Container>
          <div className="copy-right-container">
            <span>&copy; Copyright 2022 Polgozar</span>
            <div className="social-media">
              <a href="https://www.instagram.com/polgozar.z/">
                <i className="icon-instagram" />
              </a>
              <a href="/#">
                <i className="icon-facebook" />
              </a>
              <a href="/#">
                <i className="icon-twitter" />
              </a>
            </div>
          </div>
        </Container>
      </div>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.footer`
  border-top: 0.4rem solid ${colors.accent};
  background-color: ${colors.gray900};
  color: ${rgba(colors.white, 0.5)};
  a {
    color: ${rgba(colors.white, 0.5)};
  }
  .content {
    padding: 2rem 0;
    position: relative;
    .logo-cont {
      width: 200px;
    }
    &:before {
      content: url(${blurLogo});
      position: absolute;
      left: -1rem;
      top: 1.5rem;
      filter: blur(0.4375rem);
    }
    ul {
      padding: 0;
      padding-top: ${spacer.lg};
      li {
        padding-top: ${spacer.sm};
        i,
        .anticon {
          padding-right: ${spacer.md};
        }
      }
    }
    .donate-btn-cont {
      margin-top: ${spacer.xl};
    }
    .details-wrapper {
      padding-left: ${spacer.lg};
    }
  }
  .copy-right-Wrapper {
    background-color: ${rgba(colors.gray600, 0.1)};
    .copy-right-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${spacer.lg};
      .social-media {
        a {
          i {
            color: ${rgba(colors.white, 0.5)};
            font-size: ${fontSize.xl};
          }
          &:hover i {
            color: ${rgba(colors.white, 0.8)};
          }
          padding-left: ${spacer.xl};
        }
      }
    }
  }
`;
