import { useCallback } from 'react';
import { useResetPasswordMutation } from 'graphql/types';
import { AUTH_TOKEN } from 'constant';
import LocalStore from 'helpers/localStore';
import { ResetPassword } from 'services/auth';
import { message } from 'antd';

function useResetPassword() {
  const [userResetPass, { loading }] = useResetPasswordMutation();
  const login = useCallback(
    async (data: ResetPassword, onSuccess: () => void = () => {}) => {
      try {
        const result = await userResetPass({
          variables: data,
        });
        const token = result?.data?.resetPassword?.jwt;
        if (!token) {
          throw new Error('Authenication Failed!');
        }
        LocalStore.set(AUTH_TOKEN, token);
        onSuccess();
      } catch (err) {
        LocalStore.remove(AUTH_TOKEN);
        if (err instanceof Error) {
          message.error(err.message);
        }
      }
    },
    [userResetPass],
  );
  return [loading, login] as const;
}

export default useResetPassword;
