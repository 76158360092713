import { spacer, colors, fontType, fontSize, fontWeight } from 'settings/style';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  padding: ${spacer.xl};
  margin: ${spacer.xl};
  ul {
    list-style: disc;
  }
`;

export const StyledImgTitle = styled.p`
  ${fontType.md};
  color: ${colors.font};
  padding-bottom: ${spacer.xs};
`;

export const StyledContent = styled.div`
  color: ${colors.font};
  padding-bottom: ${spacer.lg};
`;

export const StyledPostDate = styled.div`
  color: ${colors.font};
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.bold};
  padding-bottom: ${spacer.lg};
  font-style: italic;
`;
