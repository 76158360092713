import { spacer, fontSize, fontWeight, colors, radius, media } from 'settings/style';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface ListItemProp {
  bgColor?: 'primary' | 'accent100';
}
export const StyledWrapper = styled(Link)<ListItemProp>`
  padding: ${spacer.lg};
  position: relative;
  background-color: ${({ bgColor }) => (bgColor === 'primary' ? colors.primary : colors.accent100)};
  border-radius: ${radius.xl};
  color: ${colors.font};
  text-align: left;
  width: 90%;
`;

export const StyledAvatar = styled.div`
  position: absolute;
  left: 0;
  top: -30%;
`;

export const StlyedText = styled.span`
  display: inline-flex;
  margin-left: 85px;
  font-size: ${fontSize.md};
  font-weight: ${fontWeight.light};
  ${media.lg`
    margin-left: 60px;
    `}
`;
