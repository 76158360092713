import React from 'react';
import styled, { css } from 'styled-components';
import { Button as antButton, ButtonProps } from 'antd';

export interface ButtonComponentProps extends ButtonProps {
  leftIcon?: string | React.ReactElement;
  rightIcon?: string | React.ReactElement;
  primaryInfo?: boolean;
}
const ButtonComponent = ({ leftIcon, rightIcon, ...props }: ButtonComponentProps) => {
  const extraProps: ButtonProps = {};
  if (leftIcon) {
    extraProps.icon = typeof leftIcon === 'string' ? <i className={leftIcon} /> : leftIcon;
  }
  return (
    <Button {...props}>
      {props.children}
      {rightIcon && (
        <span className="rightIconWrapper">
          {typeof rightIcon === 'string' ? <i className={rightIcon} /> : rightIcon}
        </span>
      )}
    </Button>
  );
};

const Button = styled(antButton)<ButtonComponentProps>`
  ${({ primaryInfo, type }) =>
    primaryInfo &&
    type === 'primary' &&
    css`
      background-color: ${({ theme }) => theme.colorInfoActive};
      box-shadow: 0 2px 0 rgb(146 199 222 / 10%);
      &:not(:disabled):hover {
        background-color: ${({ theme }) => theme.colorInfo};
      }
    `}
`;

export default ButtonComponent;
