import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import ScrollToTop from 'hooks/useScrollTop';

import Home from 'containers/home';
import Category from 'containers/category';
import AccountPanel from 'containers/account';
import page404 from 'containers/Page/404';
import UseGlobalSync from 'hooks/useStoreSync';
import { useLoggedInStateQuery } from 'graphql/types';
import MainLayout from 'components/mainLayout';
import SingleGroup from 'containers/singleGroup';
import SingleEvent from 'containers/singleEvent';
import GroupsView from 'containers/groupsView';
import EventsView from 'containers/eventsView';
import PostsView from 'containers/postsView';
import SinglePostView from 'containers/singlePostView';
import PublicProfile from 'containers/publicProfile';
import CalendarView from 'containers/calendarView';
import ResetPassword from 'containers/resetPassword';
import PublicPage from 'containers/publicPage';

interface PrivateRouteProps extends RouteProps {
  component: React.FC<any>;
  layout?: React.ComponentType<any>;
}

type RenderComponent = (props?: any) => React.ReactNode;
const PrivateRoute = ({ component: WrapperComponent, layout: Layout, location, ...rest }: PrivateRouteProps) => {
  const useLoggedInStateQueryResult = useLoggedInStateQuery();

  const isUser = useLoggedInStateQueryResult.data?.loggedInState;
  const isAdmin = false;
  let comp: RenderComponent = () => (
    <Redirect
      to={{
        pathname: '/',
        state: { from: location },
      }}
    />
  );
  const { path } = rest;
  if ((isUser && path === '/account') || (isAdmin && path === '/admin')) {
    comp = (props) => {
      if (Layout) {
        return (
          <Layout {...props}>
            <WrapperComponent {...props} />
          </Layout>
        );
      }
      return <WrapperComponent {...props} />;
    };
  }
  return <Route {...rest} render={comp} />;
};

interface LayoutWrapperProps extends RouteProps {
  component: React.ComponentType<any>;
  layout: React.ComponentType<any>;
}

function LayoutWrapper({ component: Component, layout: Layout, ...rest }: LayoutWrapperProps) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export default function RouterWrapper() {
  UseGlobalSync();
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <LayoutWrapper exact path="/" component={Home} layout={MainLayout} />
        <LayoutWrapper exact path="/category/:id" component={Category} layout={MainLayout} />
        <LayoutWrapper exact path="/groups/:slug" component={SingleGroup} layout={MainLayout} />
        <LayoutWrapper exact path="/events/:id" component={SingleEvent} layout={MainLayout} />
        <LayoutWrapper exact path="/groups" component={GroupsView} layout={MainLayout} />
        <LayoutWrapper exact path="/events" component={EventsView} layout={MainLayout} />
        <LayoutWrapper exact path="/posts" component={PostsView} layout={MainLayout} />
        <LayoutWrapper exact path="/posts/:slug" component={SinglePostView} layout={MainLayout} />
        <LayoutWrapper exact path="/profile/:id" component={PublicProfile} layout={MainLayout} />
        <LayoutWrapper exact path="/calendar" component={CalendarView} layout={MainLayout} />
        <LayoutWrapper exact path="/reset-password" component={ResetPassword} layout={MainLayout} />
        <LayoutWrapper exact path="/page/:slug" component={PublicPage} layout={MainLayout} />
        <PrivateRoute path="/account" component={AccountPanel} layout={MainLayout} />
        <Route component={page404} />
      </Switch>
    </Router>
  );
}
