import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import page404 from 'containers/Page/404';
import Profile from './profile';
import Settings from './settings';
import CreateGroup from './createUpdateGroup/createGroup';
import UpdateGroup from './createUpdateGroup/updateGroup';
import CreateEvent from './createUpdateEvent/createEvent';
import UpdateEvent from './createUpdateEvent/updateEvent';
import ManageBookings from './bookings';
import ManageGroups from './manageGroup';
import Groups from './groups';
import Events from './events';
import ComingSoon from 'containers/Page/comingSoon';

const routes = [
  {
    path: 'profile',
    component: Profile,
    exact: true,
  },
  {
    path: 'settings',
    component: Settings,
    exact: true,
  },
  {
    path: 'events',
    component: Events,
    exact: true,
  },
  {
    path: 'groups',
    component: Groups,
    exact: true,
  },
  {
    path: 'family-tree/:id',
    component: ComingSoon,
    exact: true,
  },
  {
    path: 'messages',
    component: ComingSoon,
    exact: true,
  },
  {
    path: 'groups/create',
    component: CreateGroup,
    exact: true,
  },
  {
    path: 'groups/update/:id',
    component: UpdateGroup,
    exact: true,
  },
  {
    path: 'events/create',
    component: CreateEvent,
    exact: true,
  },
  {
    path: 'events/update/:id',
    component: UpdateEvent,
    exact: true,
  },
  {
    path: 'events/bookings/:id',
    component: ManageBookings,
    exact: true,
  },
  {
    path: 'groups/:id',
    component: ManageGroups,
    exact: true,
  },
];

interface AccountRouterProps {
  url: string;
}

const AccountRouter = ({ url }: AccountRouterProps) => {
  return (
    <Switch>
      <Redirect exact from="/account" to="account/profile" />
      {routes.map((singleRoute) => {
        const { path, exact, ...otherProps } = singleRoute;
        return <Route exact={!!exact} key={path} path={`${url}/${path}`} {...otherProps} />;
      })}
      <Route
        component={page404} // if no page matched
      />
    </Switch>
  );
};

export default AccountRouter;
