import styled from 'styled-components';
import { colors, fontType, spacer, radius } from 'settings/style';

const NotFound = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 120px;
  margin: ${spacer.xl};
  padding: ${spacer.xl};
  ${fontType.lg};
  border: 2px solid ${colors.primary};
  border-radius: ${radius.lg};
  justify-content: center;
  align-items: center;
`;

export default NotFound;
