import React, { useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { radius, colors, spacer, shadow, fontSize, rgba, snippet, fontType } from 'settings/style';
import { Container, Spinner, PaginatingItems } from 'components/elements';
import { useCategoriesQuery, useGetAllGroupsLazyQuery } from 'graphql/types';
import { Typography } from 'antd';
import { RouteComponentProps, Link } from 'react-router-dom';
import GroupCard from 'components/groupCard';

const { Paragraph } = Typography;

type MatchParams = { id: string };
type CategoryProps = RouteComponentProps<MatchParams>;

const pageSize = 18;

const SingleCategory = (props: CategoryProps) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { data } = useCategoriesQuery();

  const [getGroups, { data: groupResult, loading: groupLoading }] = useGetAllGroupsLazyQuery();

  const getGroupPage = useCallback(
    (page: number) => {
      getGroups({
        variables: {
          pagination: { page, pageSize },
          sort: 'createdAt:desc',
          filters: { categories: { id: { in: [id] } } },
        },
      });
    },
    [getGroups, id],
  );

  useEffect(() => {
    getGroupPage(1);
  }, [getGroupPage]);

  const groupsData = groupResult?.groups.data || [];
  const pagination = groupResult?.groups?.meta?.pagination;

  const selectedCategory = useMemo(() => {
    if (data && data.categories) {
      return data.categories.data.find((category) => String(category.id) === String(id));
    }
    return null;
  }, [data, id]);

  const subCategories = useMemo(() => {
    if (selectedCategory) {
      return selectedCategory.attributes.subCategories.data
        .map((item) => data.categories.data.find((cat) => String(cat.id) === String(item.id)))
        .sort((a, b) => (a.attributes.order > b.attributes.order ? 1 : -1));
    }
    return [];
  }, [selectedCategory, data]);

  return (
    <CategoriesWrapper>
      <Container>
        {selectedCategory ? (
          <div>
            <p className="headTitle">{selectedCategory.attributes.title}</p>
            <p className="description">{selectedCategory.attributes.description}</p>
            <div>
              {subCategories.map((category) => (
                <CategoriesItem
                  key={category.attributes.slug}
                  img={category.attributes.coverPhotoUrl || ''}
                  to={`/category/${category.id}`}
                >
                  <div className="img-container">
                    <div className="title">{category.attributes.title} </div>
                    <Paragraph ellipsis={{ rows: 7, expandable: false }}>{category.attributes.description}</Paragraph>
                  </div>
                </CategoriesItem>
              ))}
            </div>
            <p className="headTitle">Related Groups</p>
            <PaginatingItems
              items={groupsData}
              loading={groupLoading}
              renderList={GroupCard}
              perView={6}
              pageSize={pagination?.pageSize}
              total={pagination?.total}
              current={pagination?.page}
              onChange={(page) => getGroupPage(page)}
            />
          </div>
        ) : (
          <Spinner />
        )}
      </Container>
    </CategoriesWrapper>
  );
};

export default SingleCategory;

const CategoriesWrapper = styled.div`
  padding: ${spacer.lg};
  .headTitle {
    ${fontType.lg}
    ${snippet.underline()}
    margin-top: ${spacer.lg};
  }
  .description {
    margin-bottom: ${spacer.lg};
  }
  .exploreButton {
    margin-bottom: ${spacer.lg};
  }
`;

interface CategoriesItemProps {
  img: string;
}

const CategoriesItem = styled(Link)<CategoriesItemProps>`
  height: 200px;
  width: 350px;
  border-radius: ${radius.lg};
  font-size: ${fontSize.sm};
  color: ${colors.white};
  box-shadow: ${shadow.md};
  overflow: hidden;
  margin: ${spacer.md};
  &:first-of-type {
    margin-left: 0px;
  }
  .ant-typography {
    color: ${colors.white};
  }
  .img-container {
    height: 100%;
    width: 100%;
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat, repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .title {
      position: absolute;
      padding: ${spacer.sm} ${spacer.md};
      background-color: ${rgba(colors.gray600, 0.9)};
      border-radius: ${radius.xl};
    }
    .ant-typography {
      transition: 0.4s ease;
      padding: ${spacer.xl};
      opacity: 0;
      background-color: rgba(26, 26, 26, 0.68);
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
  &:hover {
    .title {
      display: none;
    }
    .ant-typography {
      opacity: 1;
    }
  }
`;
