import React from 'react';
import { InputNumber, Form, Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

const PrefixSelector = (
  <Form.Item name="prefix" noStyle initialValue="+1">
    <Select style={{ width: 80 }}>
      <Option value="+1">+1</Option>
      <Option value="+98">+98</Option>
    </Select>
  </Form.Item>
);

const formatToPhone = (value: string) => {
  const input = value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  if (input.length > 6) {
    return `(${areaCode}) ${middle} - ${last}`;
  }
  if (input.length > 3) {
    return `(${areaCode}) ${middle}`;
  }
  if (input.length > 0) {
    return `(${areaCode}`;
  }
  return '';
};

const PhoneInput = (props: any) => {
  return (
    <Wrapper>
      {PrefixSelector}
      <InputNumber
        formatter={(value) => formatToPhone(String(value))}
        parser={(value) => value.replace(/([^0-9])/g, '')}
        {...props}
      />
    </Wrapper>
  );
};

export default PhoneInput;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
