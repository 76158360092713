/* eslint-disable */
/* DO NOT CHANGE THIS FILE MANUALLY, This file is generated automaticly from yarn codegen */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  EventGalleryDynamicZoneInput: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: any;
  /** A time string with format HH:mm:ss.SSS */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Address = {
  __typename?: 'Address';
  address: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
};

export type AddressAttributes = {
  id?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type AddressEntity = {
  __typename?: 'AddressEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Address>;
};

export type AddressEntityResponse = {
  __typename?: 'AddressEntityResponse';
  data?: Maybe<AddressEntity>;
};

export type AddressEntityResponseCollection = {
  __typename?: 'AddressEntityResponseCollection';
  data: Array<AddressEntity>;
  meta: ResponseCollectionMeta;
};

export type AddressFiltersInput = {
  id?: Maybe<IdFilterInput>;
  address?: Maybe<StringFilterInput>;
  city?: Maybe<StringFilterInput>;
  zip?: Maybe<StringFilterInput>;
  country?: Maybe<StringFilterInput>;
  state?: Maybe<StringFilterInput>;
  lat?: Maybe<FloatFilterInput>;
  lng?: Maybe<FloatFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<AddressFiltersInput>>>;
  or?: Maybe<Array<Maybe<AddressFiltersInput>>>;
  not?: Maybe<AddressFiltersInput>;
};

export type AddressInput = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type BlogContent = {
  __typename?: 'BlogContent';
  id: Scalars['ID'];
  slug: Scalars['String'];
  status: Scalars['String'];
  title: Rendered;
  content: Rendered;
};

export type Booking = {
  __typename?: 'Booking';
  guests?: Maybe<Scalars['Int']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
  event?: Maybe<EventEntityResponse>;
  status: BookingStatusEnum;
  visitor?: Maybe<VisitorEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isPast?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type BookingEntity = {
  __typename?: 'BookingEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Booking>;
};

export type BookingEntityResponse = {
  __typename?: 'BookingEntityResponse';
  data?: Maybe<BookingEntity>;
};

export type BookingEntityResponseCollection = {
  __typename?: 'BookingEntityResponseCollection';
  data: Array<BookingEntity>;
  meta: ResponseCollectionMeta;
};

export type BookingFiltersInput = {
  id?: Maybe<IdFilterInput>;
  guests?: Maybe<IntFilterInput>;
  user?: Maybe<UsersPermissionsUserFiltersInput>;
  event?: Maybe<EventFiltersInput>;
  status?: Maybe<StringFilterInput>;
  visitor?: Maybe<VisitorFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<BookingFiltersInput>>>;
  or?: Maybe<Array<Maybe<BookingFiltersInput>>>;
  not?: Maybe<BookingFiltersInput>;
};

export type BookingInput = {
  guests?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  event?: Maybe<Scalars['ID']>;
  status?: Maybe<BookingStatusEnum>;
  visitor?: Maybe<Scalars['ID']>;
};

export type BookingRelationResponseCollection = {
  __typename?: 'BookingRelationResponseCollection';
  data: Array<BookingEntity>;
};

export enum BookingStatusEnum {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type BooleanFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  or?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  not?: Maybe<BooleanFilterInput>;
  eq?: Maybe<Scalars['Boolean']>;
  eqi?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  startsWith?: Maybe<Scalars['Boolean']>;
  endsWith?: Maybe<Scalars['Boolean']>;
  contains?: Maybe<Scalars['Boolean']>;
  notContains?: Maybe<Scalars['Boolean']>;
  containsi?: Maybe<Scalars['Boolean']>;
  notContainsi?: Maybe<Scalars['Boolean']>;
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  between?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export type Category = {
  __typename?: 'Category';
  title: Scalars['String'];
  slug: Scalars['String'];
  coverPhoto: UploadFileEntityResponse;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  description: Scalars['String'];
  order: Scalars['Long'];
  subCategories?: Maybe<CategoryRelationResponseCollection>;
  parent?: Maybe<CategoryEntityResponse>;
  groups?: Maybe<GroupRelationResponseCollection>;
  events?: Maybe<EventRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  coverPhotoUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type CategoryUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type CategorySubCategoriesArgs = {
  filters?: Maybe<CategoryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type CategoryGroupsArgs = {
  filters?: Maybe<GroupFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type CategoryEventsArgs = {
  filters?: Maybe<EventFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CategoryEntity = {
  __typename?: 'CategoryEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Category>;
};

export type CategoryEntityResponse = {
  __typename?: 'CategoryEntityResponse';
  data?: Maybe<CategoryEntity>;
};

export type CategoryEntityResponseCollection = {
  __typename?: 'CategoryEntityResponseCollection';
  data: Array<CategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryFiltersInput = {
  id?: Maybe<IdFilterInput>;
  title?: Maybe<StringFilterInput>;
  slug?: Maybe<StringFilterInput>;
  users?: Maybe<UsersPermissionsUserFiltersInput>;
  description?: Maybe<StringFilterInput>;
  order?: Maybe<LongFilterInput>;
  subCategories?: Maybe<CategoryFiltersInput>;
  parent?: Maybe<CategoryFiltersInput>;
  groups?: Maybe<GroupFiltersInput>;
  events?: Maybe<EventFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<CategoryFiltersInput>>>;
  or?: Maybe<Array<Maybe<CategoryFiltersInput>>>;
  not?: Maybe<CategoryFiltersInput>;
};

export type CategoryInput = {
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['ID']>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Long']>;
  subCategories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  parent?: Maybe<Scalars['ID']>;
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  events?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CategoryRelationResponseCollection = {
  __typename?: 'CategoryRelationResponseCollection';
  data: Array<CategoryEntity>;
};

export type ComponentMediaGallery = {
  __typename?: 'ComponentMediaGallery';
  id: Scalars['ID'];
  owner?: Maybe<UsersPermissionsUserEntityResponse>;
  media?: Maybe<Array<Maybe<ComponentMediaMultimedia>>>;
};


export type ComponentMediaGalleryMediaArgs = {
  filters?: Maybe<ComponentMediaMultimediaFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentMediaMultimedia = {
  __typename?: 'ComponentMediaMultimedia';
  id: Scalars['ID'];
  caption?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  image?: Maybe<UploadFileEntityResponse>;
  videoUrl?: Maybe<Scalars['String']>;
  owner?: Maybe<UsersPermissionsUserEntityResponse>;
  status: MediaStatusEnum;
};

export type ComponentMediaMultimediaFiltersInput = {
  caption?: Maybe<StringFilterInput>;
  date?: Maybe<DateTimeFilterInput>;
  videoUrl?: Maybe<StringFilterInput>;
  owner?: Maybe<UsersPermissionsUserFiltersInput>;
  status?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<ComponentMediaMultimediaFiltersInput>>>;
  or?: Maybe<Array<Maybe<ComponentMediaMultimediaFiltersInput>>>;
  not?: Maybe<ComponentMediaMultimediaFiltersInput>;
};


export type DateTimeFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  or?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  not?: Maybe<DateTimeFilterInput>;
  eq?: Maybe<Scalars['DateTime']>;
  eqi?: Maybe<Scalars['DateTime']>;
  ne?: Maybe<Scalars['DateTime']>;
  startsWith?: Maybe<Scalars['DateTime']>;
  endsWith?: Maybe<Scalars['DateTime']>;
  contains?: Maybe<Scalars['DateTime']>;
  notContains?: Maybe<Scalars['DateTime']>;
  containsi?: Maybe<Scalars['DateTime']>;
  notContainsi?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  between?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export enum Enum_Group_Status {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected'
}

export enum Enum_Userspermissionsuser_Status {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type EmailDesignerEmailTemplate = {
  __typename?: 'EmailDesignerEmailTemplate';
  templateReferenceId?: Maybe<Scalars['Int']>;
  design?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  bodyHtml?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailDesignerEmailTemplateEntity = {
  __typename?: 'EmailDesignerEmailTemplateEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<EmailDesignerEmailTemplate>;
};

export type EmailDesignerEmailTemplateEntityResponse = {
  __typename?: 'EmailDesignerEmailTemplateEntityResponse';
  data?: Maybe<EmailDesignerEmailTemplateEntity>;
};

export type EmailDesignerEmailTemplateEntityResponseCollection = {
  __typename?: 'EmailDesignerEmailTemplateEntityResponseCollection';
  data: Array<EmailDesignerEmailTemplateEntity>;
  meta: ResponseCollectionMeta;
};

export type EmailDesignerEmailTemplateFiltersInput = {
  id?: Maybe<IdFilterInput>;
  templateReferenceId?: Maybe<IntFilterInput>;
  design?: Maybe<JsonFilterInput>;
  name?: Maybe<StringFilterInput>;
  subject?: Maybe<StringFilterInput>;
  bodyHtml?: Maybe<StringFilterInput>;
  bodyText?: Maybe<StringFilterInput>;
  enabled?: Maybe<BooleanFilterInput>;
  tags?: Maybe<JsonFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<EmailDesignerEmailTemplateFiltersInput>>>;
  or?: Maybe<Array<Maybe<EmailDesignerEmailTemplateFiltersInput>>>;
  not?: Maybe<EmailDesignerEmailTemplateFiltersInput>;
};

export type EmailDesignerEmailTemplateInput = {
  templateReferenceId?: Maybe<Scalars['Int']>;
  design?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  bodyHtml?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Scalars['JSON']>;
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  title: Scalars['String'];
  description: Scalars['String'];
  coverPhoto?: Maybe<UploadFileEntityResponse>;
  eventType: EventTypeEnum;
  capacity?: Maybe<Scalars['Int']>;
  eventUrl?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  address?: Maybe<AddressEntityResponse>;
  group?: Maybe<GroupEntityResponse>;
  categories?: Maybe<CategoryRelationResponseCollection>;
  gallery?: Maybe<Array<Maybe<EventGalleryDynamicZone>>>;
  bookings?: Maybe<BookingRelationResponseCollection>;
  repeatInterval?: Maybe<Scalars['Long']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  coverPhotoUrl?: Maybe<Scalars['String']>;
  remainingSeats?: Maybe<Scalars['Int']>;
  isPast?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};


export type EventCategoriesArgs = {
  filters?: Maybe<CategoryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type EventBookingsArgs = {
  filters?: Maybe<BookingFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EventAttributes = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['Upload']>;
  address?: Maybe<AddressAttributes>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  eventType?: Maybe<EventTypeEnum>;
  capacity?: Maybe<Scalars['Int']>;
  repeatInterval?: Maybe<Scalars['Int']>;
  eventUrl?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  group?: Maybe<Scalars['ID']>;
};

export type EventEntity = {
  __typename?: 'EventEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Event>;
};

export type EventEntityResponse = {
  __typename?: 'EventEntityResponse';
  data?: Maybe<EventEntity>;
};

export type EventEntityResponseCollection = {
  __typename?: 'EventEntityResponseCollection';
  data: Array<EventEntity>;
  meta: ResponseCollectionMeta;
};

export type EventFiltersInput = {
  id?: Maybe<IdFilterInput>;
  title?: Maybe<StringFilterInput>;
  description?: Maybe<StringFilterInput>;
  eventType?: Maybe<StringFilterInput>;
  capacity?: Maybe<IntFilterInput>;
  eventUrl?: Maybe<StringFilterInput>;
  price?: Maybe<FloatFilterInput>;
  startTime?: Maybe<DateTimeFilterInput>;
  endTime?: Maybe<DateTimeFilterInput>;
  address?: Maybe<AddressFiltersInput>;
  group?: Maybe<GroupFiltersInput>;
  categories?: Maybe<CategoryFiltersInput>;
  bookings?: Maybe<BookingFiltersInput>;
  repeatInterval?: Maybe<LongFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<EventFiltersInput>>>;
  or?: Maybe<Array<Maybe<EventFiltersInput>>>;
  not?: Maybe<EventFiltersInput>;
};

export type EventGalleryDynamicZone = ComponentMediaGallery | Error;


export type EventInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['ID']>;
  eventType?: Maybe<EventTypeEnum>;
  capacity?: Maybe<Scalars['Int']>;
  eventUrl?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  gallery?: Maybe<Array<Scalars['EventGalleryDynamicZoneInput']>>;
  bookings?: Maybe<Array<Maybe<Scalars['ID']>>>;
  repeatInterval?: Maybe<Scalars['Long']>;
};

export type EventMediaAttributes = {
  caption?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type EventRelationResponseCollection = {
  __typename?: 'EventRelationResponseCollection';
  data: Array<EventEntity>;
};

export enum EventTypeEnum {
  Physical = 'physical',
  Virtual = 'virtual',
  Both = 'both'
}

export type FamilyTree = {
  __typename?: 'FamilyTree';
  fullName?: Maybe<Scalars['String']>;
  owner?: Maybe<UsersPermissionsUserEntityResponse>;
  birthdate?: Maybe<Scalars['DateTime']>;
  deathdate?: Maybe<Scalars['DateTime']>;
  partnerPosition?: Maybe<PartnerPosition>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
  avatar?: Maybe<UploadFileEntityResponse>;
  bio?: Maybe<Scalars['String']>;
  secondaryRelationType?: Maybe<SecondaryRelationTypeEnum>;
  currentLocation?: Maybe<AddressEntityResponse>;
  birthLocation?: Maybe<AddressEntityResponse>;
  deathLocation?: Maybe<AddressEntityResponse>;
  parents?: Maybe<FamilyTreeRelationResponseCollection>;
  children?: Maybe<FamilyTreeRelationResponseCollection>;
  partners?: Maybe<FamilyTreeRelationResponseCollection>;
  partnerships?: Maybe<FamilyTreeRelationResponseCollection>;
  secondaryParents?: Maybe<FamilyTreeRelationResponseCollection>;
  secondaryChildren?: Maybe<FamilyTreeRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  avatarUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isMyself?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  pid?: Maybe<Scalars['ID']>;
  ppid?: Maybe<Scalars['ID']>;
  isPartner?: Maybe<Scalars['Boolean']>;
};


export type FamilyTreeParentsArgs = {
  filters?: Maybe<FamilyTreeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type FamilyTreeChildrenArgs = {
  filters?: Maybe<FamilyTreeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type FamilyTreePartnersArgs = {
  filters?: Maybe<FamilyTreeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type FamilyTreePartnershipsArgs = {
  filters?: Maybe<FamilyTreeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type FamilyTreeSecondaryParentsArgs = {
  filters?: Maybe<FamilyTreeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type FamilyTreeSecondaryChildrenArgs = {
  filters?: Maybe<FamilyTreeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FamilyTreeAttributes = {
  fullName?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  deathdate?: Maybe<Scalars['DateTime']>;
  partnerPosition?: Maybe<PartnerPosition>;
  user?: Maybe<Scalars['ID']>;
  avatar?: Maybe<Scalars['Upload']>;
  bio?: Maybe<Scalars['String']>;
  currentLocation?: Maybe<AddressAttributes>;
  birthLocation?: Maybe<AddressAttributes>;
  deathLocation?: Maybe<AddressAttributes>;
  parents?: Maybe<Array<Maybe<Scalars['ID']>>>;
  partners?: Maybe<Array<Maybe<Scalars['ID']>>>;
  children?: Maybe<Array<Maybe<Scalars['ID']>>>;
  secondaryPartners?: Maybe<Array<Maybe<Scalars['ID']>>>;
  secondaryChildren?: Maybe<Array<Maybe<Scalars['ID']>>>;
  secondaryRelationType?: Maybe<SecondaryRelationTypeEnum>;
};

export type FamilyTreeByNodeId = {
  __typename?: 'FamilyTreeByNodeId';
  nodes?: Maybe<Array<Maybe<FamilyTree>>>;
  links?: Maybe<Array<Maybe<FamilyTreeLinks>>>;
};

export type FamilyTreeEntity = {
  __typename?: 'FamilyTreeEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<FamilyTree>;
};

export type FamilyTreeEntityResponse = {
  __typename?: 'FamilyTreeEntityResponse';
  data?: Maybe<FamilyTreeEntity>;
};

export type FamilyTreeEntityResponseCollection = {
  __typename?: 'FamilyTreeEntityResponseCollection';
  data: Array<FamilyTreeEntity>;
  meta: ResponseCollectionMeta;
};

export type FamilyTreeFiltersInput = {
  id?: Maybe<IdFilterInput>;
  fullName?: Maybe<StringFilterInput>;
  owner?: Maybe<UsersPermissionsUserFiltersInput>;
  birthdate?: Maybe<DateTimeFilterInput>;
  deathdate?: Maybe<DateTimeFilterInput>;
  partnerPosition?: Maybe<StringFilterInput>;
  user?: Maybe<UsersPermissionsUserFiltersInput>;
  bio?: Maybe<StringFilterInput>;
  secondaryRelationType?: Maybe<StringFilterInput>;
  currentLocation?: Maybe<AddressFiltersInput>;
  birthLocation?: Maybe<AddressFiltersInput>;
  deathLocation?: Maybe<AddressFiltersInput>;
  parents?: Maybe<FamilyTreeFiltersInput>;
  children?: Maybe<FamilyTreeFiltersInput>;
  partners?: Maybe<FamilyTreeFiltersInput>;
  partnerships?: Maybe<FamilyTreeFiltersInput>;
  secondaryParents?: Maybe<FamilyTreeFiltersInput>;
  secondaryChildren?: Maybe<FamilyTreeFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<FamilyTreeFiltersInput>>>;
  or?: Maybe<Array<Maybe<FamilyTreeFiltersInput>>>;
  not?: Maybe<FamilyTreeFiltersInput>;
};

export type FamilyTreeInput = {
  fullName?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['ID']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  deathdate?: Maybe<Scalars['DateTime']>;
  partnerPosition?: Maybe<PartnerPosition>;
  user?: Maybe<Scalars['ID']>;
  avatar?: Maybe<Scalars['ID']>;
  bio?: Maybe<Scalars['String']>;
  secondaryRelationType?: Maybe<SecondaryRelationTypeEnum>;
  currentLocation?: Maybe<Scalars['ID']>;
  birthLocation?: Maybe<Scalars['ID']>;
  deathLocation?: Maybe<Scalars['ID']>;
  parents?: Maybe<Array<Maybe<Scalars['ID']>>>;
  children?: Maybe<Array<Maybe<Scalars['ID']>>>;
  partners?: Maybe<Array<Maybe<Scalars['ID']>>>;
  partnerships?: Maybe<Array<Maybe<Scalars['ID']>>>;
  secondaryParents?: Maybe<Array<Maybe<Scalars['ID']>>>;
  secondaryChildren?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type FamilyTreeLinks = {
  __typename?: 'FamilyTreeLinks';
  from?: Maybe<Scalars['ID']>;
  to?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
};

export type FamilyTreeRelationResponseCollection = {
  __typename?: 'FamilyTreeRelationResponseCollection';
  data: Array<FamilyTreeEntity>;
};

export type FileInfoInput = {
  name?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
};

export type FloatFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Float']>>>;
  or?: Maybe<Array<Maybe<Scalars['Float']>>>;
  not?: Maybe<FloatFilterInput>;
  eq?: Maybe<Scalars['Float']>;
  eqi?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  startsWith?: Maybe<Scalars['Float']>;
  endsWith?: Maybe<Scalars['Float']>;
  contains?: Maybe<Scalars['Float']>;
  notContains?: Maybe<Scalars['Float']>;
  containsi?: Maybe<Scalars['Float']>;
  notContainsi?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type GenericMorph = ComponentMediaGallery | ComponentMediaMultimedia | UploadFile | UploadFolder | I18NLocale | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser | EmailDesignerEmailTemplate | Address | Booking | Category | Event | FamilyTree | Group | Post | PostCategory | Reminder | Visitor;

export type Group = {
  __typename?: 'Group';
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  status: Enum_Group_Status;
  description?: Maybe<Scalars['String']>;
  groupType: GroupTypeEnum;
  coverPhoto?: Maybe<UploadFileEntityResponse>;
  address?: Maybe<AddressEntityResponse>;
  members?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  organizers?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  categories?: Maybe<CategoryRelationResponseCollection>;
  events?: Maybe<EventRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  coverPhotoUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type GroupMembersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GroupOrganizersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GroupCategoriesArgs = {
  filters?: Maybe<CategoryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GroupEventsArgs = {
  filters?: Maybe<EventFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupAttributes = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['Upload']>;
  address?: Maybe<AddressAttributes>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupType?: Maybe<GroupTypeEnum>;
};

export type GroupEntity = {
  __typename?: 'GroupEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Group>;
};

export type GroupEntityResponse = {
  __typename?: 'GroupEntityResponse';
  data?: Maybe<GroupEntity>;
};

export type GroupEntityResponseCollection = {
  __typename?: 'GroupEntityResponseCollection';
  data: Array<GroupEntity>;
  meta: ResponseCollectionMeta;
};

export type GroupFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  slug?: Maybe<StringFilterInput>;
  status?: Maybe<StringFilterInput>;
  description?: Maybe<StringFilterInput>;
  groupType?: Maybe<StringFilterInput>;
  address?: Maybe<AddressFiltersInput>;
  members?: Maybe<UsersPermissionsUserFiltersInput>;
  organizers?: Maybe<UsersPermissionsUserFiltersInput>;
  categories?: Maybe<CategoryFiltersInput>;
  events?: Maybe<EventFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<GroupFiltersInput>>>;
  or?: Maybe<Array<Maybe<GroupFiltersInput>>>;
  not?: Maybe<GroupFiltersInput>;
};

export type GroupInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Group_Status>;
  description?: Maybe<Scalars['String']>;
  groupType?: Maybe<GroupTypeEnum>;
  coverPhoto?: Maybe<Scalars['ID']>;
  address?: Maybe<Scalars['ID']>;
  members?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  events?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type GroupRelationResponseCollection = {
  __typename?: 'GroupRelationResponseCollection';
  data: Array<GroupEntity>;
};

export enum GroupTypeEnum {
  Physical = 'physical',
  Virtual = 'virtual',
  Both = 'both'
}

export type I18NLocale = {
  __typename?: 'I18NLocale';
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<I18NLocale>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  code?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<I18NLocaleFiltersInput>>>;
  or?: Maybe<Array<Maybe<I18NLocaleFiltersInput>>>;
  not?: Maybe<I18NLocaleFiltersInput>;
};

export type IdFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['ID']>>>;
  or?: Maybe<Array<Maybe<Scalars['ID']>>>;
  not?: Maybe<IdFilterInput>;
  eq?: Maybe<Scalars['ID']>;
  eqi?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  startsWith?: Maybe<Scalars['ID']>;
  endsWith?: Maybe<Scalars['ID']>;
  contains?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
  containsi?: Maybe<Scalars['ID']>;
  notContainsi?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type IntFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Int']>>>;
  or?: Maybe<Array<Maybe<Scalars['Int']>>>;
  not?: Maybe<IntFilterInput>;
  eq?: Maybe<Scalars['Int']>;
  eqi?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  startsWith?: Maybe<Scalars['Int']>;
  endsWith?: Maybe<Scalars['Int']>;
  contains?: Maybe<Scalars['Int']>;
  notContains?: Maybe<Scalars['Int']>;
  containsi?: Maybe<Scalars['Int']>;
  notContainsi?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type InvitationAttributes = {
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type InviteRsvpAttributes = {
  guests?: Maybe<Scalars['Int']>;
  going?: Maybe<Scalars['Boolean']>;
};


export type JsonFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  or?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  not?: Maybe<JsonFilterInput>;
  eq?: Maybe<Scalars['JSON']>;
  eqi?: Maybe<Scalars['JSON']>;
  ne?: Maybe<Scalars['JSON']>;
  startsWith?: Maybe<Scalars['JSON']>;
  endsWith?: Maybe<Scalars['JSON']>;
  contains?: Maybe<Scalars['JSON']>;
  notContains?: Maybe<Scalars['JSON']>;
  containsi?: Maybe<Scalars['JSON']>;
  notContainsi?: Maybe<Scalars['JSON']>;
  gt?: Maybe<Scalars['JSON']>;
  gte?: Maybe<Scalars['JSON']>;
  lt?: Maybe<Scalars['JSON']>;
  lte?: Maybe<Scalars['JSON']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  between?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};


export type LongFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Long']>>>;
  or?: Maybe<Array<Maybe<Scalars['Long']>>>;
  not?: Maybe<LongFilterInput>;
  eq?: Maybe<Scalars['Long']>;
  eqi?: Maybe<Scalars['Long']>;
  ne?: Maybe<Scalars['Long']>;
  startsWith?: Maybe<Scalars['Long']>;
  endsWith?: Maybe<Scalars['Long']>;
  contains?: Maybe<Scalars['Long']>;
  notContains?: Maybe<Scalars['Long']>;
  containsi?: Maybe<Scalars['Long']>;
  notContainsi?: Maybe<Scalars['Long']>;
  gt?: Maybe<Scalars['Long']>;
  gte?: Maybe<Scalars['Long']>;
  lt?: Maybe<Scalars['Long']>;
  lte?: Maybe<Scalars['Long']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Long']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Long']>>>;
  between?: Maybe<Array<Maybe<Scalars['Long']>>>;
};

export enum MediaStatusEnum {
  Pending = 'pending',
  Approved = 'approved'
}

export type Mutation = {
  __typename?: 'Mutation';
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  createEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  updateEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  deleteEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  createAddress?: Maybe<AddressEntityResponse>;
  updateAddress?: Maybe<AddressEntityResponse>;
  deleteAddress?: Maybe<AddressEntityResponse>;
  createBooking?: Maybe<BookingEntityResponse>;
  updateBooking?: Maybe<BookingEntityResponse>;
  deleteBooking?: Maybe<BookingEntityResponse>;
  createCategory?: Maybe<CategoryEntityResponse>;
  updateCategory?: Maybe<CategoryEntityResponse>;
  deleteCategory?: Maybe<CategoryEntityResponse>;
  createEvent?: Maybe<EventEntityResponse>;
  updateEvent?: Maybe<EventEntityResponse>;
  deleteEvent?: Maybe<EventEntityResponse>;
  createFamilyTree?: Maybe<FamilyTreeEntityResponse>;
  updateFamilyTree?: Maybe<FamilyTreeEntityResponse>;
  deleteFamilyTree?: Maybe<FamilyTreeEntityResponse>;
  createGroup?: Maybe<GroupEntityResponse>;
  updateGroup?: Maybe<GroupEntityResponse>;
  deleteGroup?: Maybe<GroupEntityResponse>;
  createPost?: Maybe<PostEntityResponse>;
  updatePost?: Maybe<PostEntityResponse>;
  deletePost?: Maybe<PostEntityResponse>;
  createPostCategory?: Maybe<PostCategoryEntityResponse>;
  updatePostCategory?: Maybe<PostCategoryEntityResponse>;
  deletePostCategory?: Maybe<PostCategoryEntityResponse>;
  createReminder?: Maybe<UsersPermissionsUserEntityResponse>;
  updateReminder?: Maybe<ReminderEntityResponse>;
  deleteReminder?: Maybe<ReminderEntityResponse>;
  createVisitor?: Maybe<VisitorEntityResponse>;
  updateVisitor?: Maybe<VisitorEntityResponse>;
  deleteVisitor?: Maybe<VisitorEntityResponse>;
  upload: UploadFileEntityResponse;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  updateFileInfo: UploadFileEntityResponse;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  login: UsersPermissionsLoginPayload;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  createGroupAtOnce?: Maybe<GroupEntityResponse>;
  updateGroupAtOnce?: Maybe<GroupEntityResponse>;
  removeGroup?: Maybe<GroupEntityResponse>;
  sendGroupEmail?: Maybe<GroupEntityResponse>;
  removeReminder?: Maybe<UsersPermissionsUserEntityResponse>;
  createEventAtOnce?: Maybe<EventEntityResponse>;
  updateEventAtOnce?: Maybe<EventEntityResponse>;
  removeEvent?: Maybe<EventEntityResponse>;
  removeEventPhoto?: Maybe<EventEntityResponse>;
  approveEventPhoto?: Maybe<EventEntityResponse>;
  uploadEventMedia?: Maybe<EventEntityResponse>;
  changeRsvpStatus?: Maybe<EventEntityResponse>;
  sendEventEmail?: Maybe<EventEntityResponse>;
  eventRsvp?: Maybe<EventEntityResponse>;
  removeRsvp?: Maybe<EventEntityResponse>;
  sendEventInvitation?: Maybe<EventEntityResponse>;
  eventInvitationRsvp?: Maybe<BookingEntityResponse>;
  addFamilyRelationAtOnce?: Maybe<FamilyTreeEntityResponse>;
  editFamilyRelationAtOnce?: Maybe<FamilyTreeEntityResponse>;
  removeFamilyTreeNode?: Maybe<FamilyTreeEntityResponse>;
  updateProfile?: Maybe<UsersPermissionsUserEntityResponse>;
  joinGroup?: Maybe<UsersPermissionsUserEntityResponse>;
  leaveGroup?: Maybe<UsersPermissionsUserEntityResponse>;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationUpdateUploadFileArgs = {
  id: Scalars['ID'];
  data: UploadFileInput;
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationUpdateUploadFolderArgs = {
  id: Scalars['ID'];
  data: UploadFolderInput;
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEmailDesignerEmailTemplateArgs = {
  data: EmailDesignerEmailTemplateInput;
};


export type MutationUpdateEmailDesignerEmailTemplateArgs = {
  id: Scalars['ID'];
  data: EmailDesignerEmailTemplateInput;
};


export type MutationDeleteEmailDesignerEmailTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAddressArgs = {
  data: AddressInput;
};


export type MutationUpdateAddressArgs = {
  id: Scalars['ID'];
  data: AddressInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBookingArgs = {
  data: BookingInput;
};


export type MutationUpdateBookingArgs = {
  id: Scalars['ID'];
  data: BookingInput;
};


export type MutationDeleteBookingArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  data: CategoryInput;
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  data: CategoryInput;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEventArgs = {
  data: EventInput;
};


export type MutationUpdateEventArgs = {
  id: Scalars['ID'];
  data: EventInput;
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationCreateFamilyTreeArgs = {
  data: FamilyTreeInput;
};


export type MutationUpdateFamilyTreeArgs = {
  id: Scalars['ID'];
  data: FamilyTreeInput;
};


export type MutationDeleteFamilyTreeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateGroupArgs = {
  data: GroupInput;
};


export type MutationUpdateGroupArgs = {
  id: Scalars['ID'];
  data: GroupInput;
};


export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePostArgs = {
  data: PostInput;
};


export type MutationUpdatePostArgs = {
  id: Scalars['ID'];
  data: PostInput;
};


export type MutationDeletePostArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePostCategoryArgs = {
  data: PostCategoryInput;
};


export type MutationUpdatePostCategoryArgs = {
  id: Scalars['ID'];
  data: PostCategoryInput;
};


export type MutationDeletePostCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationCreateReminderArgs = {
  input?: Maybe<ReminderAttributes>;
};


export type MutationUpdateReminderArgs = {
  id: Scalars['ID'];
  data: ReminderInput;
};


export type MutationDeleteReminderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateVisitorArgs = {
  data: VisitorInput;
};


export type MutationUpdateVisitorArgs = {
  id: Scalars['ID'];
  data: VisitorInput;
};


export type MutationDeleteVisitorArgs = {
  id: Scalars['ID'];
};


export type MutationUploadArgs = {
  refId?: Maybe<Scalars['ID']>;
  ref?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  info?: Maybe<FileInfoInput>;
  file: Scalars['Upload'];
};


export type MutationMultipleUploadArgs = {
  refId?: Maybe<Scalars['ID']>;
  ref?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  files: Array<Maybe<Scalars['Upload']>>;
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: Maybe<FileInfoInput>;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
  data: UsersPermissionsRoleInput;
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationUpdateUsersPermissionsUserArgs = {
  id: Scalars['ID'];
  data: UsersPermissionsUserInput;
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  code: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


export type MutationCreateGroupAtOnceArgs = {
  input?: Maybe<GroupAttributes>;
};


export type MutationUpdateGroupAtOnceArgs = {
  input?: Maybe<GroupAttributes>;
  groupId: Scalars['ID'];
};


export type MutationRemoveGroupArgs = {
  groupId: Scalars['ID'];
};


export type MutationSendGroupEmailArgs = {
  input?: Maybe<SendGroupEmailAttributes>;
  groupId: Scalars['ID'];
};


export type MutationRemoveReminderArgs = {
  reminderId: Scalars['ID'];
};


export type MutationCreateEventAtOnceArgs = {
  input?: Maybe<EventAttributes>;
};


export type MutationUpdateEventAtOnceArgs = {
  input?: Maybe<EventAttributes>;
  eventId: Scalars['ID'];
};


export type MutationRemoveEventArgs = {
  eventId: Scalars['ID'];
};


export type MutationRemoveEventPhotoArgs = {
  eventId: Scalars['ID'];
  mediaId: Scalars['ID'];
};


export type MutationApproveEventPhotoArgs = {
  eventId: Scalars['ID'];
  mediaId: Scalars['ID'];
};


export type MutationUploadEventMediaArgs = {
  eventId: Scalars['ID'];
  input?: Maybe<EventMediaAttributes>;
};


export type MutationChangeRsvpStatusArgs = {
  eventId: Scalars['ID'];
  userId: Scalars['ID'];
  status: BookingStatusEnum;
};


export type MutationSendEventEmailArgs = {
  input?: Maybe<SendEventEmailAttributes>;
  eventId: Scalars['ID'];
};


export type MutationEventRsvpArgs = {
  eventId: Scalars['ID'];
  input?: Maybe<RsvpAttributes>;
};


export type MutationRemoveRsvpArgs = {
  bookingId: Scalars['ID'];
};


export type MutationSendEventInvitationArgs = {
  eventId: Scalars['ID'];
  input?: Maybe<InvitationAttributes>;
};


export type MutationEventInvitationRsvpArgs = {
  eventId: Scalars['ID'];
  visitorToken: Scalars['String'];
  input?: Maybe<InviteRsvpAttributes>;
};


export type MutationAddFamilyRelationAtOnceArgs = {
  input?: Maybe<FamilyTreeAttributes>;
};


export type MutationEditFamilyRelationAtOnceArgs = {
  input?: Maybe<FamilyTreeAttributes>;
  treeNodeId: Scalars['ID'];
};


export type MutationRemoveFamilyTreeNodeArgs = {
  treeNodeId: Scalars['ID'];
};


export type MutationUpdateProfileArgs = {
  input?: Maybe<UpdateProfileInput>;
};


export type MutationJoinGroupArgs = {
  groupId: Scalars['ID'];
};


export type MutationLeaveGroupArgs = {
  groupId: Scalars['ID'];
};

export type Pagination = {
  __typename?: 'Pagination';
  total: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  pageCount: Scalars['Int'];
};

export type PaginationArg = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type Post = {
  __typename?: 'Post';
  title: Scalars['String'];
  author?: Maybe<UsersPermissionsUserEntityResponse>;
  slug: Scalars['String'];
  content: Scalars['String'];
  categories?: Maybe<PostCategoryRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type PostCategoriesArgs = {
  filters?: Maybe<PostCategoryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};

export type PostCategory = {
  __typename?: 'PostCategory';
  name: Scalars['String'];
  posts?: Maybe<PostRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type PostCategoryPostsArgs = {
  filters?: Maybe<PostFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};

export type PostCategoryEntity = {
  __typename?: 'PostCategoryEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<PostCategory>;
};

export type PostCategoryEntityResponse = {
  __typename?: 'PostCategoryEntityResponse';
  data?: Maybe<PostCategoryEntity>;
};

export type PostCategoryEntityResponseCollection = {
  __typename?: 'PostCategoryEntityResponseCollection';
  data: Array<PostCategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type PostCategoryFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  posts?: Maybe<PostFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<PostCategoryFiltersInput>>>;
  or?: Maybe<Array<Maybe<PostCategoryFiltersInput>>>;
  not?: Maybe<PostCategoryFiltersInput>;
};

export type PostCategoryInput = {
  name?: Maybe<Scalars['String']>;
  posts?: Maybe<Array<Maybe<Scalars['ID']>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type PostCategoryRelationResponseCollection = {
  __typename?: 'PostCategoryRelationResponseCollection';
  data: Array<PostCategoryEntity>;
};

export type PostEntity = {
  __typename?: 'PostEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Post>;
};

export type PostEntityResponse = {
  __typename?: 'PostEntityResponse';
  data?: Maybe<PostEntity>;
};

export type PostEntityResponseCollection = {
  __typename?: 'PostEntityResponseCollection';
  data: Array<PostEntity>;
  meta: ResponseCollectionMeta;
};

export type PostFiltersInput = {
  id?: Maybe<IdFilterInput>;
  title?: Maybe<StringFilterInput>;
  author?: Maybe<UsersPermissionsUserFiltersInput>;
  slug?: Maybe<StringFilterInput>;
  content?: Maybe<StringFilterInput>;
  categories?: Maybe<PostCategoryFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<PostFiltersInput>>>;
  or?: Maybe<Array<Maybe<PostFiltersInput>>>;
  not?: Maybe<PostFiltersInput>;
};

export type PostInput = {
  title?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type PostRelationResponseCollection = {
  __typename?: 'PostRelationResponseCollection';
  data: Array<PostEntity>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type Query = {
  __typename?: 'Query';
  address?: Maybe<AddressEntityResponse>;
  addresses?: Maybe<AddressEntityResponseCollection>;
  blogContent: Array<Maybe<BlogContent>>;
  booking?: Maybe<BookingEntityResponse>;
  bookings?: Maybe<BookingEntityResponseCollection>;
  categories?: Maybe<CategoryEntityResponseCollection>;
  category?: Maybe<CategoryEntityResponse>;
  emailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  emailDesignerEmailTemplates?: Maybe<EmailDesignerEmailTemplateEntityResponseCollection>;
  event?: Maybe<EventEntityResponse>;
  events?: Maybe<EventEntityResponseCollection>;
  familyTree?: Maybe<FamilyTreeEntityResponse>;
  familyTreeByNodeId: FamilyTreeByNodeId;
  familyTrees?: Maybe<FamilyTreeEntityResponseCollection>;
  forgotPasswordModalVisible: Scalars['Boolean'];
  group?: Maybe<GroupEntityResponse>;
  groups?: Maybe<GroupEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  loggedInState: Scalars['Boolean'];
  loginModalVisible: Scalars['Boolean'];
  me?: Maybe<UsersPermissionsMe>;
  myAccount?: Maybe<UsersPermissionsUserEntityResponse>;
  post?: Maybe<PostEntityResponse>;
  postCategories?: Maybe<PostCategoryEntityResponseCollection>;
  postCategory?: Maybe<PostCategoryEntityResponse>;
  posts?: Maybe<PostEntityResponseCollection>;
  reminder?: Maybe<ReminderEntityResponse>;
  reminders?: Maybe<ReminderEntityResponseCollection>;
  signupModalVisible: Scalars['Boolean'];
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  userTokenInfo: UserTokenInfo;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  visitor?: Maybe<VisitorEntityResponse>;
  visitorInvitationBooking?: Maybe<BookingEntityResponse>;
  visitors?: Maybe<VisitorEntityResponseCollection>;
};


export type QueryAddressArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryAddressesArgs = {
  filters?: Maybe<AddressFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryBookingArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryBookingsArgs = {
  filters?: Maybe<BookingFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryCategoriesArgs = {
  filters?: Maybe<CategoryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryCategoryArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryEmailDesignerEmailTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryEmailDesignerEmailTemplatesArgs = {
  filters?: Maybe<EmailDesignerEmailTemplateFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryEventArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryEventsArgs = {
  filters?: Maybe<EventFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryFamilyTreeArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryFamilyTreeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryFamilyTreesArgs = {
  filters?: Maybe<FamilyTreeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGroupArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGroupsArgs = {
  filters?: Maybe<GroupFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryI18NLocaleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryI18NLocalesArgs = {
  filters?: Maybe<I18NLocaleFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryPostArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryPostCategoriesArgs = {
  filters?: Maybe<PostCategoryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryPostCategoryArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryPostsArgs = {
  filters?: Maybe<PostFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryReminderArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryRemindersArgs = {
  filters?: Maybe<ReminderFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUploadFileArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: Maybe<UploadFileFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUploadFolderArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUploadFoldersArgs = {
  filters?: Maybe<UploadFolderFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: Maybe<UsersPermissionsRoleFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryVisitorArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryVisitorInvitationBookingArgs = {
  eventId: Scalars['ID'];
  visitorToken: Scalars['String'];
};


export type QueryVisitorsArgs = {
  filters?: Maybe<VisitorFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Reminder = {
  __typename?: 'Reminder';
  subject: SubjectTypeEnum;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
  time: Scalars['Time'];
  dayBefore?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReminderAttributes = {
  subject?: Maybe<SubjectTypeEnum>;
  dayBefore?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Time']>;
};

export type ReminderEntity = {
  __typename?: 'ReminderEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Reminder>;
};

export type ReminderEntityResponse = {
  __typename?: 'ReminderEntityResponse';
  data?: Maybe<ReminderEntity>;
};

export type ReminderEntityResponseCollection = {
  __typename?: 'ReminderEntityResponseCollection';
  data: Array<ReminderEntity>;
  meta: ResponseCollectionMeta;
};

export type ReminderFiltersInput = {
  id?: Maybe<IdFilterInput>;
  subject?: Maybe<StringFilterInput>;
  user?: Maybe<UsersPermissionsUserFiltersInput>;
  time?: Maybe<TimeFilterInput>;
  dayBefore?: Maybe<IntFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<ReminderFiltersInput>>>;
  or?: Maybe<Array<Maybe<ReminderFiltersInput>>>;
  not?: Maybe<ReminderFiltersInput>;
};

export type ReminderInput = {
  subject?: Maybe<SubjectTypeEnum>;
  user?: Maybe<Scalars['ID']>;
  time?: Maybe<Scalars['Time']>;
  dayBefore?: Maybe<Scalars['Int']>;
};

export type ReminderRelationResponseCollection = {
  __typename?: 'ReminderRelationResponseCollection';
  data: Array<ReminderEntity>;
};

export type Rendered = {
  __typename?: 'Rendered';
  rendered: Scalars['String'];
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type RsvpAttributes = {
  guests?: Maybe<Scalars['Int']>;
};

export enum SecondaryRelationTypeEnum {
  Biological = 'biological',
  Adoptive = 'adoptive'
}

export type SendEventEmailAttributes = {
  subject?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type SendGroupEmailAttributes = {
  subject?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type StringFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['String']>>>;
  or?: Maybe<Array<Maybe<Scalars['String']>>>;
  not?: Maybe<StringFilterInput>;
  eq?: Maybe<Scalars['String']>;
  eqi?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
  containsi?: Maybe<Scalars['String']>;
  notContainsi?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum SubjectTypeEnum {
  Nabor = 'nabor',
  Gohanbar = 'gohanbar',
  Important = 'important',
  SemiImportant = 'semiImportant',
  Ziyarat = 'ziyarat'
}


export type TimeFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Time']>>>;
  or?: Maybe<Array<Maybe<Scalars['Time']>>>;
  not?: Maybe<TimeFilterInput>;
  eq?: Maybe<Scalars['Time']>;
  eqi?: Maybe<Scalars['Time']>;
  ne?: Maybe<Scalars['Time']>;
  startsWith?: Maybe<Scalars['Time']>;
  endsWith?: Maybe<Scalars['Time']>;
  contains?: Maybe<Scalars['Time']>;
  notContains?: Maybe<Scalars['Time']>;
  containsi?: Maybe<Scalars['Time']>;
  notContainsi?: Maybe<Scalars['Time']>;
  gt?: Maybe<Scalars['Time']>;
  gte?: Maybe<Scalars['Time']>;
  lt?: Maybe<Scalars['Time']>;
  lte?: Maybe<Scalars['Time']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Time']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Time']>>>;
  between?: Maybe<Array<Maybe<Scalars['Time']>>>;
};

export type UpdateProfileInput = {
  username?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  ledGroups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<AddressAttributes>;
  birthdate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
};


export type UploadFile = {
  __typename?: 'UploadFile';
  name: Scalars['String'];
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  ext?: Maybe<Scalars['String']>;
  mime: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UploadFile>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  alternativeText?: Maybe<StringFilterInput>;
  caption?: Maybe<StringFilterInput>;
  width?: Maybe<IntFilterInput>;
  height?: Maybe<IntFilterInput>;
  formats?: Maybe<JsonFilterInput>;
  hash?: Maybe<StringFilterInput>;
  ext?: Maybe<StringFilterInput>;
  mime?: Maybe<StringFilterInput>;
  size?: Maybe<FloatFilterInput>;
  url?: Maybe<StringFilterInput>;
  previewUrl?: Maybe<StringFilterInput>;
  provider?: Maybe<StringFilterInput>;
  provider_metadata?: Maybe<JsonFilterInput>;
  folder?: Maybe<UploadFolderFiltersInput>;
  folderPath?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<UploadFileFiltersInput>>>;
  or?: Maybe<Array<Maybe<UploadFileFiltersInput>>>;
  not?: Maybe<UploadFileFiltersInput>;
};

export type UploadFileInput = {
  name?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  hash?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_metadata?: Maybe<Scalars['JSON']>;
  folder?: Maybe<Scalars['ID']>;
  folderPath?: Maybe<Scalars['String']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  name: Scalars['String'];
  pathId: Scalars['Int'];
  parent?: Maybe<UploadFolderEntityResponse>;
  children?: Maybe<UploadFolderRelationResponseCollection>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  path: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: Maybe<UploadFolderFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: Maybe<UploadFileFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UploadFolder>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  pathId?: Maybe<IntFilterInput>;
  parent?: Maybe<UploadFolderFiltersInput>;
  children?: Maybe<UploadFolderFiltersInput>;
  files?: Maybe<UploadFileFiltersInput>;
  path?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<UploadFolderFiltersInput>>>;
  or?: Maybe<Array<Maybe<UploadFolderFiltersInput>>>;
  not?: Maybe<UploadFolderFiltersInput>;
};

export type UploadFolderInput = {
  name?: Maybe<Scalars['String']>;
  pathId?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['ID']>;
  children?: Maybe<Array<Maybe<Scalars['ID']>>>;
  files?: Maybe<Array<Maybe<Scalars['ID']>>>;
  path?: Maybe<Scalars['String']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UserTokenInfo = {
  __typename?: 'UserTokenInfo';
  id: Scalars['ID'];
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  id: Scalars['ID'];
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsMeRole>;
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsPermission>;
};

export type UsersPermissionsPermissionFiltersInput = {
  id?: Maybe<IdFilterInput>;
  action?: Maybe<StringFilterInput>;
  role?: Maybe<UsersPermissionsRoleFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<UsersPermissionsPermissionFiltersInput>>>;
  or?: Maybe<Array<Maybe<UsersPermissionsPermissionFiltersInput>>>;
  not?: Maybe<UsersPermissionsPermissionFiltersInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: Maybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsRole>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  description?: Maybe<StringFilterInput>;
  type?: Maybe<StringFilterInput>;
  permissions?: Maybe<UsersPermissionsPermissionFiltersInput>;
  users?: Maybe<UsersPermissionsUserFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<UsersPermissionsRoleFiltersInput>>>;
  or?: Maybe<Array<Maybe<UsersPermissionsRoleFiltersInput>>>;
  not?: Maybe<UsersPermissionsRoleFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  username: Scalars['String'];
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  categories?: Maybe<CategoryRelationResponseCollection>;
  groups?: Maybe<GroupRelationResponseCollection>;
  ledGroups?: Maybe<GroupRelationResponseCollection>;
  bookings?: Maybe<BookingRelationResponseCollection>;
  familyTrees?: Maybe<FamilyTreeRelationResponseCollection>;
  familyTreeUserProfile?: Maybe<FamilyTreeEntityResponse>;
  birthdate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['Long']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  status: Enum_Userspermissionsuser_Status;
  avatar?: Maybe<UploadFileEntityResponse>;
  address?: Maybe<AddressEntityResponse>;
  posts?: Maybe<PostRelationResponseCollection>;
  reminders?: Maybe<ReminderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  avatarUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  roleName?: Maybe<Scalars['String']>;
  myTreeId?: Maybe<Scalars['ID']>;
  events?: Maybe<Array<Maybe<Event>>>;
};


export type UsersPermissionsUserCategoriesArgs = {
  filters?: Maybe<CategoryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UsersPermissionsUserGroupsArgs = {
  filters?: Maybe<GroupFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UsersPermissionsUserLedGroupsArgs = {
  filters?: Maybe<GroupFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UsersPermissionsUserBookingsArgs = {
  filters?: Maybe<BookingFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UsersPermissionsUserFamilyTreesArgs = {
  filters?: Maybe<FamilyTreeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UsersPermissionsUserPostsArgs = {
  filters?: Maybe<PostFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type UsersPermissionsUserRemindersArgs = {
  filters?: Maybe<ReminderFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsUser>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  id?: Maybe<IdFilterInput>;
  username?: Maybe<StringFilterInput>;
  email?: Maybe<StringFilterInput>;
  provider?: Maybe<StringFilterInput>;
  password?: Maybe<StringFilterInput>;
  resetPasswordToken?: Maybe<StringFilterInput>;
  confirmationToken?: Maybe<StringFilterInput>;
  confirmed?: Maybe<BooleanFilterInput>;
  blocked?: Maybe<BooleanFilterInput>;
  role?: Maybe<UsersPermissionsRoleFiltersInput>;
  categories?: Maybe<CategoryFiltersInput>;
  groups?: Maybe<GroupFiltersInput>;
  ledGroups?: Maybe<GroupFiltersInput>;
  bookings?: Maybe<BookingFiltersInput>;
  familyTrees?: Maybe<FamilyTreeFiltersInput>;
  familyTreeUserProfile?: Maybe<FamilyTreeFiltersInput>;
  birthdate?: Maybe<DateTimeFilterInput>;
  description?: Maybe<StringFilterInput>;
  phoneNumber?: Maybe<LongFilterInput>;
  firstname?: Maybe<StringFilterInput>;
  lastname?: Maybe<StringFilterInput>;
  status?: Maybe<StringFilterInput>;
  address?: Maybe<AddressFiltersInput>;
  posts?: Maybe<PostFiltersInput>;
  reminders?: Maybe<ReminderFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<UsersPermissionsUserFiltersInput>>>;
  or?: Maybe<Array<Maybe<UsersPermissionsUserFiltersInput>>>;
  not?: Maybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsUserInput = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  confirmationToken?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['ID']>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ledGroups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  bookings?: Maybe<Array<Maybe<Scalars['ID']>>>;
  familyTrees?: Maybe<Array<Maybe<Scalars['ID']>>>;
  familyTreeUserProfile?: Maybe<Scalars['ID']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['Long']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Userspermissionsuser_Status>;
  avatar?: Maybe<Scalars['ID']>;
  address?: Maybe<Scalars['ID']>;
  posts?: Maybe<Array<Maybe<Scalars['ID']>>>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type Visitor = {
  __typename?: 'Visitor';
  email: Scalars['String'];
  bookings?: Maybe<BookingRelationResponseCollection>;
  token?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type VisitorBookingsArgs = {
  filters?: Maybe<BookingFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VisitorEntity = {
  __typename?: 'VisitorEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Visitor>;
};

export type VisitorEntityResponse = {
  __typename?: 'VisitorEntityResponse';
  data?: Maybe<VisitorEntity>;
};

export type VisitorEntityResponseCollection = {
  __typename?: 'VisitorEntityResponseCollection';
  data: Array<VisitorEntity>;
  meta: ResponseCollectionMeta;
};

export type VisitorFiltersInput = {
  id?: Maybe<IdFilterInput>;
  email?: Maybe<StringFilterInput>;
  bookings?: Maybe<BookingFiltersInput>;
  token?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<VisitorFiltersInput>>>;
  or?: Maybe<Array<Maybe<VisitorFiltersInput>>>;
  not?: Maybe<VisitorFiltersInput>;
};

export type VisitorInput = {
  email?: Maybe<Scalars['String']>;
  bookings?: Maybe<Array<Maybe<Scalars['ID']>>>;
  token?: Maybe<Scalars['String']>;
};

export enum PartnerPosition {
  Right = 'right',
  Left = 'left'
}

export type FullBookingDetailsFragment = (
  { __typename?: 'Booking' }
  & Pick<Booking, 'guests' | 'status'>
  & { visitor?: Maybe<(
    { __typename?: 'VisitorEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'VisitorEntity' }
      & Pick<VisitorEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Visitor' }
        & Pick<Visitor, 'token' | 'email'>
      )> }
    )> }
  )>, user?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & BasicProfileFragment
      )> }
    )> }
  )> }
);

export type EventRsvpMutationVariables = Exact<{
  input?: Maybe<RsvpAttributes>;
  eventId: Scalars['ID'];
}>;


export type EventRsvpMutation = (
  { __typename?: 'Mutation' }
  & { eventRsvp?: Maybe<(
    { __typename?: 'EventEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & FullEventDetailsFragment
      )> }
    )> }
  )> }
);

export type RemoveRsvpMutationVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type RemoveRsvpMutation = (
  { __typename?: 'Mutation' }
  & { removeRsvp?: Maybe<(
    { __typename?: 'EventEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & FullEventDetailsFragment
      )> }
    )> }
  )> }
);

export type SendEventInvitationMutationVariables = Exact<{
  input?: Maybe<InvitationAttributes>;
  eventId: Scalars['ID'];
}>;


export type SendEventInvitationMutation = (
  { __typename?: 'Mutation' }
  & { sendEventInvitation?: Maybe<(
    { __typename?: 'EventEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & FullEventDetailsFragment
      )> }
    )> }
  )> }
);

export type EventInvitationRsvpMutationVariables = Exact<{
  input?: Maybe<InviteRsvpAttributes>;
  eventId: Scalars['ID'];
  visitorToken: Scalars['String'];
}>;


export type EventInvitationRsvpMutation = (
  { __typename?: 'Mutation' }
  & { eventInvitationRsvp?: Maybe<(
    { __typename?: 'BookingEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Booking' }
        & FullBookingDetailsFragment
      )> }
    )> }
  )> }
);

export type VisitorInvitationBookingQueryVariables = Exact<{
  eventId: Scalars['ID'];
  visitorToken: Scalars['String'];
}>;


export type VisitorInvitationBookingQuery = (
  { __typename?: 'Query' }
  & { visitorInvitationBooking?: Maybe<(
    { __typename?: 'BookingEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Booking' }
        & FullBookingDetailsFragment
      )> }
    )> }
  )> }
);

export type ChangeRsvpStatusMutationVariables = Exact<{
  eventId: Scalars['ID'];
  userId: Scalars['ID'];
  status: BookingStatusEnum;
}>;


export type ChangeRsvpStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeRsvpStatus?: Maybe<(
    { __typename?: 'EventEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & FullEventDetailsFragment
      )> }
    )> }
  )> }
);

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<(
    { __typename?: 'CategoryEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'slug' | 'order' | 'coverPhotoUrl'>
        & { subCategories?: Maybe<(
          { __typename?: 'CategoryRelationResponseCollection' }
          & { data: Array<(
            { __typename?: 'CategoryEntity' }
            & Pick<CategoryEntity, 'id'>
          )> }
        )>, parent?: Maybe<(
          { __typename?: 'CategoryEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'CategoryEntity' }
            & Pick<CategoryEntity, 'id'>
          )> }
        )> }
        & BasicCategoryInfoFragment
      )> }
    )> }
  )> }
);

export type BasicAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'lat' | 'lng' | 'city' | 'address' | 'country'>
);

export type FullAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'country' | 'state' | 'zip'>
  & BasicAddressFragment
);

export type BasicGroupDetailsFragment = (
  { __typename?: 'Group' }
  & Pick<Group, 'id' | 'name' | 'slug' | 'createdAt'>
);

export type BasicEventDetailsFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'title' | 'coverPhotoUrl' | 'startTime' | 'endTime' | 'eventType' | 'isPast' | 'description' | 'remainingSeats' | 'repeatInterval' | 'createdAt'>
  & { address?: Maybe<(
    { __typename?: 'AddressEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'AddressEntity' }
      & Pick<AddressEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Address' }
        & BasicAddressFragment
      )> }
    )> }
  )>, group?: Maybe<(
    { __typename?: 'GroupEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'GroupEntity' }
      & Pick<GroupEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Group' }
        & BasicGroupDetailsFragment
      )> }
    )> }
  )>, categories?: Maybe<(
    { __typename?: 'CategoryRelationResponseCollection' }
    & { data: Array<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Category' }
        & BasicCategoryInfoFragment
      )> }
    )> }
  )> }
);

export type BasicProfileFragment = (
  { __typename?: 'UsersPermissionsUser' }
  & Pick<UsersPermissionsUser, 'id' | 'username' | 'firstname' | 'lastname' | 'avatarUrl'>
);

export type BasicCategoryInfoFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'title' | 'description'>
);

export type GroupCardInfoFragment = (
  { __typename?: 'Group' }
  & Pick<Group, 'coverPhotoUrl' | 'status' | 'groupType'>
  & { members?: Maybe<(
    { __typename?: 'UsersPermissionsUserRelationResponseCollection' }
    & { data: Array<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
    )> }
  )>, address?: Maybe<(
    { __typename?: 'AddressEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'AddressEntity' }
      & Pick<AddressEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'city' | 'country'>
      )> }
    )> }
  )>, categories?: Maybe<(
    { __typename?: 'CategoryRelationResponseCollection' }
    & { data: Array<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Category' }
        & BasicCategoryInfoFragment
      )> }
    )> }
  )> }
  & BasicGroupDetailsFragment
);

export type BasicBookingInfoFragment = (
  { __typename?: 'Booking' }
  & Pick<Booking, 'id' | 'status' | 'isPast' | 'guests'>
);

export type BasicFamilyTreeInfoFragment = (
  { __typename?: 'FamilyTree' }
  & Pick<FamilyTree, 'id' | 'fullName' | 'pid' | 'ppid' | 'isMyself' | 'avatarUrl' | 'tags' | 'isPartner' | 'partnerPosition' | 'deathdate' | 'birthdate' | 'bio'>
  & { currentLocation?: Maybe<(
    { __typename?: 'AddressEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'AddressEntity' }
      & Pick<AddressEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Address' }
        & BasicAddressFragment
      )> }
    )> }
  )>, deathLocation?: Maybe<(
    { __typename?: 'AddressEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'AddressEntity' }
      & Pick<AddressEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Address' }
        & BasicAddressFragment
      )> }
    )> }
  )>, birthLocation?: Maybe<(
    { __typename?: 'AddressEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'AddressEntity' }
      & Pick<AddressEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Address' }
        & BasicAddressFragment
      )> }
    )> }
  )> }
);

export type BasicFamilyTreeLinksFragment = (
  { __typename?: 'FamilyTreeLinks' }
  & Pick<FamilyTreeLinks, 'from' | 'to' | 'label'>
);

export type PaginationMetaFragment = (
  { __typename?: 'ResponseCollectionMeta' }
  & { pagination: (
    { __typename?: 'Pagination' }
    & Pick<Pagination, 'total' | 'page' | 'pageSize' | 'pageCount'>
  ) }
);

export type FullEventDetailsFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'description' | 'price' | 'capacity' | 'eventUrl' | 'eventType'>
  & { bookings?: Maybe<(
    { __typename?: 'BookingRelationResponseCollection' }
    & { data: Array<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Booking' }
        & { user?: Maybe<(
          { __typename?: 'UsersPermissionsUserEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'UsersPermissionsUserEntity' }
            & Pick<UsersPermissionsUserEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'UsersPermissionsUser' }
              & BasicProfileFragment
            )> }
          )> }
        )>, visitor?: Maybe<(
          { __typename?: 'VisitorEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'VisitorEntity' }
            & Pick<VisitorEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Visitor' }
              & Pick<Visitor, 'email'>
            )> }
          )> }
        )> }
        & BasicBookingInfoFragment
      )> }
    )> }
  )>, gallery?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentMediaGallery' }
    & Pick<ComponentMediaGallery, 'id'>
    & { owner?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntityResponse' }
      & { data?: Maybe<(
        { __typename?: 'UsersPermissionsUserEntity' }
        & Pick<UsersPermissionsUserEntity, 'id'>
        & { attributes?: Maybe<(
          { __typename?: 'UsersPermissionsUser' }
          & BasicProfileFragment
        )> }
      )> }
    )>, media?: Maybe<Array<Maybe<(
      { __typename?: 'ComponentMediaMultimedia' }
      & Pick<ComponentMediaMultimedia, 'id' | 'date' | 'caption' | 'videoUrl' | 'status'>
      & { image?: Maybe<(
        { __typename?: 'UploadFileEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UploadFileEntity' }
          & Pick<UploadFileEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'UploadFile' }
            & Pick<UploadFile, 'url'>
          )> }
        )> }
      )> }
    )>>> }
  ) | { __typename?: 'Error' }>>> }
  & BasicEventDetailsFragment
);

export type GetAllEventsQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  pagination?: Maybe<PaginationArg>;
  filters?: Maybe<EventFiltersInput>;
}>;


export type GetAllEventsQuery = (
  { __typename?: 'Query' }
  & { events?: Maybe<(
    { __typename?: 'EventEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & BasicEventDetailsFragment
      )> }
    )>, meta: (
      { __typename?: 'ResponseCollectionMeta' }
      & PaginationMetaFragment
    ) }
  )> }
);

export type GetEventByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEventByIdQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'EventEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & FullEventDetailsFragment
      )> }
    )> }
  )> }
);

export type CreateEventAtOnceMutationVariables = Exact<{
  input?: Maybe<EventAttributes>;
}>;


export type CreateEventAtOnceMutation = (
  { __typename?: 'Mutation' }
  & { createEventAtOnce?: Maybe<(
    { __typename?: 'EventEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & FullEventDetailsFragment
      )> }
    )> }
  )> }
);

export type UpdateEventAtOnceMutationVariables = Exact<{
  input?: Maybe<EventAttributes>;
  eventId: Scalars['ID'];
}>;


export type UpdateEventAtOnceMutation = (
  { __typename?: 'Mutation' }
  & { updateEventAtOnce?: Maybe<(
    { __typename?: 'EventEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & FullEventDetailsFragment
      )> }
    )> }
  )> }
);

export type RemoveEventPhotoMutationVariables = Exact<{
  eventId: Scalars['ID'];
  mediaId: Scalars['ID'];
}>;


export type RemoveEventPhotoMutation = (
  { __typename?: 'Mutation' }
  & { removeEventPhoto?: Maybe<(
    { __typename?: 'EventEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & FullEventDetailsFragment
      )> }
    )> }
  )> }
);

export type ApproveEventPhotoMutationVariables = Exact<{
  eventId: Scalars['ID'];
  mediaId: Scalars['ID'];
}>;


export type ApproveEventPhotoMutation = (
  { __typename?: 'Mutation' }
  & { approveEventPhoto?: Maybe<(
    { __typename?: 'EventEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & FullEventDetailsFragment
      )> }
    )> }
  )> }
);

export type UploadEventMediaMutationVariables = Exact<{
  input?: Maybe<EventMediaAttributes>;
  eventId: Scalars['ID'];
}>;


export type UploadEventMediaMutation = (
  { __typename?: 'Mutation' }
  & { uploadEventMedia?: Maybe<(
    { __typename?: 'EventEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & FullEventDetailsFragment
      )> }
    )> }
  )> }
);

export type SendEventEmailMutationVariables = Exact<{
  input?: Maybe<SendEventEmailAttributes>;
  eventId: Scalars['ID'];
}>;


export type SendEventEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendEventEmail?: Maybe<(
    { __typename?: 'EventEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'EventEntity' }
      & Pick<EventEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Event' }
        & FullEventDetailsFragment
      )> }
    )> }
  )> }
);

export type FamilyTreeByNodeIdQueryVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type FamilyTreeByNodeIdQuery = (
  { __typename?: 'Query' }
  & { familyTreeByNodeId: (
    { __typename?: 'FamilyTreeByNodeId' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'FamilyTree' }
      & BasicFamilyTreeInfoFragment
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'FamilyTreeLinks' }
      & BasicFamilyTreeLinksFragment
    )>>> }
  ) }
);

export type AddFamilyRelationAtOnceMutationVariables = Exact<{
  input: FamilyTreeAttributes;
}>;


export type AddFamilyRelationAtOnceMutation = (
  { __typename?: 'Mutation' }
  & { addFamilyRelationAtOnce?: Maybe<(
    { __typename?: 'FamilyTreeEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'FamilyTreeEntity' }
      & Pick<FamilyTreeEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'FamilyTree' }
        & BasicFamilyTreeInfoFragment
      )> }
    )> }
  )> }
);

export type RemoveFamilyTreeNodeMutationVariables = Exact<{
  treeNodeId: Scalars['ID'];
}>;


export type RemoveFamilyTreeNodeMutation = (
  { __typename?: 'Mutation' }
  & { removeFamilyTreeNode?: Maybe<(
    { __typename?: 'FamilyTreeEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'FamilyTreeEntity' }
      & Pick<FamilyTreeEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'FamilyTree' }
        & BasicFamilyTreeInfoFragment
      )> }
    )> }
  )> }
);

export type EditFamilyRelationAtOnceMutationVariables = Exact<{
  input: FamilyTreeAttributes;
  treeNodeId: Scalars['ID'];
}>;


export type EditFamilyRelationAtOnceMutation = (
  { __typename?: 'Mutation' }
  & { editFamilyRelationAtOnce?: Maybe<(
    { __typename?: 'FamilyTreeEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'FamilyTreeEntity' }
      & Pick<FamilyTreeEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'FamilyTree' }
        & BasicFamilyTreeInfoFragment
      )> }
    )> }
  )> }
);

export type GetAllGroupsQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  pagination?: Maybe<PaginationArg>;
  filters?: Maybe<GroupFiltersInput>;
}>;


export type GetAllGroupsQuery = (
  { __typename?: 'Query' }
  & { groups?: Maybe<(
    { __typename?: 'GroupEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'GroupEntity' }
      & Pick<GroupEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Group' }
        & Pick<Group, 'coverPhotoUrl' | 'status' | 'groupType' | 'description'>
        & { members?: Maybe<(
          { __typename?: 'UsersPermissionsUserRelationResponseCollection' }
          & { data: Array<(
            { __typename?: 'UsersPermissionsUserEntity' }
            & Pick<UsersPermissionsUserEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'UsersPermissionsUser' }
              & BasicProfileFragment
            )> }
          )> }
        )>, address?: Maybe<(
          { __typename?: 'AddressEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'AddressEntity' }
            & Pick<AddressEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Address' }
              & BasicAddressFragment
            )> }
          )> }
        )>, organizers?: Maybe<(
          { __typename?: 'UsersPermissionsUserRelationResponseCollection' }
          & { data: Array<(
            { __typename?: 'UsersPermissionsUserEntity' }
            & Pick<UsersPermissionsUserEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'UsersPermissionsUser' }
              & BasicProfileFragment
            )> }
          )> }
        )>, events?: Maybe<(
          { __typename?: 'EventRelationResponseCollection' }
          & { data: Array<(
            { __typename?: 'EventEntity' }
            & Pick<EventEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Event' }
              & BasicEventDetailsFragment
            )> }
          )> }
        )>, categories?: Maybe<(
          { __typename?: 'CategoryRelationResponseCollection' }
          & { data: Array<(
            { __typename?: 'CategoryEntity' }
            & Pick<CategoryEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Category' }
              & BasicCategoryInfoFragment
            )> }
          )> }
        )> }
        & BasicGroupDetailsFragment
      )> }
    )>, meta: (
      { __typename?: 'ResponseCollectionMeta' }
      & PaginationMetaFragment
    ) }
  )> }
);

export type CreateGroupAtOnceMutationVariables = Exact<{
  input?: Maybe<GroupAttributes>;
}>;


export type CreateGroupAtOnceMutation = (
  { __typename?: 'Mutation' }
  & { createGroupAtOnce?: Maybe<(
    { __typename?: 'GroupEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'GroupEntity' }
      & Pick<GroupEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Group' }
        & GroupCardInfoFragment
      )> }
    )> }
  )> }
);

export type UpdateGroupAtOnceMutationVariables = Exact<{
  input?: Maybe<GroupAttributes>;
  groupId: Scalars['ID'];
}>;


export type UpdateGroupAtOnceMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupAtOnce?: Maybe<(
    { __typename?: 'GroupEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'GroupEntity' }
      & Pick<GroupEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Group' }
        & GroupCardInfoFragment
      )> }
    )> }
  )> }
);

export type SendGroupEmailMutationVariables = Exact<{
  input?: Maybe<SendGroupEmailAttributes>;
  groupId: Scalars['ID'];
}>;


export type SendGroupEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendGroupEmail?: Maybe<(
    { __typename?: 'GroupEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'GroupEntity' }
      & Pick<GroupEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Group' }
        & BasicGroupDetailsFragment
      )> }
    )> }
  )> }
);

export type LoginModalVisibilityQueryVariables = Exact<{ [key: string]: never; }>;


export type LoginModalVisibilityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'loginModalVisible'>
);

export type ForgotPasswordVisibilityQueryVariables = Exact<{ [key: string]: never; }>;


export type ForgotPasswordVisibilityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'forgotPasswordModalVisible'>
);

export type SignupModalVisibilityQueryVariables = Exact<{ [key: string]: never; }>;


export type SignupModalVisibilityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'signupModalVisible'>
);

export type LoggedInStateQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInStateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'loggedInState'>
);

export type UserTokenInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTokenInfoQuery = (
  { __typename?: 'Query' }
  & { userTokenInfo: (
    { __typename?: 'UserTokenInfo' }
    & Pick<UserTokenInfo, 'id'>
  ) }
);

export type BlogContentQueryVariables = Exact<{ [key: string]: never; }>;


export type BlogContentQuery = (
  { __typename?: 'Query' }
  & { blogContent: Array<Maybe<(
    { __typename?: 'BlogContent' }
    & Pick<BlogContent, 'id' | 'slug' | 'status'>
    & { content: (
      { __typename?: 'Rendered' }
      & Pick<Rendered, 'rendered'>
    ), title: (
      { __typename?: 'Rendered' }
      & Pick<Rendered, 'rendered'>
    ) }
  )>> }
);

export type GetAllPostsQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  pagination?: Maybe<PaginationArg>;
  filters?: Maybe<PostFiltersInput>;
}>;


export type GetAllPostsQuery = (
  { __typename?: 'Query' }
  & { posts?: Maybe<(
    { __typename?: 'PostEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'PostEntity' }
      & Pick<PostEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Post' }
        & Pick<Post, 'title' | 'slug' | 'content' | 'updatedAt'>
        & { author?: Maybe<(
          { __typename?: 'UsersPermissionsUserEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'UsersPermissionsUserEntity' }
            & Pick<UsersPermissionsUserEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'UsersPermissionsUser' }
              & BasicProfileFragment
            )> }
          )> }
        )> }
      )> }
    )>, meta: (
      { __typename?: 'ResponseCollectionMeta' }
      & PaginationMetaFragment
    ) }
  )> }
);

export type GetAllPostCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPostCategoriesQuery = (
  { __typename?: 'Query' }
  & { postCategories?: Maybe<(
    { __typename?: 'PostCategoryEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'PostCategoryEntity' }
      & Pick<PostCategoryEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'PostCategory' }
        & Pick<PostCategory, 'name'>
      )> }
    )> }
  )> }
);

export type FullProfileFragment = (
  { __typename?: 'UsersPermissionsUser' }
  & Pick<UsersPermissionsUser, 'status' | 'description' | 'birthdate' | 'myTreeId' | 'updatedAt' | 'createdAt'>
  & { reminders?: Maybe<(
    { __typename?: 'ReminderRelationResponseCollection' }
    & { data: Array<(
      { __typename?: 'ReminderEntity' }
      & Pick<ReminderEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Reminder' }
        & Pick<Reminder, 'dayBefore' | 'subject' | 'time'>
      )> }
    )> }
  )>, bookings?: Maybe<(
    { __typename?: 'BookingRelationResponseCollection' }
    & { data: Array<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Booking' }
        & { event?: Maybe<(
          { __typename?: 'EventEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'EventEntity' }
            & Pick<EventEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Event' }
              & BasicEventDetailsFragment
            )> }
          )> }
        )> }
        & BasicBookingInfoFragment
      )> }
    )> }
  )> }
  & BasicProfileFragment
);

export type MyProfileDetailsFragment = (
  { __typename?: 'UsersPermissionsUser' }
  & Pick<UsersPermissionsUser, 'phoneNumber' | 'email'>
  & { address?: Maybe<(
    { __typename?: 'AddressEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'AddressEntity' }
      & Pick<AddressEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Address' }
        & FullAddressFragment
      )> }
    )> }
  )>, groups?: Maybe<(
    { __typename?: 'GroupRelationResponseCollection' }
    & { data: Array<(
      { __typename?: 'GroupEntity' }
      & Pick<GroupEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Group' }
        & GroupCardInfoFragment
      )> }
    )> }
  )>, ledGroups?: Maybe<(
    { __typename?: 'GroupRelationResponseCollection' }
    & { data: Array<(
      { __typename?: 'GroupEntity' }
      & Pick<GroupEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Group' }
        & GroupCardInfoFragment
      )> }
    )> }
  )>, events?: Maybe<Array<Maybe<(
    { __typename?: 'Event' }
    & { bookings?: Maybe<(
      { __typename?: 'BookingRelationResponseCollection' }
      & { data: Array<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'id'>
      )> }
    )> }
    & BasicEventDetailsFragment
  )>>>, categories?: Maybe<(
    { __typename?: 'CategoryRelationResponseCollection' }
    & { data: Array<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'title'>
      )> }
    )> }
  )> }
  & FullProfileFragment
);

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = (
  { __typename?: 'Query' }
  & { usersPermissionsUsers?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & FullProfileFragment
      )> }
    )> }
  )> }
);

export type GetMyAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyAccountQuery = (
  { __typename?: 'Query' }
  & { myAccount?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & MyProfileDetailsFragment
      )> }
    )> }
  )> }
);

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserByIdQuery = (
  { __typename?: 'Query' }
  & { usersPermissionsUser?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & FullProfileFragment
      )> }
    )> }
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & MyProfileDetailsFragment
      )> }
    )> }
  )> }
);

export type JoinGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type JoinGroupMutation = (
  { __typename?: 'Mutation' }
  & { joinGroup?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & MyProfileDetailsFragment
      )> }
    )> }
  )> }
);

export type LeaveGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type LeaveGroupMutation = (
  { __typename?: 'Mutation' }
  & { leaveGroup?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & MyProfileDetailsFragment
      )> }
    )> }
  )> }
);

export type CreateReminderMutationVariables = Exact<{
  input?: Maybe<ReminderAttributes>;
}>;


export type CreateReminderMutation = (
  { __typename?: 'Mutation' }
  & { createReminder?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & MyProfileDetailsFragment
      )> }
    )> }
  )> }
);

export type RemoveReminderMutationVariables = Exact<{
  reminderId: Scalars['ID'];
}>;


export type RemoveReminderMutation = (
  { __typename?: 'Mutation' }
  & { removeReminder?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & MyProfileDetailsFragment
      )> }
    )> }
  )> }
);

export type RegisterMutationVariables = Exact<{
  input: UsersPermissionsRegisterInput;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'UsersPermissionsLoginPayload' }
    & Pick<UsersPermissionsLoginPayload, 'jwt'>
  ) }
);

export type LoginMutationVariables = Exact<{
  input: UsersPermissionsLoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'UsersPermissionsLoginPayload' }
    & Pick<UsersPermissionsLoginPayload, 'jwt'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  code: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'UsersPermissionsLoginPayload' }
    & Pick<UsersPermissionsLoginPayload, 'jwt'>
  )> }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword?: Maybe<(
    { __typename?: 'UsersPermissionsPasswordPayload' }
    & Pick<UsersPermissionsPasswordPayload, 'ok'>
  )> }
);

export const BasicProfileFragmentDoc = gql`
    fragment basicProfile on UsersPermissionsUser {
  id
  username
  firstname
  lastname
  avatarUrl
}
    `;
export const FullBookingDetailsFragmentDoc = gql`
    fragment fullBookingDetails on Booking {
  guests
  visitor {
    data {
      id
      attributes {
        token
        email
      }
    }
  }
  user {
    data {
      id
      attributes {
        ...basicProfile
      }
    }
  }
  status
}
    ${BasicProfileFragmentDoc}`;
export const BasicAddressFragmentDoc = gql`
    fragment basicAddress on Address {
  id
  lat
  lng
  city
  address
  country
}
    `;
export const BasicFamilyTreeInfoFragmentDoc = gql`
    fragment basicFamilyTreeInfo on FamilyTree {
  id
  fullName
  pid
  ppid
  isMyself
  avatarUrl
  tags
  isPartner
  partnerPosition
  deathdate
  birthdate
  bio
  currentLocation {
    data {
      id
      attributes {
        ...basicAddress
      }
    }
  }
  deathLocation {
    data {
      id
      attributes {
        ...basicAddress
      }
    }
  }
  birthLocation {
    data {
      id
      attributes {
        ...basicAddress
      }
    }
  }
}
    ${BasicAddressFragmentDoc}`;
export const BasicFamilyTreeLinksFragmentDoc = gql`
    fragment basicFamilyTreeLinks on FamilyTreeLinks {
  from
  to
  label
}
    `;
export const PaginationMetaFragmentDoc = gql`
    fragment paginationMeta on ResponseCollectionMeta {
  pagination {
    total
    page
    pageSize
    pageCount
  }
}
    `;
export const BasicGroupDetailsFragmentDoc = gql`
    fragment basicGroupDetails on Group {
  id
  name
  slug
  createdAt
}
    `;
export const BasicCategoryInfoFragmentDoc = gql`
    fragment basicCategoryInfo on Category {
  id
  title
  description
}
    `;
export const BasicEventDetailsFragmentDoc = gql`
    fragment basicEventDetails on Event {
  id
  title
  coverPhotoUrl
  startTime
  endTime
  eventType
  isPast
  description
  remainingSeats
  repeatInterval
  address {
    data {
      id
      attributes {
        ...basicAddress
      }
    }
  }
  group {
    data {
      id
      attributes {
        ...basicGroupDetails
      }
    }
  }
  categories {
    data {
      id
      attributes {
        ...basicCategoryInfo
      }
    }
  }
  createdAt
}
    ${BasicAddressFragmentDoc}
${BasicGroupDetailsFragmentDoc}
${BasicCategoryInfoFragmentDoc}`;
export const BasicBookingInfoFragmentDoc = gql`
    fragment basicBookingInfo on Booking {
  id
  status
  isPast
  guests
}
    `;
export const FullEventDetailsFragmentDoc = gql`
    fragment fullEventDetails on Event {
  ...basicEventDetails
  description
  price
  capacity
  eventUrl
  eventType
  bookings {
    data {
      id
      attributes {
        ...basicBookingInfo
        user {
          data {
            id
            attributes {
              ...basicProfile
            }
          }
        }
        visitor {
          data {
            id
            attributes {
              email
            }
          }
        }
      }
    }
  }
  gallery {
    ... on ComponentMediaGallery {
      id
      owner {
        data {
          id
          attributes {
            ...basicProfile
          }
        }
      }
      media {
        id
        date
        caption
        videoUrl
        status
        image {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    ${BasicEventDetailsFragmentDoc}
${BasicBookingInfoFragmentDoc}
${BasicProfileFragmentDoc}`;
export const FullProfileFragmentDoc = gql`
    fragment fullProfile on UsersPermissionsUser {
  ...basicProfile
  status
  description
  birthdate
  myTreeId
  updatedAt
  createdAt
  reminders {
    data {
      id
      attributes {
        dayBefore
        subject
        time
      }
    }
  }
  bookings {
    data {
      id
      attributes {
        ...basicBookingInfo
        event {
          data {
            id
            attributes {
              ...basicEventDetails
            }
          }
        }
      }
    }
  }
}
    ${BasicProfileFragmentDoc}
${BasicBookingInfoFragmentDoc}
${BasicEventDetailsFragmentDoc}`;
export const FullAddressFragmentDoc = gql`
    fragment fullAddress on Address {
  country
  state
  zip
  ...basicAddress
}
    ${BasicAddressFragmentDoc}`;
export const GroupCardInfoFragmentDoc = gql`
    fragment groupCardInfo on Group {
  ...basicGroupDetails
  coverPhotoUrl
  status
  groupType
  members {
    data {
      id
    }
  }
  address {
    data {
      id
      attributes {
        city
        country
      }
    }
  }
  categories {
    data {
      id
      attributes {
        ...basicCategoryInfo
      }
    }
  }
}
    ${BasicGroupDetailsFragmentDoc}
${BasicCategoryInfoFragmentDoc}`;
export const MyProfileDetailsFragmentDoc = gql`
    fragment myProfileDetails on UsersPermissionsUser {
  ...fullProfile
  phoneNumber
  email
  address {
    data {
      id
      attributes {
        ...fullAddress
      }
    }
  }
  groups {
    data {
      id
      attributes {
        ...groupCardInfo
      }
    }
  }
  ledGroups {
    data {
      id
      attributes {
        ...groupCardInfo
      }
    }
  }
  events {
    ...basicEventDetails
    bookings {
      data {
        id
      }
    }
  }
  categories {
    data {
      id
      attributes {
        title
      }
    }
  }
}
    ${FullProfileFragmentDoc}
${FullAddressFragmentDoc}
${GroupCardInfoFragmentDoc}
${BasicEventDetailsFragmentDoc}`;
export const EventRsvpDocument = gql`
    mutation EventRsvp($input: RsvpAttributes, $eventId: ID!) {
  eventRsvp(input: $input, eventId: $eventId) {
    data {
      id
      attributes {
        ...fullEventDetails
      }
    }
  }
}
    ${FullEventDetailsFragmentDoc}`;
export type EventRsvpMutationFn = Apollo.MutationFunction<EventRsvpMutation, EventRsvpMutationVariables>;

/**
 * __useEventRsvpMutation__
 *
 * To run a mutation, you first call `useEventRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventRsvpMutation, { data, loading, error }] = useEventRsvpMutation({
 *   variables: {
 *      input: // value for 'input'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventRsvpMutation(baseOptions?: Apollo.MutationHookOptions<EventRsvpMutation, EventRsvpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EventRsvpMutation, EventRsvpMutationVariables>(EventRsvpDocument, options);
      }
export type EventRsvpMutationHookResult = ReturnType<typeof useEventRsvpMutation>;
export type EventRsvpMutationResult = Apollo.MutationResult<EventRsvpMutation>;
export type EventRsvpMutationOptions = Apollo.BaseMutationOptions<EventRsvpMutation, EventRsvpMutationVariables>;
export const RemoveRsvpDocument = gql`
    mutation RemoveRsvp($bookingId: ID!) {
  removeRsvp(bookingId: $bookingId) {
    data {
      id
      attributes {
        ...fullEventDetails
      }
    }
  }
}
    ${FullEventDetailsFragmentDoc}`;
export type RemoveRsvpMutationFn = Apollo.MutationFunction<RemoveRsvpMutation, RemoveRsvpMutationVariables>;

/**
 * __useRemoveRsvpMutation__
 *
 * To run a mutation, you first call `useRemoveRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRsvpMutation, { data, loading, error }] = useRemoveRsvpMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useRemoveRsvpMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRsvpMutation, RemoveRsvpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRsvpMutation, RemoveRsvpMutationVariables>(RemoveRsvpDocument, options);
      }
export type RemoveRsvpMutationHookResult = ReturnType<typeof useRemoveRsvpMutation>;
export type RemoveRsvpMutationResult = Apollo.MutationResult<RemoveRsvpMutation>;
export type RemoveRsvpMutationOptions = Apollo.BaseMutationOptions<RemoveRsvpMutation, RemoveRsvpMutationVariables>;
export const SendEventInvitationDocument = gql`
    mutation SendEventInvitation($input: InvitationAttributes, $eventId: ID!) {
  sendEventInvitation(input: $input, eventId: $eventId) {
    data {
      id
      attributes {
        ...fullEventDetails
      }
    }
  }
}
    ${FullEventDetailsFragmentDoc}`;
export type SendEventInvitationMutationFn = Apollo.MutationFunction<SendEventInvitationMutation, SendEventInvitationMutationVariables>;

/**
 * __useSendEventInvitationMutation__
 *
 * To run a mutation, you first call `useSendEventInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEventInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEventInvitationMutation, { data, loading, error }] = useSendEventInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useSendEventInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendEventInvitationMutation, SendEventInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEventInvitationMutation, SendEventInvitationMutationVariables>(SendEventInvitationDocument, options);
      }
export type SendEventInvitationMutationHookResult = ReturnType<typeof useSendEventInvitationMutation>;
export type SendEventInvitationMutationResult = Apollo.MutationResult<SendEventInvitationMutation>;
export type SendEventInvitationMutationOptions = Apollo.BaseMutationOptions<SendEventInvitationMutation, SendEventInvitationMutationVariables>;
export const EventInvitationRsvpDocument = gql`
    mutation EventInvitationRsvp($input: InviteRsvpAttributes, $eventId: ID!, $visitorToken: String!) {
  eventInvitationRsvp(
    input: $input
    eventId: $eventId
    visitorToken: $visitorToken
  ) {
    data {
      id
      attributes {
        ...fullBookingDetails
      }
    }
  }
}
    ${FullBookingDetailsFragmentDoc}`;
export type EventInvitationRsvpMutationFn = Apollo.MutationFunction<EventInvitationRsvpMutation, EventInvitationRsvpMutationVariables>;

/**
 * __useEventInvitationRsvpMutation__
 *
 * To run a mutation, you first call `useEventInvitationRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventInvitationRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventInvitationRsvpMutation, { data, loading, error }] = useEventInvitationRsvpMutation({
 *   variables: {
 *      input: // value for 'input'
 *      eventId: // value for 'eventId'
 *      visitorToken: // value for 'visitorToken'
 *   },
 * });
 */
export function useEventInvitationRsvpMutation(baseOptions?: Apollo.MutationHookOptions<EventInvitationRsvpMutation, EventInvitationRsvpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EventInvitationRsvpMutation, EventInvitationRsvpMutationVariables>(EventInvitationRsvpDocument, options);
      }
export type EventInvitationRsvpMutationHookResult = ReturnType<typeof useEventInvitationRsvpMutation>;
export type EventInvitationRsvpMutationResult = Apollo.MutationResult<EventInvitationRsvpMutation>;
export type EventInvitationRsvpMutationOptions = Apollo.BaseMutationOptions<EventInvitationRsvpMutation, EventInvitationRsvpMutationVariables>;
export const VisitorInvitationBookingDocument = gql`
    query VisitorInvitationBooking($eventId: ID!, $visitorToken: String!) {
  visitorInvitationBooking(eventId: $eventId, visitorToken: $visitorToken) {
    data {
      id
      attributes {
        ...fullBookingDetails
      }
    }
  }
}
    ${FullBookingDetailsFragmentDoc}`;

/**
 * __useVisitorInvitationBookingQuery__
 *
 * To run a query within a React component, call `useVisitorInvitationBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorInvitationBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorInvitationBookingQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      visitorToken: // value for 'visitorToken'
 *   },
 * });
 */
export function useVisitorInvitationBookingQuery(baseOptions: Apollo.QueryHookOptions<VisitorInvitationBookingQuery, VisitorInvitationBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitorInvitationBookingQuery, VisitorInvitationBookingQueryVariables>(VisitorInvitationBookingDocument, options);
      }
export function useVisitorInvitationBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitorInvitationBookingQuery, VisitorInvitationBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitorInvitationBookingQuery, VisitorInvitationBookingQueryVariables>(VisitorInvitationBookingDocument, options);
        }
export type VisitorInvitationBookingQueryHookResult = ReturnType<typeof useVisitorInvitationBookingQuery>;
export type VisitorInvitationBookingLazyQueryHookResult = ReturnType<typeof useVisitorInvitationBookingLazyQuery>;
export type VisitorInvitationBookingQueryResult = Apollo.QueryResult<VisitorInvitationBookingQuery, VisitorInvitationBookingQueryVariables>;
export const ChangeRsvpStatusDocument = gql`
    mutation ChangeRsvpStatus($eventId: ID!, $userId: ID!, $status: BookingStatusEnum!) {
  changeRsvpStatus(eventId: $eventId, userId: $userId, status: $status) {
    data {
      id
      attributes {
        ...fullEventDetails
      }
    }
  }
}
    ${FullEventDetailsFragmentDoc}`;
export type ChangeRsvpStatusMutationFn = Apollo.MutationFunction<ChangeRsvpStatusMutation, ChangeRsvpStatusMutationVariables>;

/**
 * __useChangeRsvpStatusMutation__
 *
 * To run a mutation, you first call `useChangeRsvpStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRsvpStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRsvpStatusMutation, { data, loading, error }] = useChangeRsvpStatusMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeRsvpStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeRsvpStatusMutation, ChangeRsvpStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeRsvpStatusMutation, ChangeRsvpStatusMutationVariables>(ChangeRsvpStatusDocument, options);
      }
export type ChangeRsvpStatusMutationHookResult = ReturnType<typeof useChangeRsvpStatusMutation>;
export type ChangeRsvpStatusMutationResult = Apollo.MutationResult<ChangeRsvpStatusMutation>;
export type ChangeRsvpStatusMutationOptions = Apollo.BaseMutationOptions<ChangeRsvpStatusMutation, ChangeRsvpStatusMutationVariables>;
export const CategoriesDocument = gql`
    query Categories {
  categories {
    data {
      id
      attributes {
        slug
        order
        coverPhotoUrl
        ...basicCategoryInfo
        subCategories {
          data {
            id
          }
        }
        parent {
          data {
            id
          }
        }
      }
    }
  }
}
    ${BasicCategoryInfoFragmentDoc}`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const GetAllEventsDocument = gql`
    query GetAllEvents($sort: [String], $pagination: PaginationArg, $filters: EventFiltersInput) {
  events(sort: $sort, pagination: $pagination, filters: $filters) {
    data {
      id
      attributes {
        ...basicEventDetails
      }
    }
    meta {
      ...paginationMeta
    }
  }
}
    ${BasicEventDetailsFragmentDoc}
${PaginationMetaFragmentDoc}`;

/**
 * __useGetAllEventsQuery__
 *
 * To run a query within a React component, call `useGetAllEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAllEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEventsQuery, GetAllEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEventsQuery, GetAllEventsQueryVariables>(GetAllEventsDocument, options);
      }
export function useGetAllEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventsQuery, GetAllEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEventsQuery, GetAllEventsQueryVariables>(GetAllEventsDocument, options);
        }
export type GetAllEventsQueryHookResult = ReturnType<typeof useGetAllEventsQuery>;
export type GetAllEventsLazyQueryHookResult = ReturnType<typeof useGetAllEventsLazyQuery>;
export type GetAllEventsQueryResult = Apollo.QueryResult<GetAllEventsQuery, GetAllEventsQueryVariables>;
export const GetEventByIdDocument = gql`
    query GetEventById($id: ID!) {
  event(id: $id) {
    data {
      id
      attributes {
        ...fullEventDetails
      }
    }
  }
}
    ${FullEventDetailsFragmentDoc}`;

/**
 * __useGetEventByIdQuery__
 *
 * To run a query within a React component, call `useGetEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventByIdQuery(baseOptions: Apollo.QueryHookOptions<GetEventByIdQuery, GetEventByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventByIdQuery, GetEventByIdQueryVariables>(GetEventByIdDocument, options);
      }
export function useGetEventByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventByIdQuery, GetEventByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventByIdQuery, GetEventByIdQueryVariables>(GetEventByIdDocument, options);
        }
export type GetEventByIdQueryHookResult = ReturnType<typeof useGetEventByIdQuery>;
export type GetEventByIdLazyQueryHookResult = ReturnType<typeof useGetEventByIdLazyQuery>;
export type GetEventByIdQueryResult = Apollo.QueryResult<GetEventByIdQuery, GetEventByIdQueryVariables>;
export const CreateEventAtOnceDocument = gql`
    mutation CreateEventAtOnce($input: EventAttributes) {
  createEventAtOnce(input: $input) {
    data {
      id
      attributes {
        ...fullEventDetails
      }
    }
  }
}
    ${FullEventDetailsFragmentDoc}`;
export type CreateEventAtOnceMutationFn = Apollo.MutationFunction<CreateEventAtOnceMutation, CreateEventAtOnceMutationVariables>;

/**
 * __useCreateEventAtOnceMutation__
 *
 * To run a mutation, you first call `useCreateEventAtOnceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventAtOnceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventAtOnceMutation, { data, loading, error }] = useCreateEventAtOnceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventAtOnceMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventAtOnceMutation, CreateEventAtOnceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventAtOnceMutation, CreateEventAtOnceMutationVariables>(CreateEventAtOnceDocument, options);
      }
export type CreateEventAtOnceMutationHookResult = ReturnType<typeof useCreateEventAtOnceMutation>;
export type CreateEventAtOnceMutationResult = Apollo.MutationResult<CreateEventAtOnceMutation>;
export type CreateEventAtOnceMutationOptions = Apollo.BaseMutationOptions<CreateEventAtOnceMutation, CreateEventAtOnceMutationVariables>;
export const UpdateEventAtOnceDocument = gql`
    mutation UpdateEventAtOnce($input: EventAttributes, $eventId: ID!) {
  updateEventAtOnce(input: $input, eventId: $eventId) {
    data {
      id
      attributes {
        ...fullEventDetails
      }
    }
  }
}
    ${FullEventDetailsFragmentDoc}`;
export type UpdateEventAtOnceMutationFn = Apollo.MutationFunction<UpdateEventAtOnceMutation, UpdateEventAtOnceMutationVariables>;

/**
 * __useUpdateEventAtOnceMutation__
 *
 * To run a mutation, you first call `useUpdateEventAtOnceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventAtOnceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventAtOnceMutation, { data, loading, error }] = useUpdateEventAtOnceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useUpdateEventAtOnceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventAtOnceMutation, UpdateEventAtOnceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventAtOnceMutation, UpdateEventAtOnceMutationVariables>(UpdateEventAtOnceDocument, options);
      }
export type UpdateEventAtOnceMutationHookResult = ReturnType<typeof useUpdateEventAtOnceMutation>;
export type UpdateEventAtOnceMutationResult = Apollo.MutationResult<UpdateEventAtOnceMutation>;
export type UpdateEventAtOnceMutationOptions = Apollo.BaseMutationOptions<UpdateEventAtOnceMutation, UpdateEventAtOnceMutationVariables>;
export const RemoveEventPhotoDocument = gql`
    mutation RemoveEventPhoto($eventId: ID!, $mediaId: ID!) {
  removeEventPhoto(eventId: $eventId, mediaId: $mediaId) {
    data {
      id
      attributes {
        ...fullEventDetails
      }
    }
  }
}
    ${FullEventDetailsFragmentDoc}`;
export type RemoveEventPhotoMutationFn = Apollo.MutationFunction<RemoveEventPhotoMutation, RemoveEventPhotoMutationVariables>;

/**
 * __useRemoveEventPhotoMutation__
 *
 * To run a mutation, you first call `useRemoveEventPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventPhotoMutation, { data, loading, error }] = useRemoveEventPhotoMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useRemoveEventPhotoMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEventPhotoMutation, RemoveEventPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEventPhotoMutation, RemoveEventPhotoMutationVariables>(RemoveEventPhotoDocument, options);
      }
export type RemoveEventPhotoMutationHookResult = ReturnType<typeof useRemoveEventPhotoMutation>;
export type RemoveEventPhotoMutationResult = Apollo.MutationResult<RemoveEventPhotoMutation>;
export type RemoveEventPhotoMutationOptions = Apollo.BaseMutationOptions<RemoveEventPhotoMutation, RemoveEventPhotoMutationVariables>;
export const ApproveEventPhotoDocument = gql`
    mutation ApproveEventPhoto($eventId: ID!, $mediaId: ID!) {
  approveEventPhoto(eventId: $eventId, mediaId: $mediaId) {
    data {
      id
      attributes {
        ...fullEventDetails
      }
    }
  }
}
    ${FullEventDetailsFragmentDoc}`;
export type ApproveEventPhotoMutationFn = Apollo.MutationFunction<ApproveEventPhotoMutation, ApproveEventPhotoMutationVariables>;

/**
 * __useApproveEventPhotoMutation__
 *
 * To run a mutation, you first call `useApproveEventPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveEventPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveEventPhotoMutation, { data, loading, error }] = useApproveEventPhotoMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useApproveEventPhotoMutation(baseOptions?: Apollo.MutationHookOptions<ApproveEventPhotoMutation, ApproveEventPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveEventPhotoMutation, ApproveEventPhotoMutationVariables>(ApproveEventPhotoDocument, options);
      }
export type ApproveEventPhotoMutationHookResult = ReturnType<typeof useApproveEventPhotoMutation>;
export type ApproveEventPhotoMutationResult = Apollo.MutationResult<ApproveEventPhotoMutation>;
export type ApproveEventPhotoMutationOptions = Apollo.BaseMutationOptions<ApproveEventPhotoMutation, ApproveEventPhotoMutationVariables>;
export const UploadEventMediaDocument = gql`
    mutation UploadEventMedia($input: EventMediaAttributes, $eventId: ID!) {
  uploadEventMedia(input: $input, eventId: $eventId) {
    data {
      id
      attributes {
        ...fullEventDetails
      }
    }
  }
}
    ${FullEventDetailsFragmentDoc}`;
export type UploadEventMediaMutationFn = Apollo.MutationFunction<UploadEventMediaMutation, UploadEventMediaMutationVariables>;

/**
 * __useUploadEventMediaMutation__
 *
 * To run a mutation, you first call `useUploadEventMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEventMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEventMediaMutation, { data, loading, error }] = useUploadEventMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useUploadEventMediaMutation(baseOptions?: Apollo.MutationHookOptions<UploadEventMediaMutation, UploadEventMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadEventMediaMutation, UploadEventMediaMutationVariables>(UploadEventMediaDocument, options);
      }
export type UploadEventMediaMutationHookResult = ReturnType<typeof useUploadEventMediaMutation>;
export type UploadEventMediaMutationResult = Apollo.MutationResult<UploadEventMediaMutation>;
export type UploadEventMediaMutationOptions = Apollo.BaseMutationOptions<UploadEventMediaMutation, UploadEventMediaMutationVariables>;
export const SendEventEmailDocument = gql`
    mutation SendEventEmail($input: SendEventEmailAttributes, $eventId: ID!) {
  sendEventEmail(input: $input, eventId: $eventId) {
    data {
      id
      attributes {
        ...fullEventDetails
      }
    }
  }
}
    ${FullEventDetailsFragmentDoc}`;
export type SendEventEmailMutationFn = Apollo.MutationFunction<SendEventEmailMutation, SendEventEmailMutationVariables>;

/**
 * __useSendEventEmailMutation__
 *
 * To run a mutation, you first call `useSendEventEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEventEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEventEmailMutation, { data, loading, error }] = useSendEventEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useSendEventEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEventEmailMutation, SendEventEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEventEmailMutation, SendEventEmailMutationVariables>(SendEventEmailDocument, options);
      }
export type SendEventEmailMutationHookResult = ReturnType<typeof useSendEventEmailMutation>;
export type SendEventEmailMutationResult = Apollo.MutationResult<SendEventEmailMutation>;
export type SendEventEmailMutationOptions = Apollo.BaseMutationOptions<SendEventEmailMutation, SendEventEmailMutationVariables>;
export const FamilyTreeByNodeIdDocument = gql`
    query familyTreeByNodeId($nodeId: ID!) {
  familyTreeByNodeId(nodeId: $nodeId) {
    nodes {
      ...basicFamilyTreeInfo
    }
    links {
      ...basicFamilyTreeLinks
    }
  }
}
    ${BasicFamilyTreeInfoFragmentDoc}
${BasicFamilyTreeLinksFragmentDoc}`;

/**
 * __useFamilyTreeByNodeIdQuery__
 *
 * To run a query within a React component, call `useFamilyTreeByNodeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamilyTreeByNodeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamilyTreeByNodeIdQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useFamilyTreeByNodeIdQuery(baseOptions: Apollo.QueryHookOptions<FamilyTreeByNodeIdQuery, FamilyTreeByNodeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FamilyTreeByNodeIdQuery, FamilyTreeByNodeIdQueryVariables>(FamilyTreeByNodeIdDocument, options);
      }
export function useFamilyTreeByNodeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FamilyTreeByNodeIdQuery, FamilyTreeByNodeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FamilyTreeByNodeIdQuery, FamilyTreeByNodeIdQueryVariables>(FamilyTreeByNodeIdDocument, options);
        }
export type FamilyTreeByNodeIdQueryHookResult = ReturnType<typeof useFamilyTreeByNodeIdQuery>;
export type FamilyTreeByNodeIdLazyQueryHookResult = ReturnType<typeof useFamilyTreeByNodeIdLazyQuery>;
export type FamilyTreeByNodeIdQueryResult = Apollo.QueryResult<FamilyTreeByNodeIdQuery, FamilyTreeByNodeIdQueryVariables>;
export const AddFamilyRelationAtOnceDocument = gql`
    mutation AddFamilyRelationAtOnce($input: FamilyTreeAttributes!) {
  addFamilyRelationAtOnce(input: $input) {
    data {
      id
      attributes {
        ...basicFamilyTreeInfo
      }
    }
  }
}
    ${BasicFamilyTreeInfoFragmentDoc}`;
export type AddFamilyRelationAtOnceMutationFn = Apollo.MutationFunction<AddFamilyRelationAtOnceMutation, AddFamilyRelationAtOnceMutationVariables>;

/**
 * __useAddFamilyRelationAtOnceMutation__
 *
 * To run a mutation, you first call `useAddFamilyRelationAtOnceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFamilyRelationAtOnceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFamilyRelationAtOnceMutation, { data, loading, error }] = useAddFamilyRelationAtOnceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFamilyRelationAtOnceMutation(baseOptions?: Apollo.MutationHookOptions<AddFamilyRelationAtOnceMutation, AddFamilyRelationAtOnceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFamilyRelationAtOnceMutation, AddFamilyRelationAtOnceMutationVariables>(AddFamilyRelationAtOnceDocument, options);
      }
export type AddFamilyRelationAtOnceMutationHookResult = ReturnType<typeof useAddFamilyRelationAtOnceMutation>;
export type AddFamilyRelationAtOnceMutationResult = Apollo.MutationResult<AddFamilyRelationAtOnceMutation>;
export type AddFamilyRelationAtOnceMutationOptions = Apollo.BaseMutationOptions<AddFamilyRelationAtOnceMutation, AddFamilyRelationAtOnceMutationVariables>;
export const RemoveFamilyTreeNodeDocument = gql`
    mutation RemoveFamilyTreeNode($treeNodeId: ID!) {
  removeFamilyTreeNode(treeNodeId: $treeNodeId) {
    data {
      id
      attributes {
        ...basicFamilyTreeInfo
      }
    }
  }
}
    ${BasicFamilyTreeInfoFragmentDoc}`;
export type RemoveFamilyTreeNodeMutationFn = Apollo.MutationFunction<RemoveFamilyTreeNodeMutation, RemoveFamilyTreeNodeMutationVariables>;

/**
 * __useRemoveFamilyTreeNodeMutation__
 *
 * To run a mutation, you first call `useRemoveFamilyTreeNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFamilyTreeNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFamilyTreeNodeMutation, { data, loading, error }] = useRemoveFamilyTreeNodeMutation({
 *   variables: {
 *      treeNodeId: // value for 'treeNodeId'
 *   },
 * });
 */
export function useRemoveFamilyTreeNodeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFamilyTreeNodeMutation, RemoveFamilyTreeNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFamilyTreeNodeMutation, RemoveFamilyTreeNodeMutationVariables>(RemoveFamilyTreeNodeDocument, options);
      }
export type RemoveFamilyTreeNodeMutationHookResult = ReturnType<typeof useRemoveFamilyTreeNodeMutation>;
export type RemoveFamilyTreeNodeMutationResult = Apollo.MutationResult<RemoveFamilyTreeNodeMutation>;
export type RemoveFamilyTreeNodeMutationOptions = Apollo.BaseMutationOptions<RemoveFamilyTreeNodeMutation, RemoveFamilyTreeNodeMutationVariables>;
export const EditFamilyRelationAtOnceDocument = gql`
    mutation EditFamilyRelationAtOnce($input: FamilyTreeAttributes!, $treeNodeId: ID!) {
  editFamilyRelationAtOnce(input: $input, treeNodeId: $treeNodeId) {
    data {
      id
      attributes {
        ...basicFamilyTreeInfo
      }
    }
  }
}
    ${BasicFamilyTreeInfoFragmentDoc}`;
export type EditFamilyRelationAtOnceMutationFn = Apollo.MutationFunction<EditFamilyRelationAtOnceMutation, EditFamilyRelationAtOnceMutationVariables>;

/**
 * __useEditFamilyRelationAtOnceMutation__
 *
 * To run a mutation, you first call `useEditFamilyRelationAtOnceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFamilyRelationAtOnceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFamilyRelationAtOnceMutation, { data, loading, error }] = useEditFamilyRelationAtOnceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      treeNodeId: // value for 'treeNodeId'
 *   },
 * });
 */
export function useEditFamilyRelationAtOnceMutation(baseOptions?: Apollo.MutationHookOptions<EditFamilyRelationAtOnceMutation, EditFamilyRelationAtOnceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFamilyRelationAtOnceMutation, EditFamilyRelationAtOnceMutationVariables>(EditFamilyRelationAtOnceDocument, options);
      }
export type EditFamilyRelationAtOnceMutationHookResult = ReturnType<typeof useEditFamilyRelationAtOnceMutation>;
export type EditFamilyRelationAtOnceMutationResult = Apollo.MutationResult<EditFamilyRelationAtOnceMutation>;
export type EditFamilyRelationAtOnceMutationOptions = Apollo.BaseMutationOptions<EditFamilyRelationAtOnceMutation, EditFamilyRelationAtOnceMutationVariables>;
export const GetAllGroupsDocument = gql`
    query GetAllGroups($sort: [String], $pagination: PaginationArg, $filters: GroupFiltersInput) {
  groups(sort: $sort, pagination: $pagination, filters: $filters) {
    data {
      id
      attributes {
        ...basicGroupDetails
        coverPhotoUrl
        status
        groupType
        description
        members {
          data {
            id
            attributes {
              ...basicProfile
            }
          }
        }
        address {
          data {
            id
            attributes {
              ...basicAddress
            }
          }
        }
        organizers {
          data {
            id
            attributes {
              ...basicProfile
            }
          }
        }
        events {
          data {
            id
            attributes {
              ...basicEventDetails
            }
          }
        }
        categories {
          data {
            id
            attributes {
              ...basicCategoryInfo
            }
          }
        }
      }
    }
    meta {
      ...paginationMeta
    }
  }
}
    ${BasicGroupDetailsFragmentDoc}
${BasicProfileFragmentDoc}
${BasicAddressFragmentDoc}
${BasicEventDetailsFragmentDoc}
${BasicCategoryInfoFragmentDoc}
${PaginationMetaFragmentDoc}`;

/**
 * __useGetAllGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGroupsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAllGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGroupsQuery, GetAllGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGroupsQuery, GetAllGroupsQueryVariables>(GetAllGroupsDocument, options);
      }
export function useGetAllGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGroupsQuery, GetAllGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGroupsQuery, GetAllGroupsQueryVariables>(GetAllGroupsDocument, options);
        }
export type GetAllGroupsQueryHookResult = ReturnType<typeof useGetAllGroupsQuery>;
export type GetAllGroupsLazyQueryHookResult = ReturnType<typeof useGetAllGroupsLazyQuery>;
export type GetAllGroupsQueryResult = Apollo.QueryResult<GetAllGroupsQuery, GetAllGroupsQueryVariables>;
export const CreateGroupAtOnceDocument = gql`
    mutation CreateGroupAtOnce($input: GroupAttributes) {
  createGroupAtOnce(input: $input) {
    data {
      id
      attributes {
        ...groupCardInfo
      }
    }
  }
}
    ${GroupCardInfoFragmentDoc}`;
export type CreateGroupAtOnceMutationFn = Apollo.MutationFunction<CreateGroupAtOnceMutation, CreateGroupAtOnceMutationVariables>;

/**
 * __useCreateGroupAtOnceMutation__
 *
 * To run a mutation, you first call `useCreateGroupAtOnceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupAtOnceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupAtOnceMutation, { data, loading, error }] = useCreateGroupAtOnceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupAtOnceMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupAtOnceMutation, CreateGroupAtOnceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupAtOnceMutation, CreateGroupAtOnceMutationVariables>(CreateGroupAtOnceDocument, options);
      }
export type CreateGroupAtOnceMutationHookResult = ReturnType<typeof useCreateGroupAtOnceMutation>;
export type CreateGroupAtOnceMutationResult = Apollo.MutationResult<CreateGroupAtOnceMutation>;
export type CreateGroupAtOnceMutationOptions = Apollo.BaseMutationOptions<CreateGroupAtOnceMutation, CreateGroupAtOnceMutationVariables>;
export const UpdateGroupAtOnceDocument = gql`
    mutation UpdateGroupAtOnce($input: GroupAttributes, $groupId: ID!) {
  updateGroupAtOnce(input: $input, groupId: $groupId) {
    data {
      id
      attributes {
        ...groupCardInfo
      }
    }
  }
}
    ${GroupCardInfoFragmentDoc}`;
export type UpdateGroupAtOnceMutationFn = Apollo.MutationFunction<UpdateGroupAtOnceMutation, UpdateGroupAtOnceMutationVariables>;

/**
 * __useUpdateGroupAtOnceMutation__
 *
 * To run a mutation, you first call `useUpdateGroupAtOnceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupAtOnceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupAtOnceMutation, { data, loading, error }] = useUpdateGroupAtOnceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useUpdateGroupAtOnceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupAtOnceMutation, UpdateGroupAtOnceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupAtOnceMutation, UpdateGroupAtOnceMutationVariables>(UpdateGroupAtOnceDocument, options);
      }
export type UpdateGroupAtOnceMutationHookResult = ReturnType<typeof useUpdateGroupAtOnceMutation>;
export type UpdateGroupAtOnceMutationResult = Apollo.MutationResult<UpdateGroupAtOnceMutation>;
export type UpdateGroupAtOnceMutationOptions = Apollo.BaseMutationOptions<UpdateGroupAtOnceMutation, UpdateGroupAtOnceMutationVariables>;
export const SendGroupEmailDocument = gql`
    mutation SendGroupEmail($input: SendGroupEmailAttributes, $groupId: ID!) {
  sendGroupEmail(input: $input, groupId: $groupId) {
    data {
      id
      attributes {
        ...basicGroupDetails
      }
    }
  }
}
    ${BasicGroupDetailsFragmentDoc}`;
export type SendGroupEmailMutationFn = Apollo.MutationFunction<SendGroupEmailMutation, SendGroupEmailMutationVariables>;

/**
 * __useSendGroupEmailMutation__
 *
 * To run a mutation, you first call `useSendGroupEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGroupEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGroupEmailMutation, { data, loading, error }] = useSendGroupEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSendGroupEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendGroupEmailMutation, SendGroupEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendGroupEmailMutation, SendGroupEmailMutationVariables>(SendGroupEmailDocument, options);
      }
export type SendGroupEmailMutationHookResult = ReturnType<typeof useSendGroupEmailMutation>;
export type SendGroupEmailMutationResult = Apollo.MutationResult<SendGroupEmailMutation>;
export type SendGroupEmailMutationOptions = Apollo.BaseMutationOptions<SendGroupEmailMutation, SendGroupEmailMutationVariables>;
export const LoginModalVisibilityDocument = gql`
    query LoginModalVisibility {
  loginModalVisible @client
}
    `;

/**
 * __useLoginModalVisibilityQuery__
 *
 * To run a query within a React component, call `useLoginModalVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginModalVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginModalVisibilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoginModalVisibilityQuery(baseOptions?: Apollo.QueryHookOptions<LoginModalVisibilityQuery, LoginModalVisibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginModalVisibilityQuery, LoginModalVisibilityQueryVariables>(LoginModalVisibilityDocument, options);
      }
export function useLoginModalVisibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginModalVisibilityQuery, LoginModalVisibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginModalVisibilityQuery, LoginModalVisibilityQueryVariables>(LoginModalVisibilityDocument, options);
        }
export type LoginModalVisibilityQueryHookResult = ReturnType<typeof useLoginModalVisibilityQuery>;
export type LoginModalVisibilityLazyQueryHookResult = ReturnType<typeof useLoginModalVisibilityLazyQuery>;
export type LoginModalVisibilityQueryResult = Apollo.QueryResult<LoginModalVisibilityQuery, LoginModalVisibilityQueryVariables>;
export const ForgotPasswordVisibilityDocument = gql`
    query ForgotPasswordVisibility {
  forgotPasswordModalVisible @client
}
    `;

/**
 * __useForgotPasswordVisibilityQuery__
 *
 * To run a query within a React component, call `useForgotPasswordVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForgotPasswordVisibilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useForgotPasswordVisibilityQuery(baseOptions?: Apollo.QueryHookOptions<ForgotPasswordVisibilityQuery, ForgotPasswordVisibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ForgotPasswordVisibilityQuery, ForgotPasswordVisibilityQueryVariables>(ForgotPasswordVisibilityDocument, options);
      }
export function useForgotPasswordVisibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForgotPasswordVisibilityQuery, ForgotPasswordVisibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ForgotPasswordVisibilityQuery, ForgotPasswordVisibilityQueryVariables>(ForgotPasswordVisibilityDocument, options);
        }
export type ForgotPasswordVisibilityQueryHookResult = ReturnType<typeof useForgotPasswordVisibilityQuery>;
export type ForgotPasswordVisibilityLazyQueryHookResult = ReturnType<typeof useForgotPasswordVisibilityLazyQuery>;
export type ForgotPasswordVisibilityQueryResult = Apollo.QueryResult<ForgotPasswordVisibilityQuery, ForgotPasswordVisibilityQueryVariables>;
export const SignupModalVisibilityDocument = gql`
    query SignupModalVisibility {
  signupModalVisible @client
}
    `;

/**
 * __useSignupModalVisibilityQuery__
 *
 * To run a query within a React component, call `useSignupModalVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupModalVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupModalVisibilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignupModalVisibilityQuery(baseOptions?: Apollo.QueryHookOptions<SignupModalVisibilityQuery, SignupModalVisibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignupModalVisibilityQuery, SignupModalVisibilityQueryVariables>(SignupModalVisibilityDocument, options);
      }
export function useSignupModalVisibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignupModalVisibilityQuery, SignupModalVisibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignupModalVisibilityQuery, SignupModalVisibilityQueryVariables>(SignupModalVisibilityDocument, options);
        }
export type SignupModalVisibilityQueryHookResult = ReturnType<typeof useSignupModalVisibilityQuery>;
export type SignupModalVisibilityLazyQueryHookResult = ReturnType<typeof useSignupModalVisibilityLazyQuery>;
export type SignupModalVisibilityQueryResult = Apollo.QueryResult<SignupModalVisibilityQuery, SignupModalVisibilityQueryVariables>;
export const LoggedInStateDocument = gql`
    query LoggedInState {
  loggedInState @client
}
    `;

/**
 * __useLoggedInStateQuery__
 *
 * To run a query within a React component, call `useLoggedInStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInStateQuery(baseOptions?: Apollo.QueryHookOptions<LoggedInStateQuery, LoggedInStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoggedInStateQuery, LoggedInStateQueryVariables>(LoggedInStateDocument, options);
      }
export function useLoggedInStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoggedInStateQuery, LoggedInStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoggedInStateQuery, LoggedInStateQueryVariables>(LoggedInStateDocument, options);
        }
export type LoggedInStateQueryHookResult = ReturnType<typeof useLoggedInStateQuery>;
export type LoggedInStateLazyQueryHookResult = ReturnType<typeof useLoggedInStateLazyQuery>;
export type LoggedInStateQueryResult = Apollo.QueryResult<LoggedInStateQuery, LoggedInStateQueryVariables>;
export const UserTokenInfoDocument = gql`
    query UserTokenInfo {
  userTokenInfo @client {
    id
  }
}
    `;

/**
 * __useUserTokenInfoQuery__
 *
 * To run a query within a React component, call `useUserTokenInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTokenInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTokenInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTokenInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserTokenInfoQuery, UserTokenInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTokenInfoQuery, UserTokenInfoQueryVariables>(UserTokenInfoDocument, options);
      }
export function useUserTokenInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTokenInfoQuery, UserTokenInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTokenInfoQuery, UserTokenInfoQueryVariables>(UserTokenInfoDocument, options);
        }
export type UserTokenInfoQueryHookResult = ReturnType<typeof useUserTokenInfoQuery>;
export type UserTokenInfoLazyQueryHookResult = ReturnType<typeof useUserTokenInfoLazyQuery>;
export type UserTokenInfoQueryResult = Apollo.QueryResult<UserTokenInfoQuery, UserTokenInfoQueryVariables>;
export const BlogContentDocument = gql`
    query BlogContent {
  blogContent @client {
    id
    slug
    status
    content {
      rendered
    }
    title {
      rendered
    }
  }
}
    `;

/**
 * __useBlogContentQuery__
 *
 * To run a query within a React component, call `useBlogContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlogContentQuery(baseOptions?: Apollo.QueryHookOptions<BlogContentQuery, BlogContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogContentQuery, BlogContentQueryVariables>(BlogContentDocument, options);
      }
export function useBlogContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogContentQuery, BlogContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogContentQuery, BlogContentQueryVariables>(BlogContentDocument, options);
        }
export type BlogContentQueryHookResult = ReturnType<typeof useBlogContentQuery>;
export type BlogContentLazyQueryHookResult = ReturnType<typeof useBlogContentLazyQuery>;
export type BlogContentQueryResult = Apollo.QueryResult<BlogContentQuery, BlogContentQueryVariables>;
export const GetAllPostsDocument = gql`
    query GetAllPosts($sort: [String], $pagination: PaginationArg, $filters: PostFiltersInput) {
  posts(sort: $sort, pagination: $pagination, filters: $filters) {
    data {
      id
      attributes {
        title
        slug
        content
        updatedAt
        author {
          data {
            id
            attributes {
              ...basicProfile
            }
          }
        }
      }
    }
    meta {
      ...paginationMeta
    }
  }
}
    ${BasicProfileFragmentDoc}
${PaginationMetaFragmentDoc}`;

/**
 * __useGetAllPostsQuery__
 *
 * To run a query within a React component, call `useGetAllPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPostsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAllPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPostsQuery, GetAllPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPostsQuery, GetAllPostsQueryVariables>(GetAllPostsDocument, options);
      }
export function useGetAllPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPostsQuery, GetAllPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPostsQuery, GetAllPostsQueryVariables>(GetAllPostsDocument, options);
        }
export type GetAllPostsQueryHookResult = ReturnType<typeof useGetAllPostsQuery>;
export type GetAllPostsLazyQueryHookResult = ReturnType<typeof useGetAllPostsLazyQuery>;
export type GetAllPostsQueryResult = Apollo.QueryResult<GetAllPostsQuery, GetAllPostsQueryVariables>;
export const GetAllPostCategoriesDocument = gql`
    query GetAllPostCategories {
  postCategories {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;

/**
 * __useGetAllPostCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllPostCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPostCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPostCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPostCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPostCategoriesQuery, GetAllPostCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPostCategoriesQuery, GetAllPostCategoriesQueryVariables>(GetAllPostCategoriesDocument, options);
      }
export function useGetAllPostCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPostCategoriesQuery, GetAllPostCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPostCategoriesQuery, GetAllPostCategoriesQueryVariables>(GetAllPostCategoriesDocument, options);
        }
export type GetAllPostCategoriesQueryHookResult = ReturnType<typeof useGetAllPostCategoriesQuery>;
export type GetAllPostCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllPostCategoriesLazyQuery>;
export type GetAllPostCategoriesQueryResult = Apollo.QueryResult<GetAllPostCategoriesQuery, GetAllPostCategoriesQueryVariables>;
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  usersPermissionsUsers {
    data {
      id
      attributes {
        ...fullProfile
      }
    }
  }
}
    ${FullProfileFragmentDoc}`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetMyAccountDocument = gql`
    query GetMyAccount {
  myAccount {
    data {
      id
      attributes {
        ...myProfileDetails
      }
    }
  }
}
    ${MyProfileDetailsFragmentDoc}`;

/**
 * __useGetMyAccountQuery__
 *
 * To run a query within a React component, call `useGetMyAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetMyAccountQuery, GetMyAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyAccountQuery, GetMyAccountQueryVariables>(GetMyAccountDocument, options);
      }
export function useGetMyAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyAccountQuery, GetMyAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyAccountQuery, GetMyAccountQueryVariables>(GetMyAccountDocument, options);
        }
export type GetMyAccountQueryHookResult = ReturnType<typeof useGetMyAccountQuery>;
export type GetMyAccountLazyQueryHookResult = ReturnType<typeof useGetMyAccountLazyQuery>;
export type GetMyAccountQueryResult = Apollo.QueryResult<GetMyAccountQuery, GetMyAccountQueryVariables>;
export const GetUserByIdDocument = gql`
    query GetUserById($id: ID!) {
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        ...fullProfile
      }
    }
  }
}
    ${FullProfileFragmentDoc}`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    data {
      id
      attributes {
        ...myProfileDetails
      }
    }
  }
}
    ${MyProfileDetailsFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const JoinGroupDocument = gql`
    mutation JoinGroup($groupId: ID!) {
  joinGroup(groupId: $groupId) {
    data {
      id
      attributes {
        ...myProfileDetails
      }
    }
  }
}
    ${MyProfileDetailsFragmentDoc}`;
export type JoinGroupMutationFn = Apollo.MutationFunction<JoinGroupMutation, JoinGroupMutationVariables>;

/**
 * __useJoinGroupMutation__
 *
 * To run a mutation, you first call `useJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinGroupMutation, { data, loading, error }] = useJoinGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useJoinGroupMutation(baseOptions?: Apollo.MutationHookOptions<JoinGroupMutation, JoinGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinGroupMutation, JoinGroupMutationVariables>(JoinGroupDocument, options);
      }
export type JoinGroupMutationHookResult = ReturnType<typeof useJoinGroupMutation>;
export type JoinGroupMutationResult = Apollo.MutationResult<JoinGroupMutation>;
export type JoinGroupMutationOptions = Apollo.BaseMutationOptions<JoinGroupMutation, JoinGroupMutationVariables>;
export const LeaveGroupDocument = gql`
    mutation LeaveGroup($groupId: ID!) {
  leaveGroup(groupId: $groupId) {
    data {
      id
      attributes {
        ...myProfileDetails
      }
    }
  }
}
    ${MyProfileDetailsFragmentDoc}`;
export type LeaveGroupMutationFn = Apollo.MutationFunction<LeaveGroupMutation, LeaveGroupMutationVariables>;

/**
 * __useLeaveGroupMutation__
 *
 * To run a mutation, you first call `useLeaveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveGroupMutation, { data, loading, error }] = useLeaveGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useLeaveGroupMutation(baseOptions?: Apollo.MutationHookOptions<LeaveGroupMutation, LeaveGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveGroupMutation, LeaveGroupMutationVariables>(LeaveGroupDocument, options);
      }
export type LeaveGroupMutationHookResult = ReturnType<typeof useLeaveGroupMutation>;
export type LeaveGroupMutationResult = Apollo.MutationResult<LeaveGroupMutation>;
export type LeaveGroupMutationOptions = Apollo.BaseMutationOptions<LeaveGroupMutation, LeaveGroupMutationVariables>;
export const CreateReminderDocument = gql`
    mutation CreateReminder($input: ReminderAttributes) {
  createReminder(input: $input) {
    data {
      id
      attributes {
        ...myProfileDetails
      }
    }
  }
}
    ${MyProfileDetailsFragmentDoc}`;
export type CreateReminderMutationFn = Apollo.MutationFunction<CreateReminderMutation, CreateReminderMutationVariables>;

/**
 * __useCreateReminderMutation__
 *
 * To run a mutation, you first call `useCreateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReminderMutation, { data, loading, error }] = useCreateReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReminderMutation(baseOptions?: Apollo.MutationHookOptions<CreateReminderMutation, CreateReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReminderMutation, CreateReminderMutationVariables>(CreateReminderDocument, options);
      }
export type CreateReminderMutationHookResult = ReturnType<typeof useCreateReminderMutation>;
export type CreateReminderMutationResult = Apollo.MutationResult<CreateReminderMutation>;
export type CreateReminderMutationOptions = Apollo.BaseMutationOptions<CreateReminderMutation, CreateReminderMutationVariables>;
export const RemoveReminderDocument = gql`
    mutation RemoveReminder($reminderId: ID!) {
  removeReminder(reminderId: $reminderId) {
    data {
      id
      attributes {
        ...myProfileDetails
      }
    }
  }
}
    ${MyProfileDetailsFragmentDoc}`;
export type RemoveReminderMutationFn = Apollo.MutationFunction<RemoveReminderMutation, RemoveReminderMutationVariables>;

/**
 * __useRemoveReminderMutation__
 *
 * To run a mutation, you first call `useRemoveReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReminderMutation, { data, loading, error }] = useRemoveReminderMutation({
 *   variables: {
 *      reminderId: // value for 'reminderId'
 *   },
 * });
 */
export function useRemoveReminderMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReminderMutation, RemoveReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveReminderMutation, RemoveReminderMutationVariables>(RemoveReminderDocument, options);
      }
export type RemoveReminderMutationHookResult = ReturnType<typeof useRemoveReminderMutation>;
export type RemoveReminderMutationResult = Apollo.MutationResult<RemoveReminderMutation>;
export type RemoveReminderMutationOptions = Apollo.BaseMutationOptions<RemoveReminderMutation, RemoveReminderMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
  resetPassword(
    password: $password
    passwordConfirmation: $passwordConfirmation
    code: $code
  ) {
    jwt
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const namedOperations = {
  Query: {
    VisitorInvitationBooking: 'VisitorInvitationBooking',
    Categories: 'Categories',
    GetAllEvents: 'GetAllEvents',
    GetEventById: 'GetEventById',
    familyTreeByNodeId: 'familyTreeByNodeId',
    GetAllGroups: 'GetAllGroups',
    LoginModalVisibility: 'LoginModalVisibility',
    ForgotPasswordVisibility: 'ForgotPasswordVisibility',
    SignupModalVisibility: 'SignupModalVisibility',
    LoggedInState: 'LoggedInState',
    UserTokenInfo: 'UserTokenInfo',
    BlogContent: 'BlogContent',
    GetAllPosts: 'GetAllPosts',
    GetAllPostCategories: 'GetAllPostCategories',
    GetAllUsers: 'GetAllUsers',
    GetMyAccount: 'GetMyAccount',
    GetUserById: 'GetUserById'
  },
  Mutation: {
    EventRsvp: 'EventRsvp',
    RemoveRsvp: 'RemoveRsvp',
    SendEventInvitation: 'SendEventInvitation',
    EventInvitationRsvp: 'EventInvitationRsvp',
    ChangeRsvpStatus: 'ChangeRsvpStatus',
    CreateEventAtOnce: 'CreateEventAtOnce',
    UpdateEventAtOnce: 'UpdateEventAtOnce',
    RemoveEventPhoto: 'RemoveEventPhoto',
    ApproveEventPhoto: 'ApproveEventPhoto',
    UploadEventMedia: 'UploadEventMedia',
    SendEventEmail: 'SendEventEmail',
    AddFamilyRelationAtOnce: 'AddFamilyRelationAtOnce',
    RemoveFamilyTreeNode: 'RemoveFamilyTreeNode',
    EditFamilyRelationAtOnce: 'EditFamilyRelationAtOnce',
    CreateGroupAtOnce: 'CreateGroupAtOnce',
    UpdateGroupAtOnce: 'UpdateGroupAtOnce',
    SendGroupEmail: 'SendGroupEmail',
    UpdateProfile: 'UpdateProfile',
    JoinGroup: 'JoinGroup',
    LeaveGroup: 'LeaveGroup',
    CreateReminder: 'CreateReminder',
    RemoveReminder: 'RemoveReminder',
    Register: 'Register',
    Login: 'Login',
    ResetPassword: 'ResetPassword',
    ForgotPassword: 'ForgotPassword'
  },
  Fragment: {
    fullBookingDetails: 'fullBookingDetails',
    basicAddress: 'basicAddress',
    fullAddress: 'fullAddress',
    basicGroupDetails: 'basicGroupDetails',
    basicEventDetails: 'basicEventDetails',
    basicProfile: 'basicProfile',
    basicCategoryInfo: 'basicCategoryInfo',
    groupCardInfo: 'groupCardInfo',
    basicBookingInfo: 'basicBookingInfo',
    basicFamilyTreeInfo: 'basicFamilyTreeInfo',
    basicFamilyTreeLinks: 'basicFamilyTreeLinks',
    paginationMeta: 'paginationMeta',
    fullEventDetails: 'fullEventDetails',
    fullProfile: 'fullProfile',
    myProfileDetails: 'myProfileDetails'
  }
}