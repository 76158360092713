import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { SearchBar } from 'components/elements';
import { FilterOutlined } from '@ant-design/icons';
import { TreeSelect } from 'antd';
import { SiderWrapper, FilterAreaWrapper, FilterHead, FilterByCheckboxWrapper, FilterTitle } from './index.styled';

const { TreeNode } = TreeSelect;

type SearchOption = {
  label: string;
  value: string;
  parentId: string;
};
interface SearchSidebarProp {
  onSearch?: (value: string) => void;
  filterByCategories?: {
    key: string;
    title: string;
    options: SearchOption[];
    onChange: (value: { key: string; value: string }[]) => void;
    defaultValue?: string[];
  }[];
  searchDefaultValue: string;
}

const RenderTreeNodes = (options: SearchOption[]) => {
  const parents = useMemo(() => (options || []).filter((option) => !option.parentId), [options]);
  const findSubTrees = useCallback(
    (parentId: string) => {
      return (options || []).filter((option) => option.parentId === parentId);
    },
    [options],
  );
  const renderTree = useCallback(
    (parentItems: SearchOption[]) => {
      if (!parentItems || !parentItems.length) {
        return null;
      }
      return parentItems.map((parent: SearchOption) => {
        return (
          <TreeNode value={parent.value} title={parent.label}>
            {renderTree(findSubTrees(parent.value))}
          </TreeNode>
        );
      });
    },
    [findSubTrees],
  );
  return renderTree(parents);
};
const SearchSidebar = ({ onSearch, filterByCategories, searchDefaultValue }: SearchSidebarProp) => {
  return (
    <SiderWrapper>
      <SearchBar onSubmit={onSearch} defaultValue={searchDefaultValue} />
      <FilterAreaWrapper>
        <FilterHead>
          <FilterOutlined />
          Filter
        </FilterHead>
        <FilterByCheckboxWrapper>
          {filterByCategories &&
            filterByCategories.map((filterByCategory) => {
              return (
                <div key={filterByCategory.key}>
                  <FilterTitle>
                    <span className="line" />
                    <span className="title">{filterByCategory.title}</span>
                  </FilterTitle>
                  <TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    value={filterByCategory.defaultValue}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Select Category filter"
                    allowClear
                    multiple
                    treeDefaultExpandAll
                    onChange={(value) => filterByCategory.onChange(value as any)}
                  >
                    {RenderTreeNodes(filterByCategory.options)}
                  </TreeSelect>
                </div>
              );
            })}
        </FilterByCheckboxWrapper>
      </FilterAreaWrapper>
    </SiderWrapper>
  );
};

export default SearchSidebar;

export const WithSiderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: auto;
`;
