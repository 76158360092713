import React, { useState, useEffect, useCallback } from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';

interface SwitchAndInputProps {
  fieldValue: any;
  setFieldsValue: (values: any) => void;
  fieldName: string;
  switchOffDefaultValue: any;
  switchOnDefaultValue: any;
  children: React.ReactNode;
  checkedName: string;
  unCheckedName: string;
}

const SwitchAndInput = ({
  fieldValue,
  setFieldsValue,
  fieldName,
  switchOffDefaultValue,
  switchOnDefaultValue,
  children,
  checkedName,
  unCheckedName,
}: SwitchAndInputProps) => {
  const [switchChecked, setSwitch] = useState(!fieldValue);

  useEffect(() => {
    setSwitch(!fieldValue);
  }, [fieldValue]);

  const setSwitchChecked = useCallback(() => {
    if (!switchChecked) {
      setFieldsValue({ [fieldName]: switchOffDefaultValue });
    } else {
      setFieldsValue({ [fieldName]: switchOnDefaultValue });
    }
    setSwitch(!switchChecked);
  }, [fieldName, setFieldsValue, switchChecked, switchOffDefaultValue, switchOnDefaultValue]);

  return (
    <SwitchAndInputWrapper>
      <div className="switchWrapper">
        <Switch
          checkedChildren={checkedName}
          unCheckedChildren={unCheckedName}
          checked={switchChecked}
          onChange={setSwitchChecked}
        />
      </div>
      <div
        style={{
          display: switchChecked ? 'none' : 'block',
        }}
      >
        {children}
      </div>
    </SwitchAndInputWrapper>
  );
};

export default SwitchAndInput;

const SwitchAndInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .switchWrapper {
    width: 8rem;
    margin: 2rem 0;
  }
  .ant-switch {
    height: 30px;
    line-height: 30px;
  }
  .ant-switch-handle {
    top: 6px;
  }
`;
