import React, { useCallback, useMemo } from 'react';
import { useGetMyAccountQuery, BasicEventDetailsFragment } from 'graphql/types';
import { PaginatingItems, AddBoxButton } from 'components/elements';
import EventCard from 'components/eventCard';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { StyledWrapper } from './events.styled';

type MyBookingEvents = BasicEventDetailsFragment[];
const Events = () => {
  const { data, loading } = useGetMyAccountQuery();
  const history = useHistory();

  const onAddNewEvent = useCallback(() => {
    if (data?.myAccount?.data?.attributes?.ledGroups && data?.myAccount?.data?.attributes?.ledGroups?.data.length) {
      history.push('/account/events/create');
    } else {
      notification.warning({
        message: "You Don't have any Group",
        description: 'In order to make your first Event You need to create your Group first.',
      });
    }
  }, [data, history]);

  const myBookings = useMemo(() => {
    return (data?.myAccount?.data?.attributes?.bookings.data || []).reduce(
      (acc, booking) => {
        const eventEnity = {
          id: booking.attributes.event.data.id,
          ...booking.attributes.event.data.attributes,
        };
        if (booking.attributes?.isPast) {
          acc.past.push(eventEnity);
        } else {
          acc.upcoming.push(eventEnity);
        }
        return acc;
      },
      { past: [] as MyBookingEvents, upcoming: [] as MyBookingEvents },
    );
  }, [data]);

  return (
    <StyledWrapper>
      <section>
        <PaginatingItems
          title="My Upcoming Events"
          items={myBookings.upcoming}
          renderList={EventCard}
          loading={loading}
          perView={4}
          pageSize={8}
          controlledPaginating
        />
      </section>
      <section>
        <PaginatingItems
          title="My Past Events"
          items={myBookings.past}
          renderList={EventCard}
          loading={loading}
          perView={4}
          pageSize={8}
          controlledPaginating
        />
      </section>
      <section>
        <PaginatingItems
          title="Manage Events I Made"
          firstItem={<AddBoxButton onClick={onAddNewEvent} btnText="Add new Event" />}
          items={data?.myAccount?.data?.attributes?.events || []}
          renderList={(props) => <EventCard {...props} editable bookings={props?.bookings?.data} />}
          loading={loading}
          perView={4}
          pageSize={8}
          controlledPaginating
        />
      </section>
    </StyledWrapper>
  );
};

export default Events;
