import React, { useCallback, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { resizeImg, ResizeImageResult } from 'helpers';
import { message, Input } from 'antd';
import { Modal, DatePicker } from 'components/elements';
import Cropper from 'react-easy-crop';
import { Moment } from 'moment';
import { colors, spacer } from 'settings/style';

const { TextArea } = Input;

export interface FileInfoInput {
  alternativeText?: string;
  name?: string;
  caption?: string;
  date?: string;
}

export type ImageInputAction = (value: ResizeImageResult, extraInfo?: FileInfoInput) => void;

interface ImportInputProps {
  onChange?: ImageInputAction;
  children?: React.ReactElement;
  width?: number;
  height?: number;
  cropping?: boolean;
  validating?: boolean;
  disabled?: boolean;
  withEditor?: boolean; // should show image editor for drag and resize
  withInfo?: boolean; // should show extra input for image info like caption
}

function ImportInput({
  onChange = () => {},
  width = 200,
  height = 200,
  cropping = true,
  validating = false,
  disabled = false,
  children,
  withEditor = false,
  withInfo = false,
  ...rest
}: ImportInputProps) {
  const [modalVisible, setModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppingImage, setCroppingImage] = useState({ url: '', blob: null });
  const croppedConfigs = useRef({ width: 0, height: 0, x: 0, y: 0 });
  const [caption, setCaption] = useState('');
  const [date, setDate] = useState<undefined | Moment>();

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    croppedConfigs.current = croppedAreaPixels;
  }, []);

  const onConfirmCropping = useCallback(() => {
    const extraInfo = withInfo
      ? {
          caption,
          date: date ? date.format() : null,
        }
      : null;

    if (withEditor) {
      resizeImg({
        file: croppingImage.blob,
        _width: width,
        _height: height,
        crop: cropping,
        validate: validating,
        cropOptions: croppedConfigs.current,
      }).then((data) => {
        onChange(data, extraInfo);
        setModal(false);
        setCrop({ x: 0, y: 0 });
      });
    } else {
      resizeImg({
        file: croppingImage.blob,
        _width: width,
        _height: height,
        crop: cropping,
        validate: validating,
      }).then((data) => {
        onChange(data, extraInfo);
        setModal(false);
      });
    }
  }, [withInfo, caption, date, withEditor, croppingImage.blob, width, height, cropping, validating, onChange]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        if (!withEditor && !withInfo) {
          await resizeImg({
            file: acceptedFiles[0],
            _width: width,
            _height: height,
            crop: cropping,
            validate: validating,
          }).then((data) => {
            onChange(data);
          });
        } else {
          const blob = acceptedFiles[0];
          const reader = new FileReader();
          reader.onload = (e) => {
            setCroppingImage({ url: e.target.result as string, blob });
            setModal(true);
          };
          reader.readAsDataURL(blob);
        }
      } catch (err) {
        if (err instanceof Error) {
          message.error(err.message);
        }
      }
    },
    [cropping, height, onChange, validating, width, withEditor, withInfo],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  return (
    <>
      <ImportInputWrapper
        {...getRootProps({
          onClick: (e) => e.preventDefault(),
        })}
        {...rest}
      >
        <input {...getInputProps()} disabled={disabled} />
        {children}
      </ImportInputWrapper>
      <Modal
        title="Preview"
        open={modalVisible}
        onRightText="Confirm"
        onCancel={() => setModal(false)}
        onRight={onConfirmCropping}
      >
        <CopperWrapper>
          {withEditor ? (
            <Cropper
              image={croppingImage?.url}
              crop={crop}
              zoom={zoom}
              aspect={width / height}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          ) : (
            <PreviewImageWrapper>
              <img src={croppingImage?.url} alt="preview" />
            </PreviewImageWrapper>
          )}
          {!!withInfo && (
            <>
              <TextArea
                rows={2}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCaption(e.target.value)}
                placeholder="Enter Caption For This Image ..."
              />
              <DateWrapper>
                Date: <DatePicker onChange={(data) => setDate(data)} value={date} />
              </DateWrapper>
            </>
          )}
        </CopperWrapper>
      </Modal>
    </>
  );
}

export default ImportInput;

const ImportInputWrapper = styled.div`
  outline: none;
`;

const CopperWrapper = styled.div`
  height: 60vh;
  margin-bottom: 20px;
  position: relative;
`;

const PreviewImageWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  img {
    max-height: 300px;
    max-width: 300px;
  }
`;

const DateWrapper = styled.div`
  padding-top: ${spacer.md};
  color: ${colors.white};
`;
