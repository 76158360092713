import React, { useMemo, useEffect, useCallback } from 'react';
import { useGetAllEventsLazyQuery, useCategoriesQuery, GetAllEventsQueryVariables } from 'graphql/types';
import { PaginatingItems, Container, SearchSidebar } from 'components/elements';
import styled from 'styled-components';
import EventCard from 'components/eventCard';
import { WithSiderWrapper } from 'components/elements/searchSidebar';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { spacer } from '../../settings/style';

const pageSize = 20;

export default function EventsView() {
  const [getEvents, { data: eventResult, loading: eventLoading }] = useGetAllEventsLazyQuery();
  const { data: categoriesResult, loading: categoriesLoading } = useCategoriesQuery();
  const categories = useMemo(
    () =>
      (categoriesResult?.categories?.data || []).map((category) => ({
        label: category?.attributes?.title,
        value: category.id,
        parentId: category?.attributes?.parent?.data?.id,
      })),
    [categoriesResult],
  );

  const { search } = useLocation();
  const history = useHistory();
  const searchQuery = useMemo(() => qs.parse(search, { arrayFormat: 'bracket' }), [search]);

  const updatePageParams = (searchParams: any) => {
    history.push(`/events?${qs.stringify(searchParams, { arrayFormat: 'bracket' })}`);
  };

  const getEventsPage = useCallback(
    (page: number) => {
      const searchFilter = { sort: 'createdAt:desc', pagination: { page, pageSize } } as GetAllEventsQueryVariables;
      const searchFilterProps = {} as any;
      if (searchQuery?.categories) {
        searchFilterProps.categories = { id: { in: searchQuery?.categories } };
      }
      if (searchQuery?.search) {
        searchFilterProps.title = { containsi: searchQuery?.search as string };
      }

      getEvents({ variables: { ...searchFilter, filters: searchFilterProps } });
    },
    [getEvents, searchQuery],
  );

  useEffect(() => {
    getEventsPage(1);
  }, [getEvents, getEventsPage, searchQuery]);

  const eventsData = eventResult?.events?.data || [];
  const pagination = eventResult?.events?.meta?.pagination;

  return (
    <Container>
      <WithSiderWrapper>
        <SearchSidebar
          searchDefaultValue={(searchQuery.search as string) || ''}
          onSearch={(value) => updatePageParams({ ...searchQuery, search: value })}
          filterByCategories={[
            {
              title: 'category',
              key: 'category',
              onChange: (values) => updatePageParams({ ...searchQuery, categories: values }),
              options: categories,
              defaultValue: (searchQuery.categories as string[]) || [],
            },
          ]}
        />
        <EventsListWrapper>
          <PaginatingItems
            items={eventsData}
            loading={eventLoading || categoriesLoading}
            renderList={(props) => <EventCard {...props?.attributes} />}
            perView={4}
            pageSize={pagination?.pageSize}
            total={pagination?.total}
            current={pagination?.page}
            onChange={(page) => getEventsPage(page)}
          />
        </EventsListWrapper>
      </WithSiderWrapper>
    </Container>
  );
}

const EventsListWrapper = styled.div`
  padding: ${spacer.xl};
  flex: 1;
`;
