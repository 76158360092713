import { fontSize, fontWeight, radius, colors, spacer } from 'settings/style';
import styled from 'styled-components';

const height = 70;
export const StyledWrapper = styled.div`
  display: flex;
  height: ${height}px;
  border-radius: ${radius.lg};
  overflow: hidden;
`;

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${spacer.md};
  flex: 1;
  position: relative;
  &:first-child {
    background-color: ${colors.red300};
    color: ${colors.white};
    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      width: 0;
      height: 0;
      border-top: ${height / 2}px solid transparent;
      border-left: ${height / 2}px solid ${colors.red300};
      border-bottom: ${height / 2}px solid transparent;
      z-index: 1;
    }
  }
  &:last-child {
    background-color: ${colors.gray1100};
  }
`;

export const StyledText = styled.span`
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.bolder};
`;

export const StyledDay = styled.span`
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.light};
`;
