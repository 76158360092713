import React from 'react';
import styled from 'styled-components';

interface EditableProps {
  input: React.ComponentType<any>;
  preview: (value: any) => React.ReactElement;
  isEditable?: boolean;
}

function Editable({ input: Input, preview, isEditable, ...rest }: EditableProps) {
  return <EditableWrapper>{isEditable ? <Input {...rest} /> : preview(rest)}</EditableWrapper>;
}

export default Editable;

const EditableWrapper = styled.div`
  /* outline: none; */
`;
