import { useCallback, useState, useEffect } from 'react';
import { useBlogContentQuery, BlogContent } from 'graphql/types';
import { blogConnection } from 'helpers/restClient';
import { blogContent } from 'cache';
import { message } from 'antd';

function useBlogPost() {
  const blogContentResult = useBlogContentQuery();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<BlogContent[]>([]);

  const fetchBlogPages = useCallback(async () => {
    try {
      setLoading(true);
      const result = await blogConnection.get('/wp-json/wp/v2/pages');
      setContent(result?.data);
      blogContent(result?.data || []);
      setLoading(false);
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
      }
    }
  }, []);

  useEffect(() => {
    if (blogContentResult?.data.blogContent && blogContentResult?.data.blogContent.length) {
      setContent(blogContentResult?.data.blogContent);
    } else {
      fetchBlogPages();
    }
  }, [blogContentResult, fetchBlogPages]);

  return [loading, content] as const;
}

export default useBlogPost;
