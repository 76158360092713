import styled from 'styled-components';
import { colors, spacer, fontType, snippet, media } from 'settings/style';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacer.xl};
  flex: 1;
  align-self: stretch;
  ${media.lg`
    padding-right:${spacer.lg};
  `}
  section {
    margin: ${spacer.xl} 0;
    form {
      max-width: 400px;
    }
  }
`;

export const StyledTitle = styled.span`
  color: ${colors.font};
  margin-bottom: ${spacer.xl};
  ${fontType.lg}
  ${snippet.underline()}
`;

export const StyledText = styled.span`
  color: ${colors.font};
  ${fontType.md}
`;
