import React from 'react';
import { FormItem, SearchBox, SwitchAndInput } from 'components/elements';
import { Input, Select, Form, InputNumber } from 'antd';
import { EventTypeEnum } from 'graphql/types';

function EventDetailsStep() {
  return (
    <>
      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.eventType !== currentValues.eventType}>
        {({ getFieldValue, setFieldsValue }) => {
          const price = getFieldValue('price');
          return (
            <SwitchAndInput
              fieldValue={price}
              setFieldsValue={setFieldsValue}
              fieldName="price"
              switchOffDefaultValue={0}
              switchOnDefaultValue={15}
              checkedName="Free"
              unCheckedName="Premium"
            >
              <FormItem name="price" label="Enter Price" theme="primary" initialValue={0}>
                <InputNumber
                  formatter={(value) =>
                    `${value}`
                      .replace(/([^0-9.])/g, '')
                      .replace(/^(\d+.?\d{0,2})\d*$/, '$1')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/([^0-9.])/g, '') as any}
                />
              </FormItem>
            </SwitchAndInput>
          );
        }}
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.eventType !== currentValues.eventType}>
        {({ getFieldValue, setFieldsValue }) => {
          const capacity = getFieldValue('capacity');
          return (
            <SwitchAndInput
              fieldValue={capacity}
              setFieldsValue={setFieldsValue}
              fieldName="capacity"
              switchOffDefaultValue={0}
              switchOnDefaultValue={50}
              checkedName="Unlimited Seats"
              unCheckedName="Limited Seats"
            >
              <FormItem name="capacity" label="Enter capacity" theme="primary" initialValue={0}>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/([^0-9.])/g, '')}
                  parser={(value) => value.replace(/([^0-9.])/g, '') as any}
                />
              </FormItem>
            </SwitchAndInput>
          );
        }}
      </Form.Item>

      <FormItem
        name="eventType"
        label="What is your Event Type"
        theme="primary"
        rules={[
          {
            required: true,
            message: 'Please Select your Event Type!',
          },
        ]}
      >
        <Select
          options={[
            { label: 'Physical Event', value: EventTypeEnum.Physical },
            { label: 'Virtual Event', value: EventTypeEnum.Virtual },
            { label: 'Both', value: EventTypeEnum.Both },
          ]}
        />
      </FormItem>
      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.eventType !== currentValues.eventType}>
        {({ getFieldValue }) => {
          const results = [];
          const eventType = getFieldValue('eventType');

          if ([EventTypeEnum.Virtual, EventTypeEnum.Both].includes(eventType)) {
            results.push(
              <FormItem
                key="eventUrlInput"
                name="eventUrl"
                label="Event Online URL"
                theme="primary"
                rules={[
                  {
                    type: 'url',
                    message: 'The input is not valid web URL',
                  },
                ]}
              >
                <Input />
              </FormItem>,
            );
          }
          if ([EventTypeEnum.Physical, EventTypeEnum.Both].includes(eventType)) {
            results.push(
              <FormItem key="addressInput" name="address" label="Location Address" theme="primary">
                <SearchBox />
              </FormItem>,
            );
          }
          return results;
        }}
      </Form.Item>
    </>
  );
}

export default EventDetailsStep;
