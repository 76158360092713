import { spacer, colors, fontSize, fontWeight, radius, media, fontType } from 'settings/style';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const StyledTitle = styled.span`
  display: inline-flex;
  color: ${colors.font};
  ${fontType.xl}
  padding-bottom: ${spacer.xl};
`;

export const StyledButton = styled.div`
  i {
    color: ${colors.white};
    font-size: ${fontSize.md};
  }
`;

export const StyledBlock = styled.div`
  &:first-child {
    flex: 3;
    margin-right: ${spacer.lg};
    ${media.md`
        flex:0 0 100%;
        margin-right:0;
        margin-bottom: ${spacer.lg};
    `}
  }
  &:last-child {
    flex: 1;
    margin-left: auto;
    padding-left: ${spacer.lg};
    ${media.md`
      flex:0 0 100%;
      padding-left:0;
    `}
  }
`;

export const StyledHeadCard = styled.div`
  background-color: ${colors.primary100};
  border-radius: ${radius.lg};
  margin-top: ${spacer.xl};
  display: flex;
  overflow: hidden;
`;

export const StyledRecurringText = styled.div`
  border: 3px solid ${colors.red300};
  border-radius: ${radius.lg};
  text-align: center;
  padding: ${spacer.sm};
  margin-top: ${spacer.lg};
  font-weight: ${fontWeight.bold};
`;

export const StyledHeadWrapper = styled.div`
  margin-bottom: ${spacer.xl};
`;

export const StyledHeadWrap = styled.div<{ fullWidth: boolean }>`
  display: inline-flex;
  flex-flow: column;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '165px')};
`;

export const StyledHeadText = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.font};
  color: ${colors.accent};
  font-size: ${fontSize.md};
  font-weight: ${fontWeight.bold};
  border-top-left-radius: ${radius.lg};
  padding: ${spacer.sm};
`;

export const StyledHeadTextBold = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary300};
  color: ${colors.white};
  font-size: ${fontSize.xxl};
  font-weight: ${fontWeight.bold};
  border-bottom-left-radius: ${radius.lg};
  padding: ${spacer.lg};
  line-height: 1;
  flex: 1;
`;

export const StyledButtonText = styled.span`
  display: inline-flex;
  margin-left: ${spacer.sm};
  color: ${colors.white};
`;

export const StyledHeadDetail = styled.div`
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  padding: ${spacer.md} ${spacer.lg};
`;

export const StyledGuestWrap = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacer.lg};
`;

export const GuestInvitationTitle = styled.p`
  text-align: center;
  font-weight: ${fontWeight.bold};
`;

export const VisitorInvitaionResponseTitle = styled.p`
  text-align: center;
  font-weight: ${fontWeight.bold};
  padding: ${spacer.md};
  .approved {
    color: ${colors.success};
  }
  .rejected {
    color: ${colors.danger};
  }
`;

export const StyledGuestText = styled.div`
  display: inline-flex;
  white-space: nowrap;
`;

export const StyledSeatsLeft = styled.div`
  display: block;
  white-space: nowrap;
  padding-bottom: ${spacer.sm};
`;

export const StyledGuestCount = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  border-radius: ${radius.lg};
  height: 30px;
  min-width: 54px;
  margin-left: ${spacer.lg};
  padding: 0 ${spacer.sm};
  .ant-input-number {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

export const StyledHeadImgWrap = styled.div<{ coverPhotoUrl?: string }>`
  height: 100%;
  background: ${({ coverPhotoUrl }) => `url(${coverPhotoUrl})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: ${radius.lg};
  overflow: hidden;
`;

export const StyledHeadImg = styled.img`
  object-fit: cover;
`;

export const AttendingStatus = styled.div`
  font-size: ${fontSize.sm};
`;
