import { spacer, media } from 'settings/style';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacer.xl};
  flex: 1;
  align-self: stretch;
  ${media.lg`
    padding-right:${spacer.lg};
  `}
  section {
    margin: ${spacer.xl} 0;
  }
`;

export const a = '';
