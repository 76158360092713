import React, { useMemo } from 'react';
import { Button, Authenticatable } from 'components/elements';
import { useJoinGroupMutation, useGetMyAccountQuery, useLeaveGroupMutation } from 'graphql/types';
import { loginModalVisible } from 'cache';

interface JoinGroupButtonProps {
  groupId: string;
}

export default function JoinGroupButton({ groupId }: JoinGroupButtonProps) {
  const [joinGroup, { loading: joinLoading }] = useJoinGroupMutation();
  const [leaveGroup, { loading: leaveLoading }] = useLeaveGroupMutation();
  const { data: accountResult } = useGetMyAccountQuery();

  const isJoined = useMemo(() => {
    return (accountResult?.myAccount?.data?.attributes?.groups?.data || []).some((group) => group.id === groupId);
  }, [accountResult, groupId]);

  const text = isJoined ? 'Leave This Group' : 'Join This Group ';
  const action = () => {
    if (isJoined) {
      leaveGroup({ variables: { groupId } });
    } else {
      joinGroup({ variables: { groupId } });
    }
  };

  return (
    <Authenticatable
      title="For joining groups you need to login first!"
      okText="Login"
      onConfirm={() => loginModalVisible(true)}
    >
      <Button size="large" onClick={action} type="primary" danger loading={joinLoading || leaveLoading}>
        {text}
      </Button>
    </Authenticatable>
  );
}
