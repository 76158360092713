import React, { useState } from 'react';
import { Button, InvitationModal } from 'components/elements';
import useEventAccessInfo from 'hooks/useEventAccessInfo';
import { StyledWrapper, StyledText, StyledLink, StyledButtonText } from './socialMedia.styled';

const SocialMedia = ({ eventId }: { eventId: string }) => {
  const [modalVisible, setModal] = useState(false);
  const { isEventOrganizer } = useEventAccessInfo(eventId);
  return (
    <>
      <StyledWrapper>
        <StyledText>Share:</StyledText>
        <StyledLink>
          <a href="/#">
            <i className="icon-twitter" />
          </a>
          <a href="/#">
            <i className="icon-instagram" />
          </a>
          <a href="/#">
            <i className="icon-facebook" />
          </a>
        </StyledLink>
        <Button style={{ margin: '15px' }} color="font" type="primary" ghost onClick={() => {}}>
          <StyledButtonText>Donate</StyledButtonText>
        </Button>
      </StyledWrapper>
      {isEventOrganizer && (
        <>
          <StyledWrapper>
            <StyledText> Invite your friends:</StyledText>
            <StyledLink />
            <Button style={{ margin: '15px' }} color="font" type="primary" ghost onClick={() => setModal(true)}>
              <StyledButtonText>Invite</StyledButtonText>
            </Button>
          </StyledWrapper>
          <InvitationModal modalVisible={modalVisible} setModal={setModal} eventId={eventId} />
        </>
      )}
    </>
  );
};

export default SocialMedia;
