import React from 'react';
import { useGetMyAccountQuery } from 'graphql/types';
import { PaginatingItems, AddBoxButton } from 'components/elements';
import GroupCard from 'components/groupCard';
import { useHistory } from 'react-router-dom';
import { StyledWrapper } from './groups.styled';

const Groups = () => {
  const { data, loading } = useGetMyAccountQuery();
  const history = useHistory();

  return (
    <StyledWrapper>
      <section>
        <PaginatingItems
          title="Groups I've joint"
          items={data?.myAccount?.data?.attributes?.groups?.data || []}
          renderList={(props) => <GroupCard {...props} />}
          loading={loading}
          perView={3}
          pageSize={6}
          controlledPaginating
        />
      </section>
      <section>
        <PaginatingItems
          title="Manage Groups I Made"
          firstItem={<AddBoxButton onClick={() => history.push('/account/groups/create')} btnText="Add new Group" />}
          items={data?.myAccount?.data?.attributes?.ledGroups?.data || []}
          renderList={(props) => <GroupCard {...props} editable isPending={props?.attributes?.status !== 'approved'} />}
          loading={loading}
          perView={3}
          pageSize={6}
          controlledPaginating
        />
      </section>
    </StyledWrapper>
  );
};

export default Groups;
