import React from 'react';
import { Tag, Input, Tooltip, Button } from 'antd';

import _ from 'lodash';

interface InputValue {
  currentValue: string;
  valueLists: string[];
}

interface ListItemInputProps {
  placeholder?: string;
  onChange?: (value: InputValue) => void;
  value?: InputValue;
  isValid?: boolean;
}

function ListItemInput({ onChange = () => {}, value: { currentValue, valueLists }, isValid }: ListItemInputProps) {
  const handleClose = (removedItem: string) => {
    const newValueLists = valueLists.filter((valueItem) => valueItem !== removedItem);
    onChange({
      valueLists: newValueLists,
      currentValue,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      valueLists,
      currentValue: e.target.value,
    });
  };

  const handleInputConfirm = () => {
    if (isValid) {
      const newValueLists =
        currentValue && !valueLists.includes(currentValue) ? [...valueLists, currentValue] : valueLists;
      onChange({
        valueLists: newValueLists,
        currentValue: '',
      });
    }
  };

  const onEditValueItem = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, valueItem: string) => {
    e.preventDefault();
    const newValueLists = valueLists.filter((item) => item !== valueItem);
    onChange({
      valueLists: newValueLists,
      currentValue: valueItem,
    });
  };

  return (
    <div>
      {valueLists.map((valueItem, index) => {
        const isLongTag = valueItem.length > 20;
        const tagElem = (
          <Tag
            key={valueItem}
            color="#FFB300"
            closable={index >= 0}
            onClose={() => handleClose(valueItem)}
            style={{ borderRadius: 5 }}
          >
            <span onDoubleClick={(e) => onEditValueItem(e, valueItem)}>{_.truncate(valueItem, { length: 20 })}</span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={valueItem} key={valueItem}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      <Input
        style={{ width: '90%' }}
        type="text"
        size="small"
        value={currentValue}
        onChange={handleInputChange}
        onBlur={handleInputConfirm}
      />
      <Button type="primary" shape="circle" onClick={handleInputConfirm}>
        <span role="img" aria-label="add image">
          ➕
        </span>
      </Button>
    </div>
  );
}

export default ListItemInput;
