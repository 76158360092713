import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import useBlogPost from 'hooks/useBlogPost';
import { Spinner } from 'components/elements';
import { StyledWrapper } from './index.styled';
import StyleSheetWrapper from './blogTheme.style';
import BlockStyleWrapper from './blockLibrary.style';

type MatchParams = { slug: string };
type PublicPageProps = RouteComponentProps<MatchParams>;

export default function PublicPage(props: PublicPageProps) {
  const {
    match: {
      params: { slug },
    },
  } = props;

  const [loading, blogPosts] = useBlogPost();

  const page = useMemo(() => {
    return blogPosts.find((post) => post.slug === slug);
  }, [blogPosts, slug]);

  return (
    <StyledWrapper>
      {loading && <Spinner />}
      {page ? (
        <StyleSheetWrapper>
          <BlockStyleWrapper>
            <div className="entry-content">{ReactHtmlParser(page.content?.rendered)}</div>
          </BlockStyleWrapper>
        </StyleSheetWrapper>
      ) : null}
    </StyledWrapper>
  );
}
