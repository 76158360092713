import React, { useEffect, useMemo } from 'react';
import { Form, message } from 'antd';
import {
  GroupTypeEnum,
  useUpdateGroupAtOnceMutation,
  useGetAllGroupsQuery,
  GroupAttributes,
  namedOperations,
} from 'graphql/types';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { ResizeImageResult, ParseGoogleAddressResult } from 'helpers';
import StepBarForm from 'components/stepBarForm';
import { Spinner, NotFound } from 'components/elements';
import CoverPhotoStep from './coverPhotoStep';
import InformationStep from './informationStep';
import AddCategoriesStep from '../category/addCategoriesStep';

interface FromFields {
  name: string;
  description: string;
  categories: string[];
  coverPhoto: ResizeImageResult;
  address?: ParseGoogleAddressResult;
  groupType: GroupTypeEnum;
}

const steps = [
  { title: 'Cover Photo', pageTitle: 'Change Cover Photo', validation: ['coverPhoto'], content: CoverPhotoStep },

  {
    title: 'Informations',
    pageTitle: 'Update Group Details',
    validation: ['name', 'description', 'address'],
    content: InformationStep,
  },
  {
    title: 'Categories',
    pageTitle: 'Modify related categories',
    validation: ['categories'],
    content: AddCategoriesStep,
  },
];

type MatchParams = { id: string };
type UpdateGroupProps = RouteComponentProps<MatchParams>;

function UpdateGroup(props: UpdateGroupProps) {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [form] = Form.useForm();
  const [updateGroup, { loading }] = useUpdateGroupAtOnceMutation({
    refetchQueries: [namedOperations.Query.GetMyAccount],
  });
  const history = useHistory();

  const { data: groupResult, loading: groupLoading } = useGetAllGroupsQuery({
    variables: { filters: { id: { eq: id } } },
  });

  const selectedGroupResult = useMemo(() => {
    return groupResult?.groups.data && groupResult?.groups.data.length ? groupResult?.groups.data[0] : null;
  }, [groupResult]);

  const onFinish = async ({ coverPhoto, ...values }: FromFields) => {
    const attributes: GroupAttributes = { ...values };
    if (coverPhoto?.blob) {
      attributes.coverPhoto = coverPhoto?.blob;
    }

    try {
      const result = await updateGroup({
        variables: { input: attributes, groupId: id },
      });
      const groupSlug = result.data?.updateGroupAtOnce?.data?.attributes?.slug;
      if (groupSlug) {
        history.push(`/groups/${groupSlug}`);
      }
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
      }
    }
  };

  useEffect(() => {
    if (selectedGroupResult) {
      const { name, description, address, coverPhotoUrl, categories, groupType } = selectedGroupResult.attributes;
      const { __typename, ...restAddress } = address.data.attributes || {};

      form.setFieldsValue({
        name,
        description,
        address: restAddress,
        groupType,
        categories: categories.data.map((cat) => String(cat.id)),
        coverPhoto: { blob: null, url: coverPhotoUrl },
      });
    }
  }, [form, selectedGroupResult]);

  if (groupLoading) {
    return <Spinner size="xl" block />;
  }
  return selectedGroupResult ? (
    <StepBarForm
      pageTitle={`Update Group ${selectedGroupResult?.attributes.name}`}
      form={form}
      onFinish={onFinish}
      loading={loading}
      steps={steps}
    />
  ) : (
    <NotFound>Group Not Found!</NotFound>
  );
}

export default UpdateGroup;
