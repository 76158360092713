import React from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { Button } from 'components/elements';
import { ButtonComponentProps } from 'components/elements/button';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { DarkThemeWrapper } from 'settings/theme';
import { maxWinSize } from 'settings/style';
import { Popup } from 'antd-mobile';

interface ModalWrapperProps extends ModalProps {
  onLeft?: (() => void)[];
  onRight?: () => void;
  onLeftText?: React.ReactNode[];
  onRightText?: React.ReactNode;
  children?: React.ReactNode;
  onRightProps?: Omit<ButtonComponentProps, 'onClick'>;
}

const ModalWrapper = ({
  onLeft,
  onLeftText,
  onRight,
  onRightText,
  onRightProps,
  children,
  ...props
}: ModalWrapperProps) => {
  return maxWinSize('md') ? (
    <Popup position="bottom" visible={props.open} showCloseButton onClose={props.onCancel as any}>
      <div style={{ height: '100vh', overflowY: 'scroll', padding: 20 }}>
        {children}
        <ModalFooterWrapper>
          <div className="left">
            {onLeft &&
              onLeft.map((fn, idx) => (
                <Button type="link" onClick={fn} style={{ paddingLeft: 0, display: 'block' }} key={v4()}>
                  {onLeftText[idx]}
                </Button>
              ))}
          </div>
          <div className="right">
            {onRight && (
              <Button type="primary" onClick={onRight} {...onRightProps}>
                {onRightText}
              </Button>
            )}
          </div>
        </ModalFooterWrapper>
      </div>
    </Popup>
  ) : (
    <DarkThemeWrapper>
      <Modal
        getContainer={() => window.document.getElementById('root') || window.document.body}
        {...props}
        footer={null}
      >
        {children}
        <ModalFooterWrapper>
          <div className="left">
            {onLeft &&
              onLeft.map((fn, idx) => (
                <Button type="link" onClick={fn} style={{ paddingLeft: 0, display: 'block' }} key={v4()}>
                  {onLeftText[idx]}
                </Button>
              ))}
          </div>
          <div className="right">
            {onRight && (
              <Button type="primary" onClick={onRight} {...onRightProps}>
                {onRightText}
              </Button>
            )}
          </div>
        </ModalFooterWrapper>
      </Modal>
    </DarkThemeWrapper>
  );
};
export default ModalWrapper;

const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
