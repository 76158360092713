import React from 'react';
import Image from 'assets/images/coming-soon.png';

const ComingSoon = () => {
  return (
    <div>
      <img alt="#" src={Image} />
    </div>
  );
};
export default ComingSoon;