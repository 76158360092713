import React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from 'helpers/client';
// library CSS
import './assets/fonts/style.css';
import 'swiper/swiper.css';
import 'react-quill/dist/quill.snow.css';
import Theme from 'settings/theme';

import { datadogRum } from '@datadog/browser-rum';
import GlobalStyle from './global.style';

import RouterWrapper from './RouterWrapper';

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_RUM_SITE,
    service: process.env.REACT_APP_DATADOG_RUM_SERVICE,
    env: process.env.REACT_APP_ENVIRONMENT,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

const App = () => {
  return (
    <Theme>
      <ApolloProvider client={client}>
        <RouterWrapper />
        <GlobalStyle />
      </ApolloProvider>
    </Theme>
  );
};
export default App;
