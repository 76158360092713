import React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';

const EditorInput = ({ value, defaultValue, ...rest }: any) => {
  const inputValue = value || defaultValue || '';
  return (
    <Wrapper>
      <ReactQuill theme="snow" style={{ height: 400, paddingBottom: 50 }} {...rest} value={inputValue} />
    </Wrapper>
  );
};

export default EditorInput;

const Wrapper = styled.div``;
