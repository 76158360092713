import React, { useState, useRef, useMemo } from 'react';
import { StyledWrapper } from './index.styled';

interface DonateFormProps {
  title?: string;
  paypalEmail: string;
  onFormSubmitted?: () => void;
}

const transactionFee = 3.5;

const subscriptionTypeTag = {
  monthly: { p3: '1', t3: 'M' },
  quarterly: { p3: '3', t3: 'M' },
  annually: { p3: '1', t3: 'Y' },
  once: null as any,
} as const;

const DonateForm = ({ title, paypalEmail, onFormSubmitted }: DonateFormProps) => {
  const [price, setPrice] = useState('50');
  const [coverFeeChecked, setCoverFeeChecked] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState<'once' | 'monthly' | 'annually' | 'quarterly'>('once');
  const inputRef = useRef(null);
  const formRef = useRef(null);

  const coverFee = useMemo(() => {
    return (parseInt(price, 10) * transactionFee) / 100;
  }, [price]);

  const setPriceAmount = (e: any) => {
    setPrice(e.target.value);
  };

  return (
    <StyledWrapper>
      <div className="donate-form">
        <div className="donateFormHeader">
          <h2 className="donateheadline">
            {title ||
              'How much would you like to donate? As a contributor we make sure your donation goes directly to supporting our cause.'}
          </h2>
        </div>
        <form
          target="_blank"
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          id="donationForm"
          ref={formRef}
        >
          <input
            type="hidden"
            name="cmd"
            value={subscriptionType === 'once' ? '_donations' : '_xclick-subscriptions'}
          />

          <input type="hidden" name="currency_code" value="USD" />

          <input type="hidden" name="business" value={paypalEmail} />
          <input type="hidden" name="charset" value="utf-8" />
          <input type="hidden" name="lc" value="US" />
          <input type="hidden" name="no_note" value="1" />

          <section className="amount-section">
            <p className="title">Choose Amount:</p>
            <div className="input-amount">
              <span className="dollarSign">$</span>
              <input
                type="number"
                id="inputAmount"
                name={subscriptionType === 'once' ? 'amount' : 'a3'}
                value={price}
                onChange={setPriceAmount}
                ref={inputRef}
              />
            </div>

            <div className="amount-buttons">
              <button
                className={`amountPrice${price === '10' ? ' selected' : ''}`}
                type="button"
                onClick={() => setPrice('10')}
              >
                <span className="currency-before">$</span>
                <span className="priceAmount">10</span>
              </button>
              <button
                className={`amountPrice${price === '30' ? ' selected' : ''}`}
                type="button"
                onClick={() => setPrice('30')}
              >
                <span className="currency-before">$</span>
                <span className="priceAmount">30</span>
              </button>
              <button
                className={`amountPrice${price === '50' ? ' selected' : ''}`}
                type="button"
                onClick={() => setPrice('50')}
              >
                <span className="currency-before">$</span>
                <span className="priceAmount">50</span>
              </button>
              <button
                className={`amountPrice${price === '100' ? ' selected' : ''}`}
                type="button"
                onClick={() => setPrice('100')}
              >
                <span className="currency-before">$</span>
                <span className="priceAmount">100</span>
              </button>
              <button
                className={`amountPrice${price === '250' ? ' selected' : ''}`}
                type="button"
                onClick={() => setPrice('250')}
              >
                <span className="currency-before">$</span>
                <span className="priceAmount">250</span>
              </button>
              <button
                className={`amountPrice${!['10', '50', '30', '250', '100'].includes(price) ? ' selected' : ''}`}
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current.focus();
                    setPrice('');
                  }
                }}
                id="customAmount"
                type="button"
              >
                <span className="priceAmount">
                  Custom
                  <br />
                  Amount
                </span>
              </button>
            </div>
          </section>

          <section className="subscription-section">
            <p className="title">Choose Period:</p>
            <div className="subscription-buttons">
              <button
                className={`subscriptionBtn${subscriptionType === 'once' ? ' selected' : ''}`}
                onClick={() => setSubscriptionType('once')}
                id="onceSub"
                type="button"
              >
                Once
              </button>
              <button
                className={`subscriptionBtn${subscriptionType === 'monthly' ? ' selected' : ''}`}
                type="button"
                onClick={() => setSubscriptionType('monthly')}
              >
                Monthly
              </button>
              <button
                className={`subscriptionBtn${subscriptionType === 'quarterly' ? ' selected' : ''}`}
                type="button"
                onClick={() => setSubscriptionType('quarterly')}
              >
                Quarterly
              </button>
              <button
                className={`subscriptionBtn${subscriptionType === 'annually' ? ' selected' : ''}`}
                type="button"
                onClick={() => setSubscriptionType('annually')}
              >
                Annually
              </button>
            </div>
          </section>

          <section className="transaction-fee-section">
            <input
              type="checkbox"
              id="transactionFee"
              name="transactionFee"
              value="transactionFee"
              checked={coverFeeChecked}
              onChange={() => setCoverFeeChecked(!coverFeeChecked)}
            />
            <label htmlFor="transactionFee">
              I Like to help to cover the transaction fee of
              <span id="transactionFeeAmount"> ${coverFee}</span>
            </label>
          </section>

          <div className="donate_buttons">
            <input
              type="submit"
              value={`Donate ${subscriptionType}`}
              alt="Donate Monthly"
              className="btn_styles"
              id="donateBtn"
              onClick={(e) => {
                e.preventDefault();
                if (coverFeeChecked) {
                  inputRef.current.value = String(parseInt(price, 10) + coverFee);
                }
                if (formRef.current) {
                  formRef.current.submit();
                  if (onFormSubmitted) {
                    onFormSubmitted();
                  }
                }
              }}
            />
            <img alt="" width="1" height="1" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" />
          </div>
          {!!subscriptionTypeTag[subscriptionType] && (
            <>
              <input type="hidden" className="subsciptionTag" name="src" value="1" />
              <input type="hidden" name="no_shipping" value="1" />
              <input
                type="hidden"
                className="subsciptionTag"
                name="p3"
                value={subscriptionTypeTag[subscriptionType].p3}
              />
              <input
                type="hidden"
                className="subsciptionTag"
                name="t3"
                value={subscriptionTypeTag[subscriptionType].t3}
              />
            </>
          )}
        </form>
      </div>
    </StyledWrapper>
  );
};
export default DonateForm;
