import { gql } from '@apollo/client';

export default gql`
  type Rendered {
    rendered: String!
  }
  type BlogContent {
    id: ID!
    slug: String!
    status: String!
    title: Rendered!
    content: Rendered!
  }
  type UserTokenInfo {
    id: ID!
  }
  extend type Query {
    loggedInState: Boolean!
    loginModalVisible: Boolean!
    forgotPasswordModalVisible: Boolean!
    signupModalVisible: Boolean!
    userTokenInfo: UserTokenInfo!
    blogContent: [BlogContent]!
  }
`;
