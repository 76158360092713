import { spacer, fontWeight, fontSize, colors, radius, media } from 'settings/style';
import styled from 'styled-components';

export const StyledMapWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 186px;
  border: 1px solid ${colors.primary300};
  border-radius: ${radius.lg};
  overflow: hidden;
`;

export const StyledContent = styled.div`
  flex: 3;
  padding-right: ${spacer.lg};
  ${media.lg`
    flex: 0 0 100%;
    padding-right: 0;
  `}
`;

export const StyledTitle = styled.span`
  display: inline-flex;
  color: ${colors.font};
  font-size: ${fontSize.lg};
  font-weight: ${fontWeight.normal};
`;

export const StyledText = styled.p`
  font-weight: ${fontWeight.light};
  font-size: ${fontSize.md};
`;

export const StyledDetail = styled.div`
  flex: 2;
  ${media.lg`
    flex: 0 0 100%;
    margin-top:${spacer.xl};
  `}
`;

export const StyledVideoWrap = styled.div`
  margin-top: ${spacer.lg};
`;
