import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'components/elements';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import moment from 'moment-jalaali';
import {
  StyledWrapper,
  StyledArrow,
  StyledSlider,
  StyledImg,
  ImagePreviewInfo,
  StyledVideoCont,
  Description,
} from './albumSlider.styled';

SwiperCore.use([Navigation, Thumbs]);
export interface PhotoType {
  id: string;
  url?: string;
  ownerId: string;
  ownerName: string;
  caption?: string;
  videoUrl?: string;
  mediaDate?: string;
  isApproved?: boolean;
}

interface PhotosProp {
  photos: PhotoType[];
}

interface AlbumSliderProp extends PhotosProp {
  showSlider: boolean;
  toggleSlider: () => void;
  defaultIndex?: number;
}

const AlbumSlider = ({ showSlider, toggleSlider, photos, defaultIndex = 0 }: AlbumSliderProp) => {
  const [swiper, setSwiper] = useState(null);
  const slideTo = useCallback(
    (index: number) => {
      if (swiper && swiper.slideTo) {
        swiper.slideTo(index);
      }
    },
    [swiper],
  );

  useEffect(() => {
    if (slideTo) {
      slideTo(defaultIndex);
    }
  }, [defaultIndex, slideTo]);

  return (
    <>
      <Modal centered width="90%" open={showSlider} onCancel={toggleSlider}>
        <StyledWrapper>
          <Swiper
            id="AlbumSlider"
            navigation={{
              nextEl: '.nextElm',
              prevEl: '.prevElm',
            }}
            onSwiper={setSwiper}
          >
            {photos.map(({ url, ownerName, caption, mediaDate, videoUrl }) => {
              return (
                <SwiperSlide key={url} style={{ display: 'grid' }}>
                  <StyledSlider>
                    {videoUrl ? (
                      <StyledVideoCont>
                        <iframe
                          width="100%"
                          height="100%"
                          src={videoUrl}
                          title="video"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </StyledVideoCont>
                    ) : (
                      <StyledImg src={url} />
                    )}
                    <Description>
                      <ImagePreviewInfo />
                      {!!mediaDate && <ImagePreviewInfo>{moment(mediaDate).format('LL')}</ImagePreviewInfo>}
                      <ImagePreviewInfo className="text">
                        @{ownerName}
                        {caption ? `: ${caption}` : ''}
                      </ImagePreviewInfo>
                      <hr
                        style={{
                          height: '1px',
                          borderWidth: '0',
                          color: 'gray',
                          backgroundColor: 'gray',
                          marginTop: '15px',
                        }}
                      />
                    </Description>
                  </StyledSlider>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <StyledArrow className="nextElm">
            <i className="icon-arrow-right" />
          </StyledArrow>
          <StyledArrow className="prevElm">
            <i className="icon-arrow-left" />
          </StyledArrow>
        </StyledWrapper>
      </Modal>
    </>
  );
};

export default AlbumSlider;
