import React, { useState } from 'react';
import moment from 'moment-jalaali';
import styled from 'styled-components';
import { Button } from 'components/elements';
import { colors, rgba } from 'settings/style';
import { zYear, zDateDetails, zMonth } from '@polgozar/utils';
import { Link } from 'react-router-dom';

const ZCalendar = () => {
  const [dateIdx, setDate] = useState(0);
  const date = moment().add(dateIdx, 'day');
  const dateData = zDateDetails(date, 'week');
  const selectedZYear = zYear(date);
  const selectedZMonth = zMonth(date);

  return (
    <CalendarCont>
      <div className="dateController">
        <Button onClick={() => setDate(dateIdx - 1)} leftIcon="icon-chevron-left" type="link" />
        <Button onClick={() => setDate(0)} type="primary" ghost>
          Today
        </Button>
        <Button onClick={() => setDate(dateIdx + 1)} leftIcon="icon-chevron-right" type="link" />
      </div>

      <Link className="contentCont" to="/calendar">
        <p>{date.format('dddd, MMMM D YYYY')}</p>
        <p>
          {dateData.day} {selectedZMonth.name.en} {selectedZYear}
        </p>
        <p>
          <p>{dateData.name.en}</p>
          {dateData.isNabor && (
            <span className="naborDay">
              (Nabor{' '}
              <span role="img" aria-label="nabor image">
                🌱
              </span>
              )
            </span>
          )}
        </p>
        {!!dateData.events && <p>{dateData.events.map((e) => e.en)}</p>}
      </Link>
    </CalendarCont>
  );
};
export default ZCalendar;

const CalendarCont = styled.div`
  text-align: center;
  .dateController {
    margin-bottom: 1em;
  }
  .contentCont {
    border: 2px solid ${colors.accent};
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;
    color: ${colors.gray1500};
    p {
      line-height: 2rem;
    }
    transition: all 0.5s;
    &:hover {
      background-color: ${rgba(colors.accent, 0.08)};
    }
  }
  .naborDay {
    color: ${colors.success};
  }
`;
