import React, { useEffect } from 'react';
import { Form, message } from 'antd';
import {
  useUpdateEventAtOnceMutation,
  useGetEventByIdQuery,
  EventAttributes,
  EventTypeEnum,
  namedOperations,
} from 'graphql/types';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { ResizeImageResult, ParseGoogleAddressResult } from 'helpers';
import StepBarForm from 'components/stepBarForm';
import { Spinner, NotFound } from 'components/elements';
import moment from 'moment';
import CoverPhotoStep from './coverPhotoStep';
import InformationStep from './informationStep';
import EventDetailsStep from './eventDetails';
import AddCategoriesStep from '../category/addCategoriesStep';

interface FromFields {
  title: string;
  dates: moment.Moment[];
  description: string;
  group: string;
  coverPhoto: ResizeImageResult;
  address: ParseGoogleAddressResult;
  eventType: EventTypeEnum;
  eventUrl: string;
  price: number;
  capacity?: number;
  categories: string[];
  repeatInterval: number;
}

const steps = [
  {
    title: 'Informations',
    pageTitle: 'Update Event Details',
    validation: ['title', 'description', 'group', 'dates'],
    content: InformationStep,
  },
  {
    title: 'Categories',
    pageTitle: 'Modify related categories',
    validation: ['categories'],
    content: AddCategoriesStep,
  },
  { title: 'Cover Photo', pageTitle: 'Change Cover Photo', validation: ['coverPhoto'], content: CoverPhotoStep },
  {
    title: 'Extra Details',
    pageTitle: 'Add Extra Details',
    validation: ['price', 'eventType', 'address', 'eventUrl'],
    content: EventDetailsStep,
  },
];

type MatchParams = { id: string };
type UpdateEventProps = RouteComponentProps<MatchParams>;

function UpdateEvent(props: UpdateEventProps) {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [form] = Form.useForm();
  const [updateEvent, { loading }] = useUpdateEventAtOnceMutation({
    refetchQueries: [namedOperations.Query.GetMyAccount],
  });
  const history = useHistory();

  const { data: eventResult, loading: eventLoading } = useGetEventByIdQuery({
    variables: { id },
  });

  const onFinish = async (values: FromFields) => {
    const { dates, coverPhoto, ...restValues } = values;
    const attributes: EventAttributes = {
      ...restValues,
      startTime: dates[0],
      endTime: dates[1],
    };

    if (coverPhoto?.blob) {
      attributes.coverPhoto = coverPhoto?.blob;
    }

    try {
      const result = await updateEvent({
        variables: { input: attributes, eventId: id },
      });
      const eventId = result.data?.updateEventAtOnce?.data.id;
      if (eventId) {
        history.push(`/events/${eventId}`);
      }
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
      }
    }
  };

  useEffect(() => {
    if (eventResult?.event?.data) {
      const {
        title,
        description,
        address,
        coverPhotoUrl,
        startTime,
        endTime,
        group,
        eventUrl,
        eventType,
        price,
        capacity,
        repeatInterval,
        categories,
      } = eventResult.event.data.attributes;
      const { __typename, ...restAddress } = address || {};

      form.setFieldsValue({
        title,
        description,
        eventUrl,
        eventType,
        price,
        address: restAddress,
        coverPhoto: { blob: null, url: coverPhotoUrl },
        group: group?.data?.id,
        dates: [moment(startTime), moment(endTime)],
        capacity,
        repeatInterval,
        categories: categories.data.map((cat) => String(cat.id)),
      });
    }
  }, [form, eventResult]);

  if (eventLoading) {
    return <Spinner size="xl" block />;
  }

  return eventResult?.event ? (
    <StepBarForm
      pageTitle={`Update Event ${eventResult?.event?.data?.attributes?.title}`}
      form={form}
      onFinish={onFinish}
      loading={loading}
      steps={steps}
    />
  ) : (
    <NotFound>Event Not Found!</NotFound>
  );
}

export default UpdateEvent;
