import React, { useState } from 'react';
import { Button } from 'components/elements';
import { Input } from 'antd';
import { SearchBarWrapper, SearchBarInputWrapper } from './index.styled';

interface SearchBarProp {
  defaultValue?: string;
  onSubmit?: (value: string) => void;
}

const SearchBar = ({ defaultValue, onSubmit }: SearchBarProp) => {
  const [inputValue, setInputValue] = useState(defaultValue);

  return (
    <SearchBarWrapper>
      <SearchBarInputWrapper>
        <i className="icon-search" />
        <Input onChange={(e) => setInputValue(e.target.value)} value={inputValue} placeholder="Search by name" />
      </SearchBarInputWrapper>
      <Button
        onClick={() => {
          if (onSubmit) {
            onSubmit(inputValue);
          }
        }}
        type="primary"
        primaryInfo
        block
      >
        Search
      </Button>
    </SearchBarWrapper>
  );
};

export default SearchBar;
