import React from 'react';
import { Form, message } from 'antd';
import { useCreateEventAtOnceMutation, EventTypeEnum, namedOperations } from 'graphql/types';
import { useHistory } from 'react-router-dom';
import { ResizeImageResult, ParseGoogleAddressResult } from 'helpers';
import StepBarForm from 'components/stepBarForm';
import moment from 'moment';
import CoverPhotoStep from './coverPhotoStep';
import InformationStep from './informationStep';
import EventDetailsStep from './eventDetails';
import AddCategoriesStep from '../category/addCategoriesStep';

interface FromFields {
  title: string;
  dates: moment.Moment[];
  description: string;
  group: string;
  coverPhoto: ResizeImageResult;
  address: ParseGoogleAddressResult;
  eventType: EventTypeEnum;
  eventUrl: string;
  price: number;
  capacity: number;
  categories: string[];
  repeatInterval: number;
}

const steps = [
  {
    title: 'Informations',
    pageTitle: 'Add Event Details',
    validation: ['title', 'description', 'group', 'dates'],
    content: InformationStep,
  },
  {
    title: 'Categories',
    pageTitle: 'Select related categories',
    validation: ['categories'],
    content: AddCategoriesStep,
  },
  { title: 'Cover Photo', pageTitle: 'Add Cover Photo', validation: ['coverPhoto'], content: CoverPhotoStep },
  {
    title: 'Extra Details',
    pageTitle: 'Add Extra Details',
    validation: ['price', 'eventType', 'address', 'eventUrl'],
    content: EventDetailsStep,
  },
];

function CreateEvent() {
  const [form] = Form.useForm<FromFields>();
  const [createEvent, { loading }] = useCreateEventAtOnceMutation({
    refetchQueries: [namedOperations.Query.GetMyAccount],
  });
  const history = useHistory();

  const onFinish = async (values: FromFields) => {
    const { dates, ...restValues } = values;
    const attributes = {
      ...restValues,
      coverPhoto: values.coverPhoto.blob,
      startTime: dates[0],
      endTime: dates[1],
    };

    try {
      const result = await createEvent({
        variables: { input: attributes },
      });
      const eventId = result.data?.createEventAtOnce?.data?.id;
      if (eventId) {
        history.push(`/events/${eventId}`);
      }
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
      }
    }
  };

  return (
    <StepBarForm<FromFields>
      pageTitle="Make new Event"
      form={form}
      onFinish={onFinish}
      loading={loading}
      steps={steps}
    />
  );
}

export default CreateEvent;
