import styled, { css } from 'styled-components';
import { colors, fontWeight, media, fontType, spacer } from 'settings/style';
import { LabelColors } from '@polgozar/utils';

export const StyledWrapper = styled.div``;

export const CalendarWrapper = styled.div`
  display: block;
  position: relative;
  margin: 1em;
  border: 2px solid ${colors.danger};
  border-radius: 10px;
  overflow: hidden;
  ${media.md`
    border: none;
    margin: 0;
  `}
`;

export const EventTitle = styled.p`
  font-weight: ${fontWeight.bold};
  padding-bottom: ${spacer.md};
`;

export const EventWrapper = styled.p`
  margin: ${spacer.md} 0;
`;

export const WeekDayWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  justify-content: center;
  text-align: center;
`;
export const WeekHeaderWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  color: ${colors.gray1500};
  font-size: 0.8rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${colors.gray300};
  background-color: ${colors.danger};
  color: white;
`;

interface DayWrapperProps {
  disabled: boolean;
  selected: boolean;
  labelColor?: LabelColors;
  bgImage?: string;
  isToday: boolean;
}

export const DayWrapper = styled.div<DayWrapperProps>`
  flex: 1;
  max-width: 100%;
  position: relative;
  border-right: 1px solid ${colors.gray300};
  /* overflow: hidden; */
  cursor: pointer;
  transition: 0.25s ease-out;
  border-left: 7px solid transparent;
  ${media.md`
    border-left: 2px solid transparent;
  `}
  ${({ labelColor }) =>
    labelColor &&
    css`
      background-color: ${labelColor};
    `}
  ${({ bgImage }) =>
    bgImage &&
    css`
      &:before {
        content: '';
        background-image: url(${bgImage});
        background-repeat: no-repeat;
        background-position: center 96%;
        background-size: 50%;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.8;
      }
    `}
  &:hover {
    transition: 0.5s ease-out;
  }
  &:last-child {
    border-right: none;
  }
  ${({ selected, disabled }) =>
    selected &&
    !disabled &&
    css`
      border-image: ${`linear-gradient(to top, transparent 0%, ${colors.accent} 100%)`};
      border-image-slice: 1;
    `}

  ${({ isToday, disabled }) =>
    isToday &&
    css`
      background-color: #91c8de26;
      border: 2px dashed ${disabled ? colors.gray1200 : '#1b0598'};
      border-radius: 5px;
      ${media.md`
        border: 0.5px dashed ${disabled ? colors.gray1200 : '#1b0598'};
      `}
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.gray1200};
      pointer-events: none;
      background-color: #f7f7f7;
    `}
`;

export const EventSpaceBar = styled.div`
  padding-bottom: 22px;
`;

export const InnerContent = styled.div`
  height: 10rem;
  ${media.md`
    height: 6rem;
  `}/* width: 100%;
  padding-top: 100%; 
  position: relative; */
`;

interface Disabled {
  disabled: boolean;
}

export const GeorgianDay = styled.span<Disabled>`
  position: absolute;
  font-size: 1.2rem;
  top: 0.5rem;
  left: 0.5rem;
  font-weight: 600;
  line-height: 1;
  font-size: 0.96rem;
  color: ${colors.red200};
  font-size: 1.5rem;
  font-family: serif;
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.gray1200};
      border-color: ${colors.gray1200};
    `}
  ${media.md`
    font-size: 1rem;
  `}
`;

export const PersianDay = styled.p<Disabled>`
  position: absolute;
  /* font-size: 1.2rem; */
  /* top: 0.5rem; */
  /* left: 0.5rem; */
  font-weight: 600;
  line-height: 1;
  font-size: 0.96rem;
  top: 0.5rem;
  right: 0.5rem;
  left: auto;
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.gray1200};
      border-color: ${colors.gray1200};
    `}
`;

export const ZDetails = styled.div`
  position: absolute;
  top: 2.7rem;
  text-align: center;
  width: 100%;
`;

export const EventDay = styled.div`
  position: absolute;
  border: 1px solid #ffb300;
  background-color: #ffb3008f;
  text-align: right;
  bottom: 0.5rem;
  right: 0.5rem;
  padding: 0rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
  ${media.md`
      font-size: 0.5rem;
    `}
`;

export const ZDayName = styled.div<Disabled>`
  font-size: 0.95rem;
  font-weight: 600;
  color: ${colors.font};
  line-height: 1rem;
  ${media.md`
    font-size: 0.5rem;
  `}
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.gray1200};
      border-color: ${colors.gray1200};
    `}
`;

export const ZEvent = styled.div<Disabled>`
  font-size: 0.8rem;
  padding-top: 0.4rem;
  ${media.md`
    font-size: 0.5rem;
    font-weight: ${fontWeight.bold};
    padding-top: 0.3rem;
  `}
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.gray1200};
      border-color: ${colors.gray1200};
    `}
`;

export const NaborDay = styled.div<Disabled>`
  position: absolute;
  border: 1px solid #56b100;
  color: #56b100;
  text-align: right;
  bottom: 0.5rem;
  left: 0.5rem;
  background-color: #00800017;
  padding: 0rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
  ${media.md`
    font-size: 0.5rem;
  `}
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.gray1200};
      border-color: ${colors.gray1200};
    `}
`;

export const PartyDay = styled.div`
  position: absolute;
  border: 1px solid #56b100;
  color: #56b100;
  text-align: right;
  bottom: 0.5rem;
  left: 0.5rem;
  background-color: #00800017;
  padding: 0rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
  ${media.md`
    font-size: 0.5rem;
  `}
  border: 1px solid #ff0080;
  color: #ff0080;
  background-color: #ff008021;
`;

interface EventBarProps {
  span: number;
  disabled: boolean;
  openEnded?: boolean;
  openStarted?: boolean;
  type?: 'gohanbar' | 'ziyarat';
}

const EventBarTypes = {
  gohanbar: colors.success,
  ziyarat: colors.accent,
} as const;

export const EventBar = styled.div<EventBarProps>`
  position: absolute;
  background: ${({ disabled, type }) => (disabled ? colors.gray1500 : EventBarTypes[type] || colors.accent)};
  left: 0;
  z-index: 9;
  text-align: left;
  padding-left: 10px;
  width: calc(((100% + 7px) * ${({ span }) => span}) - ${({ openStarted }) => (openStarted ? '0px' : '12px')});
  margin-left: 7px;
  border-radius: 5px;
  color: white;
  ${({ openStarted }) =>
    openStarted &&
    css`
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      margin-left: -7px;
    `}
  ${({ openEnded }) =>
    openEnded &&
    css`
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    `}
`;
export const WeekRowWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid ${colors.gray300};
  &:last-child {
    border-bottom: none;
  }
`;

export const HeaderNavWrapper = styled.div``;
export const HeaderNavTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 2rem;
  ${fontType.lg};
`;

export const GeorgianDate = styled.p`
  text-align: left;
  color: ${colors.danger};
  font-family: serif;
  font-size: 1.4rem;
  ${media.md`
    font-size: 1rem;
    font-weight: ${fontWeight.bold};
`}
`;

export const PersianDate = styled.p`
  /* font-weight: 400; */
  text-align: right;
`;

export const HeaderNavAction = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 2rem 1.5rem 2rem;
  justify-content: space-between;
  ${media.md`
    padding: 0px;
    padding-bottom: 0.5rem;
  `}
`;

export const CalendarDetailsWrapper = styled.div`
  padding: 2rem;
  margin-left: 0.5rem;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${media.lg`
    margin-top: 0.5rem;
    margin-left: 2rem;
  `}
`;

export const DetailsHeader = styled.div`
  ${fontType.lg}
`;

export const DetailsContainer = styled.div`
  ul {
    padding-top: 1rem;
    li {
      &:before {
        content: '\\e910';
        font-family: 'polgozar';
        color: ${colors.danger};
        font-weight: bold;
        padding-right: 1rem;
      }
      padding-bottom: 1rem;
      white-space: pre-wrap;
    }
  }
`;

export const AddEventWrapper = styled.div`
  text-align: center;
  margin-top: 3rem;
`;

export const LegendWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const LegendRow = styled.div`
  margin-bottom: 7px;
  display: flex;
  align-items: center;
`;

interface LegendProps {
  type: 'nabor' | 'party' | 'important' | 'semiImportant' | 'today' | 'ziyarat' | 'selected' | 'gohanbar';
}

const typeStyles = {
  nabor: css`
    background-color: #00800017;
    color: #56b100;
    border: 1px solid #56b100;
  `,
  party: css`
    border: 1px solid #ff0080;
    color: #ff0080;
    background-color: #ff008021;
  `,
  important: css`
    background-color: ${LabelColors.important};
  `,
  ziyarat: css`
    background-color: ${colors.accent};
  `,
  gohanbar: css`
    background-color: ${colors.success};
  `,
  semiImportant: css`
    background-color: ${LabelColors.semiImportant};
  `,
  today: css`
    background-color: #91c8de26;
    border: 1px dashed #1b0598;
  `,
  selected: css`
    background-image: ${`linear-gradient(to top, transparent 10%, ${colors.accent} 110%)`};
  `,
} as const;

export const LegendIcon = styled.div<LegendProps>`
  width: 25px;
  height: 25px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 0.8rem;
  ${({ type }) => typeStyles[type]}
`;
