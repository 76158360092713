import React from 'react';
import { Modal, FormItem, ImageInput, Avatar, PhoneInput } from 'components/elements';
import { Row, Col, Input, Form } from 'antd';
import { loginModalVisible, signupModalVisible, forgotPasswordModalVisible } from 'cache';
import { useSignupModalVisibilityQuery, useUpdateProfileMutation } from 'graphql/types';
import useRegister from 'hooks/useRegister';
import { useHistory } from 'react-router-dom';
import { ResizeImageResult } from 'helpers';

interface SignupProps {
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  prefix: string;
  phone: string;
  password: string;
  avatar: ResizeImageResult;
}

function AvatarInput({
  onChange,
  value,
  loading,
}: {
  loading?: boolean;
  value?: ResizeImageResult;
  onChange?: (data: ResizeImageResult) => void;
}) {
  return (
    <ImageInput width={200} height={200} cropping onChange={onChange}>
      <Avatar avatarUrl={value?.url} noOutline center size="md" editable loading={loading} />
    </ImageInput>
  );
}

function Signup() {
  const useSignupModalVisibilityResult = useSignupModalVisibilityQuery();
  const [loginLoading, onRegisters] = useRegister();
  const history = useHistory();
  const [updateUserProfile, { loading: updateProfileLoading }] = useUpdateProfileMutation();

  const [signupForm] = Form.useForm();
  const setSignupVisibility = (visible: boolean) => {
    signupModalVisible(visible);
  };

  const onSigupSuccess = () => {
    setSignupVisibility(false);
    history.push('/account');
  };

  const onFinish = (values: SignupProps) => {
    const { firstname, lastname, email, prefix, phone, password, avatar, username } = values;
    onRegisters({ password, email, username }, async () => {
      const input = { firstname, lastname } as any;
      if (avatar?.blob) {
        input.avatar = avatar?.blob;
      }
      if (phone && prefix) {
        input.phoneNumber = prefix + phone;
      }
      await updateUserProfile({
        variables: { input },
      });
      onSigupSuccess();
    });
  };

  const toLogin = () => {
    setSignupVisibility(false);
    loginModalVisible(true);
  };

  const forgotPassword = () => {
    setSignupVisibility(false);
    forgotPasswordModalVisible(true);
  };

  return (
    <Modal
      title="Sign up"
      open={useSignupModalVisibilityResult.data?.signupModalVisible}
      onRight={() => signupForm.submit()}
      onRightProps={{ loading: loginLoading || updateProfileLoading }}
      onRightText="Confirm"
      onLeft={[toLogin, forgotPassword]}
      onLeftText={['I already have an account', 'Forgot my password']}
      onCancel={() => setSignupVisibility(false)}
      destroyOnClose
    >
      <Form layout="vertical" form={signupForm} onFinish={onFinish} preserve={false}>
        <FormItem name="avatar" label="" theme="dark" noStyle>
          <AvatarInput loading={updateProfileLoading} />
        </FormItem>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={12}>
            <FormItem
              theme="dark"
              name="firstname"
              label="First Name"
              rules={[{ required: true, message: 'Please input your phone First Name!' }]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              theme="dark"
              name="lastname"
              label="Last Name"
              rules={[{ required: true, message: 'Please input your phone Last Name!' }]}
            >
              <Input />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={12}>
            <FormItem
              theme="dark"
              name="username"
              label="User Name"
              rules={[{ required: true, message: 'Please input your Display Name!' }]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem theme="dark" name="phone" label="Phone Number">
              <PhoneInput />
            </FormItem>
          </Col>
        </Row>
        <FormItem
          theme="dark"
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          theme="dark"
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </FormItem>
        <FormItem
          theme="dark"
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }: { getFieldValue: any }) => ({
              validator(rule: any, value: string) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </FormItem>
      </Form>
    </Modal>
  );
}

export default Signup;
