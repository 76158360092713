import React, { useMemo } from 'react';
import { FormItem } from 'components/elements';
import DetailedCheckbox from 'components/detailedCheckbox';
import { useCategoriesQuery } from 'graphql/types';

function AddCategoriesStep() {
  const { data } = useCategoriesQuery();

  const categories = useMemo(
    () =>
      data?.categories
        ? data?.categories.data.map(({ id, attributes: { description, title } }) => ({
            value: id,
            label: title,
            description,
          }))
        : [],
    [data],
  );

  return (
    <FormItem name="categories" label="" noStyle rules={[{ required: true, message: 'Categories is required!' }]}>
      <DetailedCheckbox options={categories} />
    </FormItem>
  );
}

export default AddCategoriesStep;
