import React from 'react';
import { Avatar } from 'components/elements';

import { StyledWrapper, StyledAvatar, StlyedText } from './listItem.styled';

interface ListItemProp {
  bgColor?: 'primary' | 'accent100';
  avatarUrl?: string;
  title?: string;
  id: string;
}
const ListItem = ({ bgColor = 'primary', title = 'User name', avatarUrl = '', id = '' }: ListItemProp) => {
  return (
    <StyledWrapper bgColor={bgColor} to={`/profile/${id}`}>
      <StyledAvatar>
        <Avatar size="sm" avatarUrl={avatarUrl} />
      </StyledAvatar>
      <StlyedText>{title}</StlyedText>
    </StyledWrapper>
  );
};

export default ListItem;
