import { spacer, colors, radius, fontSize, fontWeight, media } from 'settings/style';
import styled, { css } from 'styled-components';

const boxSize = '35vh';

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledItem = styled.div`
  cursor: pointer;
  position: relative;
`;

export const StyledItemWrapper = styled.div<{ isApproved?: boolean }>`
  ${({ isApproved }) =>
    !isApproved &&
    css`
      position: relative;
      &:before {
        content: '';
        display: block;
        background: #e4e2e2ad;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    `}
`;

export const StyledImg = styled.img`
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
  border-radius: ${radius.md};
  margin-bottom: ${radius.lg};
`;

export const VideoPlaceHolder = styled.div`
  width: 100%;
  height: 100%;
  background: ${`linear-gradient(200deg, ${colors.red100} 20%,${colors.primary200} 50%)`};
  padding: 8rem;
  .anticon {
    font-size: 80px;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    &:hover {
      color: ${colors.primary300};
    }
  }
`;

export const ItemRemoveButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  width: 35px;
  height: 35px;
  background-color: ${colors.red300};
  color: ${colors.white};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  z-index: 9;
  /* border-top-right-radius: 0px; */
  margin: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.red400};
  }
`;

export const ApproveButton = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #06c049;
  color: #fff;
  border-radius: 5px;
  padding: 5px 12px;
  cursor: pointer;
  z-index: 10;
  margin: 10px;
  &:hover {
    background-color: #059f3c;
  }
`;

export const PendingApproval = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: ${colors.gray1400};
  color: #fff;
  border-radius: 5px;
  padding: 5px 12px;
  cursor: pointer;
  z-index: 10;
  margin: 10px;
`;

export const StyledUploadContainer = styled.div`
  height: ${boxSize};
  width: ${boxSize};
  display: inline-flex;
  margin: 0.5rem;
`;

export const StyledUploadWrap = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: ${colors.white200};
  flex: 1;
  height: 100%;
  border: 1px dashed ${colors.font};
  position: relative;
  border-radius: ${radius.md};
  overflow: hidden;
  cursor: pointer;
  i {
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${media.xs`
     font-size:${fontSize.sm};
  `}
  }
  input {
    position: absolute;
    width: 1px;
    opacity: 0;
    visibility: hidden;
    background-color: transparent;
  }
`;

export const MediaUploadButton = styled.button`
  font-size: 15px;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  width: 13rem;
  height: 30px;
  background-color: ${'#ffb300'};
  color: ${colors.white};
  font-weight: 600;
  border-radius: ${radius.xl};
  &:hover {
    color: ${'#ffb300'};
    background-color: ${colors.white};
  }
  ${media.xs`
    font-size: 14px;
  `}
`;

export const StyledHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 100%;
  padding-right: ${spacer.xl};
  margin-right: ${spacer.xl};
  margin-left: ${spacer.lg};
  padding-top: ${spacer.xl};
  margin-top: ${spacer.lg};
`;

export const StyledTitle = styled.span`
  display: inline-flex;
  color: ${colors.font};
  font-size: ${fontSize.lg};
  font-weight: ${fontWeight.normal};
  margin-right: ${spacer.md};
`;

export const StyledAlbumWrap = styled.div`
  column-count: 4;
  column-gap: ${spacer.sm};
  background-color: ${colors.white};
  padding: ${spacer.lg};
  padding-bottom: ${spacer.md};
  margin: ${spacer.md};
  border-radius: ${radius.lg};
  ${media.md`
    column-count: 1;
  `}
`;
