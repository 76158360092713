import React from 'react';
import styled, { css } from 'styled-components';
import { HeroImage, Container, SliderList } from 'components/elements';
import BgImage from 'assets/images/intro_bg.jpg';
import { colors, fontType, snippet, spacer, rgba, media } from 'settings/style';
import GroupCard from 'components/groupCard';
import EventCard from 'components/eventCard';
import { useGetAllGroupsQuery, useGetAllEventsQuery, useLoggedInStateQuery } from 'graphql/types';
// import { signupModalVisible } from 'cache';
import moment from 'moment';
import CategoriesList from './categories/categoriesList';

// filters: { startTime: { gt: moment().format() } }

const Home = () => {
  const { data: groupsResult, loading: groupLoading } = useGetAllGroupsQuery({
    variables: { pagination: { start: 0, limit: 30 }, sort: 'createdAt:desc' },
  });
  const { data: upcomingEvents, loading: upcomingEventsLoading } = useGetAllEventsQuery({
    variables: {
      pagination: { start: 0, limit: 30 },
      sort: 'startTime:asc',
      filters: { startTime: { gt: moment().format() } },
    },
  });
  const { data: newEvents, loading: newEventsLoading } = useGetAllEventsQuery({
    variables: { pagination: { start: 0, limit: 30 }, sort: 'createdAt:desc' },
  });
  const useLoggedInStateResult = useLoggedInStateQuery();
  const isLoggedIn = useLoggedInStateResult.data?.loggedInState;
  return (
    <HomeWrapper isLoggedIn={isLoggedIn}>
      <div style={{ display: 'relative' }}>
        <HeroImage img={BgImage} overlay>
          <div className="hero-content">
            <h1 className="head-first">The way in the world is just one,</h1>
            <h1 className="head-second">
              and that way is <span>TRUTH</span>.
            </h1>
            {/* {!isLoggedIn && (
              <Button className="join-us-btn" onClick={() => signupModalVisible(true)}>
                JOIN US
              </Button>
            )} */}
          </div>
        </HeroImage>
        <CategoriesList />
      </div>
      <ContentContainer>
        <Container>
          <div className="row-cont">
            <SliderList
              renderList={(props) => <EventCard {...props.attributes} />}
              title="Newly added Events"
              items={newEvents?.events?.data || []}
              loading={newEventsLoading}
            />
          </div>
          <div className="row-cont">
            <SliderList
              renderList={(props) => <EventCard {...props.attributes} />}
              title="Upcoming Events"
              items={upcomingEvents?.events?.data || []}
              loading={upcomingEventsLoading}
            />
          </div>
          <div className="row-cont">
            <SliderList
              renderList={(props) => <GroupCard {...props} />}
              title="Newly added Groups"
              items={groupsResult?.groups?.data || []}
              perView={3}
              loading={groupLoading}
            />
          </div>
        </Container>
      </ContentContainer>
    </HomeWrapper>
  );
};
export default Home;

const ContentContainer = styled.div`
  background-color: ${colors.gray200};
  padding: ${spacer.lg} 0;
  h3 {
    ${fontType.lg}
    ${snippet.underline()}
  }
  .row-cont {
    margin: ${spacer.xl} 0;
  }
`;

const HomeWrapper = styled.div<{ isLoggedIn: boolean }>`
  .hero-content {
    font-size: 15px;
    ${media.md`
      font-size: 14px;
      padding: 0 ${spacer.lg};
    `}
    ${media.xs`
      font-size: 11px;
    `}
    text-align: center;
    margin-bottom: 22em;
    .head-first {
      color: ${colors.white};
      font-size: 1.5em;
      font-weight: 600;
      margin-bottom: 0;
    }
    .head-second {
      color: ${colors.white};
      font-size: 1.7em;
      font-weight: 700;
      span {
        color: ${colors.accent};
      }
    }
    .join-us-btn {
      width: 54%;
      padding: 1em 0em;
      margin-top: 1.5em;
      color: ${colors.white};
      backdrop-filter: blur(10px);
      background-color: ${rgba(colors.accent, 0.3)};
      justify-content: center;
      &:hover {
        border-color: ${colors.accent};
        background-color: ${rgba(colors.accent, 1)};
      }
    }
    position: relative;
    /* ${({ isLoggedIn }) =>
      !isLoggedIn &&
      css`
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          bottom: 1.7em;
          height: 1.5px;
          left: 0;
          background: linear-gradient(
            to right,
            ${rgba(colors.white, 0)},
            ${rgba(colors.accent, 1)},
            ${rgba(colors.white, 0)}
          );
        }
      `} */
  }
`;
