import React from 'react';
import styled from 'styled-components';
import { radius, colors, spacer, shadow, fontSize, fontType } from 'settings/style';
import { Button } from 'components/elements';
import { Link, useHistory } from 'react-router-dom';

interface GroupCardProps {
  editable?: boolean;
  isPending?: boolean;
  id?: string;
  attributes?: {
    slug?: string;
    coverPhotoUrl?: string;
    name?: string;
    categories?: {
      data: {
        attributes?: {
          title?: string;
        };
      }[];
    };
    members?: {
      data?: { id?: string }[];
    };
  };
}

export default function GroupCard({
  id,
  editable,
  isPending,
  attributes: { slug, coverPhotoUrl, name, categories, members },
}: GroupCardProps) {
  const history = useHistory();

  return (
    <GroupCardWrapper img={coverPhotoUrl || ''} to={isPending ? '#' : `/groups/${slug}`}>
      <div className="img-cont" />
      <div className="content-cont">
        <h4 className="group-name">{name}</h4>
        <div className="categories-wrapper">
          {categories.data.map((cat) => (
            <span key={cat.attributes.title}>{cat.attributes.title}</span>
          ))}
        </div>

        <div className="footer">
          <span className="group-members">
            <i className="icon-user" />
            {members.data.length}
          </span>
          {editable && (
            <>
              <Button
                type="primary"
                ghost
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  history.push(`/account/groups/update/${id}`);
                }}
              >
                Edit
              </Button>
              {isPending ? (
                <span>pending for approval</span>
              ) : (
                <Button
                  type="primary"
                  ghost
                  onClick={(e?: React.MouseEvent) => {
                    if (e) {
                      e.preventDefault();
                    }
                    history.push(`/account/groups/${id}`);
                  }}
                >
                  Manage Group
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </GroupCardWrapper>
  );
}

const GroupCardWrapper = styled(Link)<{ img: string }>`
  background-color: ${colors.white};
  height: 200px;
  display: flex;
  box-shadow: ${shadow.md};
  border-radius: ${radius.lg};
  overflow: hidden;
  color: ${colors.font};
  &:hover {
    color: ${colors.font};
  }
  .img-cont {
    flex: 4;
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content-cont {
    flex: 5;
    padding: ${spacer.md};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .categories-wrapper {
      span {
        display: inline-block;
        ${fontType.sm};
        background-color: ${colors.gray100};
        padding: ${spacer.xs};
        border-radius: ${radius.sm};
        margin-right: ${spacer.sm};
        margin-bottom: ${spacer.sm};
      }
    }
    .group-name {
      ${fontType.md}
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .group-members {
        font-size: ${fontSize.lg};
        color: ${colors.primary};
        i {
          padding-right: ${spacer.md};
        }
      }
    }
  }
`;
