import { spacer, colors, fontType, radius } from 'settings/style';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  border: 3px solid ${colors.primary};
  border-radius: ${radius.lg};
  padding: ${spacer.md};
  margin: ${spacer.md} 0;
`;

export const FormInputWrapper = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const StyledImgTitle = styled.p`
  ${fontType.lg};
  text-align: center;
  margin-bottom: ${spacer.md};
  color: ${colors.primary400};
`;
