import React from 'react';
import { Spinner } from 'components/elements';
import { StyledImgWrapper, StyledWrapper, StyledText, AvatarProps } from './avatar.styled';

const Avatar = ({ avatarUrl, size, text, noOutline, outlineColor, center, editable, loading }: AvatarProps) => {
  return (
    <StyledWrapper center={center}>
      <StyledImgWrapper size={size} noOutline={noOutline} outlineColor={outlineColor} editable={editable}>
        {avatarUrl ? <img src={avatarUrl} alt="" /> : <i className="icon-user" />}
        {loading && <Spinner size={size} />}
      </StyledImgWrapper>

      {text ? <StyledText>{text}</StyledText> : null}
    </StyledWrapper>
  );
};

export default Avatar;
