import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import { StyledWrapper, StyledRow, StyledImgTitle } from './index.styled';
import { message } from 'antd';
import { Button } from 'components/elements';
import { useRemoveReminderMutation, useGetMyAccountQuery } from 'graphql/types';
import { mapSubject, mapDaysBefore, serverTimeFormat } from '../addReminder';
import { DeleteOutlined } from '@ant-design/icons';

function RemoveReminderList() {
  const { data: accountResult } = useGetMyAccountQuery();
  const [removeReminder] = useRemoveReminderMutation();

  const reminders = useMemo(() => {
    return accountResult?.myAccount?.data?.attributes?.reminders?.data || [];
  }, [accountResult]);

  const deleteReminder = useCallback(
    (reminderId: string) => {
      try {
        removeReminder({ variables: { reminderId } });
        message.success('Your Reminder deleted Successfully!');
      } catch (err) {
        if (err instanceof Error) {
          message.error(err?.message);
        }
      }
    },
    [removeReminder],
  );

  if (!reminders.length) {
    return null;
  }

  return (
    <StyledWrapper>
      <StyledImgTitle>Your Reminder List</StyledImgTitle>
      {reminders.map((reminder) => (
        <StyledRow>
          Email Reminder {mapDaysBefore[reminder.attributes.dayBefore]} {mapSubject[reminder.attributes.subject]} at{' '}
          {moment(reminder.attributes.time, serverTimeFormat).format('LT')}
          <Button onClick={() => deleteReminder(reminder.id)} danger>
            <DeleteOutlined />
          </Button>
        </StyledRow>
      ))}
    </StyledWrapper>
  );
}
export default RemoveReminderList;
