import React from 'react';
import { Popconfirm } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { useLoggedInStateQuery } from 'graphql/types';

interface AuthenticatableProps extends PopconfirmProps {
  children: JSX.Element;
  title: string;
}

function Authenticatable({ children, title, onConfirm, onCancel, ...props }: AuthenticatableProps) {
  const useLoggedInStateResult = useLoggedInStateQuery();
  const isLoggedIn = useLoggedInStateResult.data?.loggedInState;

  if (!isLoggedIn) {
    return (
      <Popconfirm
        title={title}
        placement="top"
        onConfirm={(e) => {
          e.stopPropagation();
          if (onConfirm) {
            onConfirm();
          }
        }}
        okText="Confirm"
        cancelText="Cancel"
        onCancel={(e) => {
          e.stopPropagation();
          if (onCancel) {
            onCancel();
          }
        }}
        {...props}
      >
        <div>{children}</div>
      </Popconfirm>
    );
  }
  return children;
}

export default Authenticatable;
