import React, { useMemo } from 'react';
import { FormItem, SwitchAndInput, EditorInput, DatePicker } from 'components/elements';
import { Input, Select, Row, Col, InputNumber, Form } from 'antd';
import { useGetMyAccountQuery } from 'graphql/types';
import { eventDateTimeFormat, eventHourFormat } from 'settings/configs';

const { RangePicker } = DatePicker;

function InformationStep() {
  const { data: myAccount } = useGetMyAccountQuery();

  const options = useMemo(() => {
    const groups = myAccount?.myAccount?.data.attributes.ledGroups;
    return !groups || !groups.data.length
      ? []
      : groups.data.map((group) => ({ value: group.id, label: group.attributes.name }));
  }, [myAccount]);

  return (
    <>
      <FormItem
        name="title"
        label="Title"
        theme="primary"
        rules={[
          {
            required: true,
            message: 'Please input your Title!',
          },
        ]}
      >
        <Input placeholder="Enter Your Event Title" />
      </FormItem>
      <Row gutter={12}>
        <Col xs={24} md={10}>
          <FormItem
            name="group"
            label="Select Your Group"
            theme="primary"
            rules={[
              {
                required: true,
                message: 'Please Select Your Group!',
              },
            ]}
          >
            <Select options={options} />
          </FormItem>
        </Col>
        <Col xs={24} md={14}>
          <FormItem
            name="dates"
            label="Select Your Start and Ent Date Time"
            theme="primary"
            rules={[
              {
                required: true,
                message: 'Please Select Your Dates!',
              },
            ]}
          >
            <RangePicker showTime={{ minuteStep: 15, format: eventHourFormat }} format={eventDateTimeFormat} />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.repeatInterval !== currentValues.repeatInterval}
          >
            {({ getFieldValue, setFieldsValue }) => {
              const repeatInterval = getFieldValue('repeatInterval');
              return (
                <SwitchAndInput
                  fieldValue={repeatInterval}
                  setFieldsValue={setFieldsValue}
                  fieldName="repeatInterval"
                  switchOffDefaultValue={0}
                  switchOnDefaultValue={1}
                  checkedName="One-Time Event"
                  unCheckedName="Recurring"
                >
                  <FormItem name="repeatInterval" theme="primary" label="Recurring Option">
                    <InputNumber addonBefore="Repeat every" addonAfter="Day" />
                  </FormItem>
                </SwitchAndInput>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <FormItem
        name="description"
        label="Description"
        theme="primary"
        fullRounded={false}
        rules={[
          {
            required: true,
            message: 'Please input your Description!',
          },
        ]}
      >
        <EditorInput />
      </FormItem>
    </>
  );
}

export default InformationStep;
