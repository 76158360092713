import { spacer, fontSize, fontWeight, colors, radius, media } from 'settings/style';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface ListItemProp {
  bgColor?: 'primary' | 'accent100';
}
export const StyledWrapper = styled(Link)<ListItemProp>`
  padding: ${spacer.lg};
  position: relative;
  background-color: ${({ bgColor }) => (bgColor === 'primary' ? colors.primary : colors.accent100)};
  border-radius: ${radius.xl};
  color: ${colors.font};
  text-align: left;
  width: 90%;
`;

export const SiderWrapper = styled.div`
  flex: 0 0 275px;
  background-color: ${colors.primary200};
  padding: 1.5rem 1rem;
  padding-bottom: 3rem;
`;

export const StlyedText = styled.span`
  display: inline-flex;
  margin-left: 85px;
  font-size: ${fontSize.md};
  font-weight: ${fontWeight.light};
  ${media.lg`
    margin-left: 60px;
    `}
`;

export const FilterAreaWrapper = styled.div`
  background-color: ${colors.white};
  padding: 1rem 1.5rem;
  padding-bottom: 3rem;
  border-radius: 5px;
  .anticon {
    margin-right: 10px;
  }
`;

export const FilterHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const FilterByCheckboxWrapper = styled.div``;

export const FilterTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-bottom: 1.2rem;
  .line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${colors.font};
  }
  .title {
    position: absolute;
    background-color: ${colors.white};
    display: inline-block;
    margin-left: 15px;
    padding: 0 0.5rem;
  }
`;
