import React from 'react';
import {
  StyledWrapper,
  StyledContentTopWrap,
  StyledContentTopContainer,
  StyledContentTopSubText,
  StyledContentTopText,
} from './singleGroupBottom.styled';
import JoinGroupButton from '../joinGroupButton';

interface SingleGroupBottomProp {
  slug?: string;
  name?: string;
  groupId: string;
}
const SingleGroupBottom = ({ slug, name, groupId }: SingleGroupBottomProp) => {
  return (
    <StyledWrapper>
      <StyledContentTopWrap>
        <StyledContentTopContainer>
          <StyledContentTopText>{slug}</StyledContentTopText>
        </StyledContentTopContainer>
        <StyledContentTopSubText>{name}</StyledContentTopSubText>
      </StyledContentTopWrap>
      <JoinGroupButton groupId={groupId} />
    </StyledWrapper>
  );
};

export default SingleGroupBottom;
