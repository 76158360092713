import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'antd';
import 'antd/dist/reset.css';
import { ConfigProvider } from 'antd';

const StyledTheme = ({ children }: { children: JSX.Element }) => {
  const { token } = theme.useToken();

  return <ThemeProvider theme={token}>{children}</ThemeProvider>;
};

const baseThemeSeed = {
  colorInfo: '#91c8de',
  colorError: '#f95877',
  colorPrimary: '#f1a800',
  fontSize: 16,
  borderRadius: 16,
  borderRadiusXS: 4,
  borderRadiusSM: 8,
  colorSuccess: '#06c049',
  colorWarning: '#fa861b',
  colorBgBase: '#ffffff',
  colorTextBase: '#333333',
};

const baseDarkThemeSeed = {
  colorBgBase: '#333',
  colorTextBase: '#fff',
};

function Theme({ children }: { children: JSX.Element }) {
  return (
    <ConfigProvider
      theme={{
        token: baseThemeSeed,
      }}
    >
      <StyledTheme>{children}</StyledTheme>
    </ConfigProvider>
  );
}

export function DarkThemeWrapper({ children }: { children: JSX.Element }) {
  const { darkAlgorithm } = theme;

  const baseDarkTheme = (seed: any) => darkAlgorithm({ ...seed, ...baseDarkThemeSeed });
  return (
    <ConfigProvider
      theme={{
        algorithm: baseDarkTheme,
      }}
    >
      {children}
    </ConfigProvider>
  );
}

export default Theme;
