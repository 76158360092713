import { useCallback } from 'react';
import { useLoginMutation, namedOperations } from 'graphql/types';
import { AUTH_TOKEN } from 'constant';
import LocalStore from 'helpers/localStore';
import { LoginReq } from 'services/auth';
import { message } from 'antd';

function useLogin() {
  const [userLogin, { loading }] = useLoginMutation({
    refetchQueries: [namedOperations.Query.GetMyAccount],
  });
  const login = useCallback(
    async (data: LoginReq, onSuccess: () => void = () => {}) => {
      try {
        const result = await userLogin({
          variables: { input: data },
        });
        const token = result?.data?.login?.jwt;
        if (!token) {
          throw new Error('Authenication Failed!');
        }
        LocalStore.set(AUTH_TOKEN, token);
        onSuccess();
      } catch (err) {
        LocalStore.remove(AUTH_TOKEN);
        if (err instanceof Error) {
          message.error(err.message);
        }
      }
    },
    [userLogin],
  );
  return [loading, login] as const;
}

export default useLogin;
