import { colors, radius, media, fontSize, spacer } from 'settings/style';
import styled from 'styled-components';

const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  width: 100%;
  background-color: ${colors.white200};
  border: 1px dashed ${colors.font};
  position: relative;
  border-radius: ${radius.md};
  overflow: hidden;
  cursor: pointer;
  min-height: 200px;
  transition: all 0.3s;
  &:hover {
    opacity: 0.8;
  }
  i {
    font-size: 50px;
    ${media.xs`
     font-size:${fontSize.sm};
    `}
  }
  button {
    position: absolute;
    bottom: ${spacer.lg};
    width: 80%;
  }
  button + i {
    padding-bottom: ${spacer.xl};
  }
`;

export default StyledButton;
