import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { UsersPermissionsUserEntityResponse } from 'graphql/types';
import { StyledWrapper, StyledImgTitle, StyledContent, StyledPostDate, StyledReadMore } from './index.styled';

interface PostItemProps {
  title?: string;
  slug?: string;
  content?: string;
  author?: UsersPermissionsUserEntityResponse;
  updatedAt?: string;
}

const PostItem = ({ title, slug, content, author, updatedAt }: PostItemProps) => {
  return (
    <StyledWrapper to={`/posts/${slug}`}>
      <StyledImgTitle>{title}</StyledImgTitle>
      <StyledPostDate>
        {author?.data?.attributes?.username ? `Author: ${author?.data?.attributes?.username}  |  ` : ''}Last Update:{' '}
        {moment(updatedAt).format('ll')}
      </StyledPostDate>
      <StyledContent>
        {ReactHtmlParser(content.slice(0, 300))} <StyledReadMore>Read more</StyledReadMore>
      </StyledContent>
    </StyledWrapper>
  );
};
export default PostItem;
