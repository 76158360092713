import { colors, fontWeight, spacer, fontSize } from 'settings/style';
import styled from 'styled-components';

export const xxx = styled.div`
  max-width: 500px;
  margin: auto;
  overflow: hidden;
`;

export const StyledWrapper = styled.div`
  max-width: 500px;
  margin: auto;

  padding: ${spacer.sm};
  font-size: ${fontSize.base};

  h2.donateheadline {
    text-align: center;
    font-weight: 500;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .input-amount {
    position: relative;
    padding: 5px;
  }
  #inputAmount {
    padding: 15px;
    padding-left: 32px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 4px solid ${colors.accent};
    font-size: ${fontSize.lg};
  }
  .input-amount .dollarSign {
    position: absolute;
    left: 17px;
    top: 1rem;
    font-size: ${fontSize.xxl};
  }
  button.selected {
    color: ${colors.accent} !important;
    background: ${colors.white} !important;
    transition: all 0.2s ease, color 0.2s ease;
  }

  button {
    align-items: center;
    background: ${colors.accent};
    border: 2px solid ${colors.accent};
    color: ${colors.gray900};
    justify-content: center;
    line-height: 1;
    padding-bottom: 24px;
    padding-top: 24px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
    font-weight: ${fontWeight.bold};
    line-height: 25px;
  }
  button:hover {
    opacity: 0.9;
  }

  input#donateBtn {
    cursor: pointer;
    background: ${colors.accent};
    border: 2px solid ${colors.accent};
    color: ${colors.gray900};
    width: 100%;
    text-align: center;
    padding-bottom: 24px;
    padding-top: 24px;
    border-radius: 5px;
    margin-top: 40px;
    font-weight: 800;
  }
  input#donateBtn:hover {
    opacity: 0.9;
  }

  .amount-buttons,
  .subscription-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
  }

  .amount-buttons button {
    flex: 0 1 calc(33.333333% - 10px); /* <-- adjusting for margin */
  }

  .subscription-buttons button {
    flex: 0 1 calc(25% - 10px); /* <-- adjusting for margin */
  }

  p.title {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 8px;
    padding-left: 5px;
  }
  .transaction-fee-section {
  }

  .transaction-fee-section {
    margin-top: 20px;
  }

  input[type='checkbox'] + label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label:before {
    content: '\\2714';
    border: 1px solid #000;
    border-radius: 3px;
    display: inline-block;
    width: 22px;
    height: 22px;
    padding-left: 3px;
    padding-bottom: 2px;
    margin-right: 7px;
    vertical-align: middle;
    color: transparent;
    transition: 0.2s;
  }

  input[type='checkbox'] + label:active:before {
    transform: scale(0);
  }

  input[type='checkbox']:checked + label:before {
    background-color: ${colors.accent};
    border-color: ${colors.accent};
    color: ${colors.gray900};
  }

  input[type='checkbox']:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
  }

  input[type='checkbox']:checked:disabled + label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
  }
`;
