import React, { useCallback } from 'react';
import { Form, Input, FormInstance } from 'antd';
import { Button, FormItem, EditorInput } from 'components/elements';

import { FormWrapper, FormControllerWrapper } from './index.styled';

interface SendEmailFormProp {
  onSubmit: (values: FromFields) => void;
  submitLabel?: string;
  loading?: boolean;
  form: FormInstance;
}

export interface FromFields {
  subject: string;
  message: string;
}

const SendEmailForm = ({ onSubmit, submitLabel = 'Send Email', loading = false, form }: SendEmailFormProp) => {
  const submit = useCallback(() => {
    form.submit();
  }, [form]);

  const onFinish = useCallback(
    async (values: FromFields) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <FormWrapper>
      <Form
        layout="vertical"
        preserve={false}
        form={form}
        onFinish={onFinish}
        initialValues={{ subject: '', message: '' }}
      >
        <FormItem
          name="subject"
          label="Subject"
          theme="primary"
          rules={[{ required: true, message: 'Subject cannot be empty!' }]}
        >
          <Input placeholder="Email Subject ..." />
        </FormItem>

        <FormItem
          name="message"
          label="Message"
          noStyle
          rules={[{ required: true, message: 'Message cannot be empty!' }]}
        >
          <EditorInput />
        </FormItem>
      </Form>
      <FormControllerWrapper>
        <Button onClick={submit} loading={loading}>
          {submitLabel}
        </Button>
      </FormControllerWrapper>
    </FormWrapper>
  );
};

export default SendEmailForm;
