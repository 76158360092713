import { spacer, colors, rgba, radius } from 'settings/style';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  width: 100%;
  display: block;
  .quill {
    .ql-toolbar {
      border-radius: ${radius.lg} ${radius.lg} 0 0;
      background-color: ${rgba(colors.primary, 0.3)};
    }
    .ql-container {
      border-radius: 0 0 ${radius.lg} ${radius.lg};
      min-height: 150px;
      .ql-editor {
        min-height: 150px;
      }
    }
  }
`;

export const FormControllerWrapper = styled.div`
  margin-top: ${spacer.xl};
`;
