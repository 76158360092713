import React from 'react';
import { Location, Button } from 'components/elements';
import { isEventType } from 'graphql/customTypes';
import { EventTypeEnum } from 'graphql/types';
import ReactHtmlParser from 'react-html-parser';
import {
  StyledContent,
  StyledDetail,
  StyledText,
  StyledMapWrapper,
  StyledVideoWrap,
  StyledTitle,
} from './singleEventContent.styled';

interface SingleEventContentProp {
  address: { lng: number; lat: number; address: string; city?: string };
  coverPhotoUrl: string;
  description: string;
  eventUrl?: string;
  eventType: string;
}
const SingleEventContent = ({ address, description, eventUrl, eventType }: SingleEventContentProp) => {
  return (
    <>
      <StyledContent>
        <StyledTitle>Details</StyledTitle>
        <StyledText>{ReactHtmlParser(description)}</StyledText>
      </StyledContent>
      <StyledDetail>
        {isEventType(EventTypeEnum.Physical, eventType) && address && (
          <StyledMapWrapper>
            <Location loactionDetail={address} />
          </StyledMapWrapper>
        )}
        {isEventType(EventTypeEnum.Virtual, eventType) && eventUrl && (
          <StyledVideoWrap>
            <Button
              onClick={() => window.open(eventUrl, '_blank')}
              type="primary"
              ghost
              leftIcon="icon-hyperlink"
              rightIcon="icon-chevron-right"
              block
              size="large"
            >
              Online Meeting Link
            </Button>
          </StyledVideoWrap>
        )}
      </StyledDetail>
    </>
  );
};

export default SingleEventContent;
