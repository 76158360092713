import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  useGetEventByIdQuery,
  useUploadEventMediaMutation,
  useGetAllEventsQuery,
  useApproveEventPhotoMutation,
  useRemoveEventPhotoMutation,
  ComponentMediaGallery,
} from 'graphql/types';
import { SliderList, Container } from 'components/elements';
import SingleEventList from 'components/userList';
import SingleEventHead from 'containers/singleEvent/singleEventHead';
import SingleEventContent from 'containers/singleEvent/singleEventContent';
import { ResizeImageResult } from 'helpers';
import { message } from 'antd';
import useEventAccessInfo from 'hooks/useEventAccessInfo';
import EventCard from 'components/eventCard';
import { FileInfoInput } from 'components/elements/imageInput';
import { StyledWrapper, StyledHead, StyledContent, StyledList, StyledAlbum, StyledEvents } from './index.styled';
import SingleEventAlbum from './singleEventAlbum';

type MatchParams = { id: string };
type SingleEventProps = RouteComponentProps<MatchParams>;

// const fakeUsers = [...Array(5)].fill({ firstname: 'Test', lastname: 'User', id: Math.random().toString() });

export default function SingleEvent(props: SingleEventProps) {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { data: eventByIdResult } = useGetEventByIdQuery({
    variables: { id },
  });
  const [removeEventPhoto, { loading: removePhotoLoading }] = useRemoveEventPhotoMutation();
  const [approveEventPhoto] = useApproveEventPhotoMutation();
  const [uploadEventPhoto, { loading: uploadPhotoLoading }] = useUploadEventMediaMutation();

  const { hasActiveBooking, isEventOrganizer, userId } = useEventAccessInfo(id);
  const isEditableEvent = hasActiveBooking || isEventOrganizer;

  const eventsResult = useGetAllEventsQuery({ variables: { pagination: { start: 0, limit: 10 } } });

  const uploadPhoto = useCallback(
    (data: ResizeImageResult, extraInfo: FileInfoInput) => {
      if (isEditableEvent) {
        try {
          uploadEventPhoto({
            variables: { input: { image: data?.blob, ...extraInfo }, eventId: id },
          });
        } catch (err) {
          if (err instanceof Error) {
            message.error(err.message);
          }
        }
      }
    },
    [id, isEditableEvent, uploadEventPhoto],
  );

  const removePhoto = useCallback(
    (mediaId: string) => {
      if (isEditableEvent) {
        try {
          removeEventPhoto({ variables: { mediaId, eventId: id } });
        } catch (err) {
          if (err instanceof Error) {
            message.error(err.message);
          }
        }
      }
    },
    [id, isEditableEvent, removeEventPhoto],
  );

  const approvePhoto = useCallback(
    (mediaId: string) => {
      if (isEditableEvent) {
        try {
          approveEventPhoto({ variables: { mediaId, eventId: id } });
        } catch (err) {
          if (err instanceof Error) {
            message.error(err.message);
          }
        }
      }
    },
    [id, isEditableEvent, approveEventPhoto],
  );

  const attendants = useMemo(() => {
    const bookings = eventByIdResult?.event?.data?.attributes?.bookings?.data || [];
    return bookings.map((booking) => booking?.attributes?.user?.data?.attributes);
  }, [eventByIdResult]);

  return (
    <StyledWrapper>
      <StyledHead>
        <Container>
          {eventByIdResult?.event?.data?.id && (
            <SingleEventHead
              address={eventByIdResult?.event.data?.attributes?.address?.data?.attributes}
              coverPhotoUrl={eventByIdResult?.event.data?.attributes?.coverPhotoUrl}
              title={eventByIdResult?.event.data?.attributes?.title}
              price={eventByIdResult?.event.data?.attributes?.price}
              startTime={eventByIdResult?.event.data?.attributes?.startTime}
              endTime={eventByIdResult?.event.data?.attributes?.endTime}
              id={eventByIdResult?.event.data?.id}
              remainingSeats={eventByIdResult?.event.data?.attributes?.remainingSeats}
              isPast={eventByIdResult?.event.data?.attributes?.isPast}
              repeatInterval={eventByIdResult?.event.data?.attributes?.repeatInterval}
            />
          )}
        </Container>
      </StyledHead>
      <Container>
        <StyledContent>
          <SingleEventContent
            address={eventByIdResult?.event.data?.attributes?.address?.data?.attributes}
            coverPhotoUrl={eventByIdResult?.event.data?.attributes?.coverPhotoUrl}
            description={eventByIdResult?.event.data?.attributes?.description}
            eventUrl={eventByIdResult?.event.data?.attributes?.eventUrl}
            eventType={eventByIdResult?.event.data?.attributes?.eventType}
          />
        </StyledContent>

        {isEditableEvent && (
          <StyledAlbum>
            <SingleEventAlbum
              gallery={(eventByIdResult?.event.data?.attributes?.gallery || []) as ComponentMediaGallery[]}
              removePhoto={isEditableEvent && removePhoto}
              onImport={isEditableEvent && uploadPhoto}
              approvePhoto={isEditableEvent && approvePhoto}
              uploadLoading={uploadPhotoLoading}
              removeLoading={removePhotoLoading}
              isEventOrganizer={isEventOrganizer}
              userId={userId}
            />
          </StyledAlbum>
        )}

        <StyledList>
          <SingleEventList size={attendants.length} bgColor="primary" title="Attended list" items={attendants} />
        </StyledList>
        {/* <StyledList>
          <SingleEventList size={3} bgColor="accent100" title="Donated list" items={fakeUsers} />
        </StyledList>
        <StyledComments>
          <Comments />
        </StyledComments> */}
        <StyledEvents>
          <SliderList
            renderList={(item) => <EventCard {...item.attributes} />}
            title="Related Events"
            items={eventsResult?.data?.events?.data || []}
          />
        </StyledEvents>
      </Container>
    </StyledWrapper>
  );
}
