import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { youtubeIdParser, googleIdParser } from 'helpers';
import { Input } from 'antd';
import { Modal, DatePicker } from 'components/elements';
import { Moment } from 'moment';
import { colors, spacer } from 'settings/style';

const { TextArea } = Input;

export interface VideoInputInfo {
  caption?: string;
  date?: string;
  videoUrl?: string;
}

export type ImageInputAction = (info?: VideoInputInfo) => void;

interface ImportInputProps {
  onChange?: ImageInputAction;
}

function VideoImportFromUrl({ onChange = () => {} }: ImportInputProps) {
  const [modalVisible, setModal] = useState(false);
  const [url, setUrl] = useState('');
  const [caption, setCaption] = useState('');
  const [date, setDate] = useState<undefined | Moment>();

  const openVideoImportModal = useCallback(() => {
    setModal(true);
    setUrl('');
  }, []);

  const previewUrl = useMemo(() => {
    if (url.includes('youtube')) {
      const youtubeVideoID = youtubeIdParser(url);
      return `https://www.youtube.com/embed/${youtubeVideoID}`;
    }
    if (url.includes('google')) {
      const googleVideoID = googleIdParser(url);
      return `https://drive.google.com/file/d/${googleVideoID}/preview`;
    }
    return '';
  }, [url]);

  const onSubmit = useCallback(() => {
    onChange({ videoUrl: previewUrl, caption, date: date ? date.format() : null });
    setModal(false);
  }, [caption, date, onChange, previewUrl]);

  const renderModal = (
    <Modal
      title="Import Video From URL"
      open={modalVisible}
      onRightText="Confirm"
      onCancel={() => setModal(false)}
      onRight={onSubmit}
    >
      <VideoURLWrapper>
        <label htmlFor="videoUrl">
          You can copy your video link from your google drive or youtube and paste it here:
        </label>
        <Input
          id="videoUrl"
          placeholder="Please copy paste your Video URL here"
          onChange={(e) => setUrl(e.target.value)}
          value={url}
        />
      </VideoURLWrapper>
      <CaptionWrapper>
        <TextArea
          rows={2}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCaption(e.target.value)}
          placeholder="Enter Caption For This Image ..."
          value={caption}
        />
      </CaptionWrapper>
      <DateWrapper>
        Date: <DatePicker onChange={(data) => setDate(data)} value={date} />
      </DateWrapper>
      <VideoWrapper>
        <iframe
          width="500"
          height="200"
          src={previewUrl}
          title="video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoWrapper>
    </Modal>
  );
  return [renderModal, openVideoImportModal] as const;
}

export default VideoImportFromUrl;

const DateWrapper = styled.div`
  padding-bottom: ${spacer.md};
  color: ${colors.white};
`;

const VideoWrapper = styled.div`
  padding-bottom: ${spacer.md};
`;

const CaptionWrapper = styled.div`
  padding-bottom: ${spacer.md};
`;

const VideoURLWrapper = styled.div`
  padding-bottom: ${spacer.md};
  color: ${colors.white};
  label {
    padding-bottom: ${spacer.md};
  }
`;
