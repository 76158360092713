import { colors, spacer, fontSize, media, fontWeight, rgba } from 'settings/style';
import styled, { css } from 'styled-components';

export interface AvatarProps {
  avatarUrl?: string;
  size: 'md' | 'lg' | 'sm';
  text?: string;
  noOutline?: boolean;
  outlineColor?: string;
  center?: boolean;
  editable?: boolean;
  loading?: boolean;
}

const mapSize = {
  sm: { size: '70px', border: '3px', icon: '25px' },
  md: { size: '90px', border: '4px', icon: '30px' },
  lg: { size: '180px', border: '10px', icon: '55px' },
};

export const StyledImgWrapper = styled.div<AvatarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: ${({ size }) => mapSize[size].size};
  width: ${({ size }) => mapSize[size].size};
  background: ${colors.primary300};
  background: ${`linear-gradient(137deg, ${colors.primary300} 29%, ${colors.accent} 100%)`};
  overflow: hidden;
  border: ${({ size, noOutline }) => (noOutline ? 0 : mapSize[size].border)} solid
    ${({ outlineColor }) => outlineColor || colors.white};
  position: relative;
  .loading {
    position: absolute;
  }
  ${({ editable }) =>
    editable &&
    css`
      cursor: pointer;
      &:hover:after {
        display: flex;
        position: absolute;
        content: 'Change';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${rgba('black', 0.5)};
        color: ${colors.white};
        font-weight: ${fontWeight.bold};
        border-radius: 100%;
        justify-content: center;
        align-items: center;
      }
    `}

  img {
    object-fit: cover;
    width: 94%;
    height: 94%;
    border-radius: 50%;
  }
  i {
    font-size: ${({ size }) => mapSize[size].icon};
    background: ${colors.gray400};
    width: 94%;
    height: 94%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: ${colors.white};
  }
`;

export const StyledWrapper = styled.div<{ center?: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  ${media.xs`
    flex-flow:column;
    align-items: flex-start;
  `};
`;

export const StyledText = styled.span`
  display: inline-flex;
  font-size: ${fontSize.md};
  margin-left: ${spacer.xl};
  ${media.xs`
        font-size: ${fontSize.sm};

    `}
`;
