import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';

interface SpinnerProps {
  size?: 'xl' | 'lg' | 'md' | 'sm';
  block?: boolean;
}
const mapSize = {
  md: '1rem',
  sm: '0.75rem',
  lg: '2rem',
  xl: '3rem',
};

const Spinner = (props: SpinnerProps) => {
  return (
    <SpinnerWrapper {...props}>
      <LoadingOutlined className="loading" spin />
    </SpinnerWrapper>
  );
};

export default Spinner;

const SpinnerWrapper = styled.span<SpinnerProps>`
  ${({ block, size = 'md' }) => css`
    font-size: ${mapSize[size]};
    ${block &&
    css`
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      height: 100%;
    `}
  `}
`;
