import React from 'react';
import moment from 'moment';
import { StyledWrapper, FormInputWrapper, StyledImgTitle } from './index.styled';
import { Form, message, Select } from 'antd';
import { Button, FormItem, Authenticatable, TimePicker } from 'components/elements';
import { useCreateReminderMutation, useGetMyAccountQuery } from 'graphql/types';
import { loginModalVisible } from 'cache';

export const uiTimeFormat = 'hh:m:a';
export const serverTimeFormat = 'HH:mm:ss.SSS';
export const mapSubject = {
  nabor: 'Nabor Days',
  gohanbar: 'Gāhānbār days',
  important: 'Important days',
  semiImportant: 'Semi important days',
  ziyarat: 'Zīyārat days',
};

export const mapDaysBefore = ['Day of', '1 day before', '2 day before', '3 day before', '4 day before'];

function AddReminder() {
  const { data: accountResult } = useGetMyAccountQuery();
  const [addReminderForm] = Form.useForm();
  const [createReminder, { loading }] = useCreateReminderMutation();

  const onFinish = async (values: any) => {
    console.log(values);
    if (!accountResult?.myAccount?.data?.id) {
      return;
    }
    try {
      await createReminder({
        variables: {
          input: {
            ...values,
            time: values.time.format(serverTimeFormat),
          },
        },
      });

      message.success('Your Reminder created Successfully!');
    } catch (err) {
      if (err instanceof Error) {
        message.error(err?.message);
      }
    }
  };

  return (
    <StyledWrapper>
      <StyledImgTitle>Add Reminder</StyledImgTitle>
      <Form layout="vertical" form={addReminderForm} onFinish={onFinish} preserve={false}>
        <FormInputWrapper>
          I want to set email reminder
          <FormItem theme="primary" name="dayBefore" hasFeedback initialValue={0}>
            <Select
              style={{ width: 120 }}
              options={[
                { value: 0, label: 'Day of' },
                { value: 1, label: '1 day before' },
                { value: 2, label: '2 day before' },
                { value: 3, label: '3 day before' },
                { value: 4, label: '4 day before' },
              ]}
            />
          </FormItem>
          <FormItem theme="primary" name="subject" hasFeedback initialValue={'nabor'}>
            <Select
              options={[
                { value: 'nabor', label: 'Nabor Days' },
                { value: 'gohanbar', label: 'Gāhānbār days' },
                { value: 'important', label: 'Important days' },
                { value: 'semiImportant', label: 'Semi important days' },
                { value: 'ziyarat', label: 'Zīyārat days' },
              ]}
            />
          </FormItem>
          at
          <FormItem theme="primary" name="time" hasFeedback initialValue={moment('7:00', uiTimeFormat)}>
            <TimePicker format={uiTimeFormat} />
          </FormItem>
        </FormInputWrapper>

        <Authenticatable
          title="For Create Reminder you need to login first!"
          okText="Login"
          onConfirm={() => loginModalVisible(true)}
        >
          <Button onClick={() => addReminderForm.submit()} loading={loading} block type="primary" primaryInfo>
            Create
          </Button>
        </Authenticatable>
      </Form>
    </StyledWrapper>
  );
}
export default AddReminder;
