import React from 'react';
import moment from 'moment';
import { StyledWrapper, StyledBlock, StyledText, StyledDay } from './dateTag.styled';

interface DateTagProps {
  from: string;
  to: string;
}

const DateTag = ({ from, to }: DateTagProps) => {
  const fromObj = moment(from);
  const toObj = moment(to);
  return (
    <StyledWrapper>
      <StyledBlock>
        <StyledText>{fromObj.format('MMM, D')}</StyledText>
        <StyledDay>{fromObj.format('HH:mm a')}</StyledDay>
      </StyledBlock>
      <StyledBlock>
        <StyledText>{toObj.format('MMM, D')}</StyledText>
        <StyledDay>{toObj.format('HH:mm a')}</StyledDay>
      </StyledBlock>
    </StyledWrapper>
  );
};

DateTag.propTypes = {};

export default DateTag;
