import React from 'react';
import { breakpoints } from 'settings/style';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 } from 'uuid';
import { Skeleton } from 'antd';
import { StyledWrapper, StyledItem, StyledTitle, NavController, SwiperWrapper } from './sliderList.styled';

function LoadingCards(count: number) {
  return [...Array(count)].map(() => (
    <SwiperSlide key={v4()}>
      <Skeleton active title={false} avatar={{ shape: 'square' }} paragraph={{ rows: 4 }} />
    </SwiperSlide>
  ));
}

interface SliderListProp<T> {
  items: T[];
  renderList: (item: T) => React.ReactElement;
  title: string;
  perView?: number;
  loading?: boolean;
}

const sliderBreakpoints = (perview: number) => ({
  [breakpoints.xs]: {
    slidesPerView: perview - 3 || 1,
    spaceBetween: 20,
  },
  [breakpoints.lg]: {
    slidesPerView: perview - 2 || 1,
    spaceBetween: 30,
  },
  [breakpoints.lg]: {
    slidesPerView: perview - 1,
    spaceBetween: 40,
  },
  [breakpoints.xl]: {
    slidesPerView: perview,
    spaceBetween: 50,
  },
});

function SliderList<T>({ items = [], title, renderList, perView = 4, loading = false }: SliderListProp<T>) {
  const counter = v4();

  const nextEl = `slide-next-${counter}`;
  const prevEl = `slide-prev-${counter}`;
  return (
    <StyledWrapper>
      <StyledTitle>{title}</StyledTitle>
      <SwiperWrapper>
        <Swiper
          spaceBetween={20}
          breakpoints={sliderBreakpoints(perView)}
          navigation={{
            nextEl: `.${nextEl}`,
            prevEl: `.${prevEl}`,
          }}
        >
          {loading
            ? LoadingCards(perView)
            : items.map((item) => {
                return (
                  <SwiperSlide key={v4()}>
                    <StyledItem>{renderList(item)}</StyledItem>
                  </SwiperSlide>
                );
              })}
        </Swiper>

        <NavController className={nextEl}>
          <i className="icon-chevron-right" />
        </NavController>
        <NavController className={prevEl}>
          <i className="icon-chevron-left" />
        </NavController>
      </SwiperWrapper>
    </StyledWrapper>
  );
}

export default SliderList;
