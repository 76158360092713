import loadImage from 'blueimp-load-image';

export interface ResizeImageResult {
  blob: Blob | null;
  url: string;
}

type CropOptions = {
  width: number;
  height: number;
  x: number;
  y: number;
};

interface resizeImgProps {
  file: Blob;
  _width: number;
  _height: number;
  crop: boolean;
  cropOptions?: CropOptions;
  validate: boolean;
}

export function resizeImg({
  file,
  _width = 1200,
  _height = 800,
  crop = true,
  cropOptions,
  validate = false,
}: resizeImgProps): Promise<ResizeImageResult> {
  const width = _width;
  const height = _height;

  if (file) {
    return new Promise(function (resolve, reject) {
      loadImage(
        file,
        () => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = document.createElement('img');
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

              let imgWidth = img.width;
              let imgHeight = img.height;
              let rImgWidth = img.width;
              let rImgHeight = img.height;
              let x = 0;
              let y = 0;

              if (validate && (imgWidth < width || imgHeight < height)) {
                reject(new Error('size_validation_error'));
              }
              if (cropOptions) {
                x = cropOptions.x;
                y = cropOptions.y;
                rImgWidth = cropOptions.width;
                rImgHeight = cropOptions.height;
                imgWidth = width;
                imgHeight = height;
              } else if (crop) {
                rImgHeight = (height / width) * rImgWidth;
                if (rImgHeight > imgHeight) {
                  rImgHeight = imgHeight;
                  rImgWidth = (width / height) * imgHeight;
                }
                x = Math.max(0, (imgWidth - rImgWidth) / 2);
                y = Math.max(0, (imgHeight - rImgHeight) / 2);
                imgWidth = width;
                imgHeight = height;
              } else if (imgWidth > imgHeight) {
                if (imgWidth > width) {
                  imgHeight *= width / imgWidth;
                  imgWidth = width;
                }
              } else if (imgHeight > height) {
                imgWidth *= height / imgHeight;
                imgHeight = height;
              }

              canvas.width = imgWidth;
              canvas.height = imgHeight;

              ctx.drawImage(img, x, y, rImgWidth, rImgHeight, 0, 0, imgWidth, imgHeight);
              const dataurl = canvas.toDataURL('image/jpeg');
              canvas.toBlob(
                (blob) => {
                  resolve({ blob, url: dataurl });
                },
                'image/jpeg',
                0.85,
              );
            };
            img.src = e.target?.result as string;
          };
          reader.readAsDataURL(file);
        },
        { orientation: true },
      );
    });
  }
  return Promise.reject(new Error('file do not found!'));
}

export function isObject(value: any) {
  return value && typeof value === 'object' && value.constructor === Object;
}

export const loadMapApi = () => {
  const mapsURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&libraries=places&language=no&region=NO&v=quarterly&callback=Function.prototype`;
  const scripts = document.getElementsByTagName('script');

  // Go through existing script tags, and return google maps api tag when found.
  for (let i = 0; i < scripts.length; i += 1) {
    if (scripts[i].src.indexOf('https://maps.googleapis.com/maps/api') === 0) {
      return { script: scripts[i], loaded: true };
    }
  }

  const googleMapScript = document.createElement('script');
  googleMapScript.src = mapsURL;
  googleMapScript.async = true;
  googleMapScript.defer = true;
  window.document.body.appendChild(googleMapScript);

  return { script: googleMapScript, loaded: false };
};

const addressAttr = {
  locality: ['long_name', 'city'] as const,
  postal_code: ['long_name', 'zip'] as const,
  country: ['long_name', 'country'] as const,
};

type AddressAttr = keyof typeof addressAttr;
export interface ParseGoogleAddressResult {
  city?: string;
  zip?: string;
  country?: string;
  address: string;
  lat: number;
  lng: number;
}

export function parseGoogleAddress(address: google.maps.places.PlaceResult) {
  if (!address) {
    return null;
  }
  const addressResult = {
    lat: address.geometry?.location.lat(),
    lng: address.geometry?.location.lng(),
    address: address.formatted_address,
  } as ParseGoogleAddressResult;
  const addressComponent = address?.address_components || [];
  for (let i = 0; i < addressComponent.length; i += 1) {
    const attr = addressComponent[i];
    const attrKey = attr.types[0] as AddressAttr;
    if (addressAttr[attrKey]) {
      addressResult[addressAttr[attrKey][1]] = attr[addressAttr[attrKey][0]];
    }
  }
  return addressResult;
}

export function formatMoney(amount = 0, _decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    let decimalCount = Math.abs(_decimalCount);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';
    const newAmount = Math.abs(Number(amount) || 0).toFixed(decimalCount);
    const i = parseInt(newAmount, 10).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(+newAmount - +i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
    return '';
  }
}

/* eslint no-control-regex: "error" */
export function youtubeIdParser(url: string) {
  // const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  // const match = url.match(regExp);
  // return match && match[7].length === 11 ? match[7] : false;
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  }
  return '';
}

/* eslint no-control-regex: "error" */
export function googleIdParser(url: string) {
  const regExp = /[-\w]{25,}(?!.*[-\w]{25,})/;
  const match = url.match(regExp);
  if (match && match[0].length) {
    return match[0];
  }
  return '';
}

export function emailValidator(email: string) {
  const tester =
    /^[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (!email) return true;

  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) return false;
  if (address.length > 255) return false;

  const domainParts = address.split('.');
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  if (!tester.test(email)) return false;

  return true;
}
