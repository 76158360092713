import React, { useMemo, useEffect, useCallback } from 'react';
import { useCategoriesQuery, GetAllGroupsQueryVariables, useGetAllGroupsLazyQuery } from 'graphql/types';
import { PaginatingItems, Container, SearchSidebar } from 'components/elements';
import { WithSiderWrapper } from 'components/elements/searchSidebar';
import styled from 'styled-components';
import GroupCard from 'components/groupCard';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { spacer } from '../../settings/style';

const pageSize = 24;

export default function GroupsView() {
  const [getGroups, { data: groupResult, loading: groupLoading }] = useGetAllGroupsLazyQuery();
  const { data: categoriesResult, loading: categoriesLoading } = useCategoriesQuery();
  const categories = useMemo(
    () =>
      (categoriesResult?.categories?.data || []).map((category) => ({
        label: category.attributes.title,
        value: category.id,
        parentId: category?.attributes?.parent?.data?.id,
      })),
    [categoriesResult],
  );

  const { search } = useLocation();
  const history = useHistory();
  const searchQuery = useMemo(() => qs.parse(search, { arrayFormat: 'bracket' }), [search]);

  const updatePageParams = (searchParams: any) => {
    history.push(`/groups?${qs.stringify(searchParams, { arrayFormat: 'bracket' })}`);
  };

  const getGroupPage = useCallback(
    (page: number) => {
      const searchFilter = { pagination: { page, pageSize }, sort: 'createdAt:desc' } as GetAllGroupsQueryVariables;
      const searchFilterProps = { status: { eq: 'approved' } } as any;
      if (searchQuery?.categories) {
        searchFilterProps.categories = { id: { in: searchQuery?.categories } };
      }
      if (searchQuery?.search) {
        searchFilterProps.name = { containsi: searchQuery?.search as string };
      }

      getGroups({ variables: { ...searchFilter, filters: searchFilterProps } });
    },
    [getGroups, searchQuery],
  );

  useEffect(() => {
    getGroupPage(1);
  }, [getGroupPage, getGroups, searchQuery]);

  const groupsData = groupResult?.groups.data || [];
  const pagination = groupResult?.groups?.meta?.pagination;

  return (
    <Container>
      <WithSiderWrapper>
        <SearchSidebar
          searchDefaultValue={(searchQuery.search as string) || ''}
          onSearch={(value) => updatePageParams({ ...searchQuery, search: value })}
          filterByCategories={[
            {
              title: 'category',
              key: 'category',
              onChange: (values) => updatePageParams({ ...searchQuery, categories: values }),
              options: categories,
              defaultValue: (searchQuery.categories as string[]) || [],
            },
          ]}
        />
        <GroupListWrapper>
          <PaginatingItems
            items={groupsData}
            loading={groupLoading || categoriesLoading}
            renderList={GroupCard}
            perView={3}
            pageSize={pagination?.pageSize}
            total={pagination?.total}
            current={pagination?.page}
            onChange={(page) => getGroupPage(page)}
          />
        </GroupListWrapper>
      </WithSiderWrapper>
    </Container>
  );
}

const GroupListWrapper = styled.div`
  padding: ${spacer.xl};
  flex: 1;
`;
