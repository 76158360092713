import { spacer, colors, fontType, snippet } from 'settings/style';
import styled from 'styled-components';

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${spacer.lg} 0;
`;

export const StyledWrapper = styled.div``;

export const StyledTitle = styled.span`
  color: ${colors.font};
  margin-bottom: ${spacer.xl};
  ${fontType.lg}
  ${snippet.underline()}
`;
