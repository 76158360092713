import { spacer, colors, radius, fontType, fontWeight } from 'settings/style';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  border: 3px solid ${colors.primary};
  border-radius: ${radius.lg};
  padding: ${spacer.md};
  margin: ${spacer.md} 0;
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: ${spacer.md};
  margin: ${spacer.md} 0;
  align-items: baseline;
  font-weight: ${fontWeight.bold};
  padding-bottom: ${spacer.md};
`;

export const StyledImgTitle = styled.p`
  ${fontType.lg};
  text-align: center;
  margin-bottom: ${spacer.md};
  color: ${colors.primary400};
`;
