import { AUTH_TOKEN } from 'constant';
import LocalStore from 'helpers/localStore';

export interface LoginReq {
  identifier: string;
  password: string;
}

export interface ResetPassword {
  password: string;
  passwordConfirmation: string;
  code: string;
}
export interface RegisterReq {
  username: string;
  password: string;
  email: string;
}

export function logout() {
  LocalStore.remove(AUTH_TOKEN);
}
