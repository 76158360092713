import React from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

export interface defaultCategoryType {
  attributes?: {
    parent?: {
      data?: {
        id: string;
      };
    };
    title?: string;
    order?: number;
  };
  id?: string;
}
interface categoryCollapseTreeProps<Item> {
  renderItem: (category: Item) => React.ReactElement;
  categories?: Item[];
}

function categoryCollapseTree<T extends defaultCategoryType>({
  renderItem,
  categories = [],
}: categoryCollapseTreeProps<T>) {
  const parentCategories = (categories || [])
    .filter((category) => !category.attributes.parent?.data)
    .sort((a, b) => (a.attributes.order > b.attributes.order ? 1 : -1));

  const findSubCategories = (parentId: string) => {
    return (categories || [])
      .filter((category) => category.attributes?.parent?.data?.id === parentId)
      .sort((a, b) => (a.attributes.order > b.attributes.order ? 1 : -1));
  };

  const renderedCategoryTree = (categoryItems: T[]) => {
    if (!categoryItems || !categoryItems.length) {
      return null;
    }
    const panels = categoryItems.map((category: T) => {
      return (
        <Panel header={category.attributes.title} key={category.id}>
          <>
            {renderItem(category)}
            {renderedCategoryTree(findSubCategories(category.id))}
          </>
        </Panel>
      );
    });
    return <Collapse defaultActiveKey={categoryItems[0].id}>{panels}</Collapse>;
  };

  if (!categories || !categories.length) {
    return null;
  }
  return renderedCategoryTree(parentCategories);
}

export default categoryCollapseTree;
