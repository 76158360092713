import React, { useMemo, useCallback } from 'react';
import {
  useGetEventByIdQuery,
  useChangeRsvpStatusMutation,
  BookingStatusEnum,
  useSendEventEmailMutation,
} from 'graphql/types';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Table, Select, message, Form } from 'antd';
import SendEmailForm, { FromFields } from 'components/sendEmailForm';
import { StyledWrapper, StyledTitle } from './bookings.styled';

const { Option } = Select;

type MatchParams = { id: string };
type BookingsProps = RouteComponentProps<MatchParams>;

const Bookings = (props: BookingsProps) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [changeRsvpStatus, { loading }] = useChangeRsvpStatusMutation();
  const [form] = Form.useForm();
  const eventByIdResult = useGetEventByIdQuery({
    variables: { id },
  });
  const bookings = eventByIdResult?.data?.event?.data.attributes.bookings.data || [];

  const tableDataSource = useMemo(() => {
    return bookings.map((booking) => {
      const visitor = booking.attributes?.visitor?.data;

      if (visitor) {
        return {
          ...booking.attributes,
          userId: visitor.id,
          email: visitor.attributes.email,
        };
      }

      return {
        ...booking.attributes,
        ...booking.attributes?.user?.data?.attributes,
        userId: booking.attributes?.user?.data?.id,
      };
    });
  }, [bookings]);

  const setStatus = useCallback(
    async (status: BookingStatusEnum, userId: string) => {
      try {
        await changeRsvpStatus({
          variables: { eventId: id, userId, status },
        });
      } catch (err) {
        if (err instanceof Error) {
          message.error(err.message);
        }
      }
    },
    [changeRsvpStatus, id],
  );

  const columns = [
    {
      title: 'First name',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text: string, record: any) => <Link to={`/profile/${record.userId}`}>{text}</Link>,
    },
    {
      title: 'Last name',
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Guests',
      dataIndex: 'guests',
      key: 'guests',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: any) => (
        <Select
          value={text}
          style={{ width: 120 }}
          onChange={(status) => setStatus(status as BookingStatusEnum, record.userId)}
        >
          <Option value="approved">Approved</Option>
          <Option value="pending">Pending</Option>
          <Option value="rejected">Rejected</Option>
        </Select>
      ),
    },
  ];

  const [sendEmailMutation, { loading: sendMailLoading }] = useSendEventEmailMutation();

  const senEmail = async (values: FromFields) => {
    try {
      await sendEmailMutation({
        variables: {
          eventId: id,
          input: {
            ...values,
          },
        },
      });
      form.resetFields();
      message.success('Messages Sent to All Users Successfully!');
    } catch (err) {
      if (err instanceof Error) {
        message.error(err?.message);
      }
    }
  };

  return (
    <>
      <StyledWrapper>
        <section>
          <StyledTitle>Send email to all users</StyledTitle>
          <SendEmailForm onSubmit={senEmail} loading={sendMailLoading} form={form} />
        </section>
        <section>
          <StyledTitle>Booking Lists</StyledTitle>
          <Table columns={columns} dataSource={tableDataSource} loading={loading} />
        </section>
      </StyledWrapper>
    </>
  );
};

export default Bookings;
