import React, { useEffect, useState, useCallback } from 'react';
import { useGetMyAccountQuery, Address, useUpdateProfileMutation, UpdateProfileInput } from 'graphql/types';
import { Form, Input, Row, Col, message } from 'antd';
import { Button, Avatar, FormItem, Editable, SearchBox, ImageInput, DatePicker } from 'components/elements';
import { dateFormat } from 'settings/configs';
import { ResizeImageResult } from 'helpers';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  StyledWrapper,
  StyledProfile,
  StyledHead,
  ActionWrapper,
  StyledImgWrap,
  StyledBlock,
  StyledName,
  StyledInfo,
  StyledText,
  StyledSubText,
  StyledNameBold,
  StyledTextWrap,
  InnerStyledWrapper,
  ProfileBody,
} from './profile.styled';

interface FromFields {
  username: string;
  firstname: string;
  lastname: string;
  description: string;
  avatar: ResizeImageResult;
  address: Address;
  birthdate: moment.Moment;
}

const MyProfile = () => {
  const { data: accountResult } = useGetMyAccountQuery();
  const [updateProfile, { loading }] = useUpdateProfileMutation();

  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState(false);

  const setForm = useCallback(() => {
    if (accountResult?.myAccount) {
      const { firstname, lastname, description, address, avatarUrl, birthdate, username } =
        accountResult?.myAccount?.data?.attributes;
      const { __typename, ...restAddress } = address?.data?.attributes || {};

      form.setFieldsValue({
        username,
        firstname,
        lastname,
        description,
        address: isEmpty(restAddress) ? null : restAddress,
        avatar: { blob: null, url: avatarUrl },
        birthdate: birthdate && moment(birthdate),
      });
    }
  }, [accountResult, form]);

  const isEditableHandler = (): void => {
    setIsEditable(true);
  };
  const discardForm = (): void => {
    setForm();
    setIsEditable(false);
  };
  const onSubmitForm = (): void => {
    form.submit();
  };

  const onFinish = async ({ avatar, ...values }: FromFields) => {
    const attributes: UpdateProfileInput = { ...values };
    if (avatar?.blob) {
      attributes.avatar = avatar?.blob;
    }

    try {
      await updateProfile({
        variables: { input: attributes },
      });
      setIsEditable(false);
    } catch (err) {
      // err
      if (err instanceof Error) {
        message.error(err.message);
      }
    }
  };

  const Capitalize = (str: string) => {
    const firsrChar = str ? str.charAt(0).toLocaleUpperCase() : '';
    const restChar = str ? str.substring(1).toLocaleLowerCase() : '';
    return `${firsrChar}${restChar}`;
  };

  useEffect(() => {
    setForm();
  }, [form, accountResult, setForm]);

  return (
    <StyledWrapper>
      <Form form={form} onFinish={onFinish}>
        <StyledHead>
          {isEditable ? (
            <ActionWrapper>
              <Button color="font" type="primary" ghost onClick={discardForm}>
                Discard
              </Button>
              <Button type="primary" onClick={onSubmitForm} loading={loading}>
                Save Changes
              </Button>
            </ActionWrapper>
          ) : (
            <Button color="font" type="primary" ghost onClick={isEditableHandler} leftIcon="icon-pen">
              Edit Profile
            </Button>
          )}
          <StyledProfile>
            <InnerStyledWrapper>
              <StyledBlock>
                <StyledTextWrap>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <FormItem name="firstname" noStyle bordered>
                      <Editable
                        isEditable={isEditable}
                        preview={({ value }) => <StyledNameBold>{Capitalize(value)}</StyledNameBold>}
                        input={Input}
                      />
                    </FormItem>
                    <FormItem name="lastname" noStyle bordered>
                      <Editable
                        isEditable={isEditable}
                        preview={({ value }) => <StyledNameBold>{Capitalize(value)}</StyledNameBold>}
                        input={Input}
                      />
                    </FormItem>
                  </div>
                  <FormItem name="username" noStyle bordered>
                    <Editable
                      isEditable={isEditable}
                      preview={({ value }) => <StyledName>{Capitalize(value)}</StyledName>}
                      input={Input}
                    />
                  </FormItem>
                </StyledTextWrap>
              </StyledBlock>
              <StyledBlock>
                <StyledInfo>
                  <i className="icon-pin" />
                  <StyledText>Location :</StyledText>
                  <FormItem name="address" noStyle bordered>
                    <Editable
                      isEditable={isEditable}
                      preview={({ value }) => (
                        <StyledName>
                          {value?.country}
                          {value?.city && `, ${value?.city}`}
                        </StyledName>
                      )}
                      input={SearchBox}
                    />
                  </FormItem>
                </StyledInfo>
                <StyledInfo>
                  <i className="icon-birthday" />
                  <StyledText>Birthday :</StyledText>
                  <FormItem name="birthdate" noStyle bordered>
                    <Editable
                      isEditable={isEditable}
                      preview={({ value }) => <StyledName>{value ? moment(value).format(dateFormat) : ''}</StyledName>}
                      input={DatePicker}
                    />
                  </FormItem>
                </StyledInfo>
                <StyledInfo>
                  <i className="icon-calendar2" />
                  <StyledText>Joined date : </StyledText>
                  <StyledSubText>
                    {moment(accountResult?.myAccount?.data?.attributes?.createdAt).format(dateFormat)}
                  </StyledSubText>
                </StyledInfo>
              </StyledBlock>
              <StyledImgWrap>
                <FormItem name="avatar" noStyle bordered>
                  <Editable
                    isEditable={isEditable}
                    preview={({ value }) => <Avatar avatarUrl={value?.url} size="lg" />}
                    input={({ value, onChange }) => (
                      <ImageInput width={200} height={200} cropping onChange={onChange}>
                        <Avatar avatarUrl={value?.url} size="lg" editable />
                      </ImageInput>
                    )}
                  />
                </FormItem>
              </StyledImgWrap>
            </InnerStyledWrapper>
          </StyledProfile>
        </StyledHead>
      </Form>
      <ProfileBody>
        {/* <div style={{ display: 'block', width: '40%' }}>
          <StyledTitle>My favorite photos</StyledTitle>
          <FavoriteImg src={favoritesimg} />
        </div> */}
        <Row>
          <Col md={24} lg={30}>
            <Form form={form} onFinish={onFinish} />
          </Col>
          {/* <Col md={24} lg={30}>
            <InvitationForm />
          </Col> */}
        </Row>
      </ProfileBody>
    </StyledWrapper>
  );
};

export default MyProfile;
