import { spacer, colors, fontType, fontSize, fontWeight, shadow, radius } from 'settings/style';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledWrapper = styled(Link)`
  background-color: ${colors.white};
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: ${shadow.md};
  border-radius: ${radius.lg};
  overflow: hidden;
  color: ${colors.font};
  padding: ${spacer.md};
  width: 100%;
  &:hover{
    border-bottom: 1px solid ${colors.accent};
  }
`;

export const StyledImgTitle = styled.p`
  ${fontType.md};
  color: ${colors.accent};
`;

export const StyledPostDate = styled.div`
  
  color: ${colors.font};
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.bold};
  padding-bottom: ${spacer.lg};
`;

export const StyledContent = styled.div`
  color: ${colors.font};
  font-weight: ${fontWeight.normal};
`;

export const StyledReadMore = styled.span`
  display: flex;
  justify-content: flex-end;
  color: ${colors.accent};
  font-weight: ${fontWeight.heavy};
  padding: ${spacer.lg}: 
`;
