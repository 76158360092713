import React, { useMemo } from 'react';
import { SocialMedia, DateTag } from 'components/elements';
import { formatMoney } from 'helpers';
import {
  StyledWrapper,
  StyledBlock,
  StyledHeadCard,
  StyledTitle,
  StyledHeadWrap,
  StyledHeadText,
  StyledHeadTextBold,
  StyledHeadImgWrap,
  StyledHeadWrapper,
  StyledRecurringText,
} from './singleEventHead.styled';
import RsvpEventForm from './rsvpEventForm';

interface SingleEventHeadProp {
  address: { lng: number; lat: number; address: string; city?: string };
  coverPhotoUrl: string;
  title: string;
  startTime: string;
  endTime: string;
  price?: number;
  id: string;
  remainingSeats?: number;
  isPast?: boolean;
  repeatInterval?: number;
}

const repeatIntervalMap = {
  1: 'day',
  7: 'week',
  30: 'month',
} as any;

const SingleEventHead = ({
  coverPhotoUrl,
  title,
  price,
  startTime,
  endTime,
  id,
  remainingSeats,
  isPast,
  repeatInterval,
}: SingleEventHeadProp) => {
  const recurringText = useMemo(() => {
    if (!repeatInterval) {
      return null;
    }
    const label = repeatIntervalMap[repeatInterval];
    return `Event repeats every ${label || `${repeatInterval} day`}`;
  }, [repeatInterval]);
  return (
    <StyledWrapper>
      <StyledBlock>
        <StyledHeadImgWrap coverPhotoUrl={coverPhotoUrl} />
      </StyledBlock>
      <StyledBlock>
        <StyledTitle>{title}</StyledTitle>
        <StyledHeadWrapper>
          <DateTag from={startTime} to={endTime} />
          {repeatInterval ? <StyledRecurringText>{recurringText}</StyledRecurringText> : null}
          <StyledHeadCard>
            <StyledHeadWrap fullWidth={isPast}>
              <StyledHeadText>Price</StyledHeadText>
              <StyledHeadTextBold>{price ? `$ ${formatMoney(price)}` : 'Free'}</StyledHeadTextBold>
            </StyledHeadWrap>
            {!isPast && <RsvpEventForm eventId={id} remainingSeats={remainingSeats} />}
          </StyledHeadCard>
        </StyledHeadWrapper>
        <SocialMedia eventId={id} />
      </StyledBlock>
    </StyledWrapper>
  );
};

export default SingleEventHead;
