import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useGetAllGroupsQuery } from 'graphql/types';
import SingleGroupHead from 'containers/singleGroup/singleGroupHead';
import SingleGroupContent from 'containers/singleGroup/singleGroupContent';
import SingleGroupBottom from 'containers/singleGroup/singleGroupBottom';
import { Tabs } from 'components/elements';
import { map } from 'lodash';
import { StyledWrapper, StyledLayout, StyledTabs, StyledContent, StyledWrap } from './index.styled';

type MatchParams = { slug: string };
type SingleGroupProps = RouteComponentProps<MatchParams>;

export default function SingleGroup(props: SingleGroupProps) {
  const {
    match: {
      params: { slug },
    },
  } = props;

  const { data: groupByIdSlugResult } = useGetAllGroupsQuery({
    variables: { filters: { slug: { eq: slug } } },
  });

  const selectedGroupResult = useMemo(() => {
    return groupByIdSlugResult?.groups.data && groupByIdSlugResult?.groups.data.length
      ? groupByIdSlugResult?.groups.data[0]
      : null;
  }, [groupByIdSlugResult]);

  const selectedGroupAttribute = selectedGroupResult?.attributes;
  return (
    <StyledWrapper>
      <StyledLayout>
        <StyledWrap>
          <StyledTabs>
            <Tabs
              items={[
                { text: 'About Group', path: '#aboutGroup' },
                { text: 'Up Coming Events', path: '#upCommingEvents' },
                { text: 'Past Events', path: '#pastEvents' },
                { text: 'Members', path: '#members' },
              ]}
              width="105px"
              height="150px"
              refTag
            />
            <div />
          </StyledTabs>
          <StyledContent>
            <SingleGroupHead
              coverPhotoUrl={selectedGroupAttribute?.coverPhotoUrl}
              description={selectedGroupAttribute?.description}
              members={map(selectedGroupAttribute?.members?.data, 'attributes')}
              organizers={map(selectedGroupAttribute?.organizers?.data, 'attributes')}
              city={selectedGroupAttribute?.address?.data?.attributes?.city}
              groupType={selectedGroupAttribute?.groupType}
              name={selectedGroupAttribute?.name}
              slug={selectedGroupAttribute?.slug}
              groupId={selectedGroupResult?.id}
            />
            <SingleGroupContent
              users={map(selectedGroupAttribute?.members?.data, 'attributes')}
              events={selectedGroupAttribute?.events?.data}
            />
            <SingleGroupBottom
              name={selectedGroupAttribute?.name}
              slug={selectedGroupAttribute?.slug}
              groupId={selectedGroupResult?.id}
            />
          </StyledContent>
        </StyledWrap>
      </StyledLayout>
    </StyledWrapper>
  );
}
