import React, { useMemo } from 'react';
import { useGetAllGroupsQuery, useSendGroupEmailMutation } from 'graphql/types';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Table, message, Form } from 'antd';
import SendEmailForm, { FromFields } from 'components/sendEmailForm';
import { StyledWrapper, StyledTitle } from './index.styled';
import { map } from 'lodash';

type MatchParams = { id: string };
type BookingsProps = RouteComponentProps<MatchParams>;

const Bookings = (props: BookingsProps) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [form] = Form.useForm();
  const { data, loading } = useGetAllGroupsQuery({
    variables: { filters: { id: { eq: id } } },
  });
  const groupData = useMemo(() => {
    return data?.groups.data && data?.groups.data.length ? data?.groups.data[0] : null;
  }, [data]);

  const columns = [
    {
      title: 'First name',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text: string, record: any) => <Link to={`/profile/${record.userId}`}>{text}</Link>,
    },
    {
      title: 'Last name',
      dataIndex: 'lastname',
      key: 'lastname',
    },
  ];

  const [sendEmailMutation, { loading: sendMailLoading }] = useSendGroupEmailMutation();

  const senEmail = async (values: FromFields) => {
    try {
      await sendEmailMutation({
        variables: {
          groupId: id,
          input: {
            ...values,
          },
        },
      });
      form.resetFields();
      message.success('Messages Sent to All Users Successfully!');
    } catch (err) {
      if (err instanceof Error) {
        message.error(err?.message);
      }
    }
  };

  return (
    <>
      <StyledWrapper>
        <section>
          <StyledTitle>Send email to all members</StyledTitle>
          <SendEmailForm onSubmit={senEmail} loading={sendMailLoading} form={form} />
        </section>
        <section>
          <StyledTitle>Group Member List</StyledTitle>
          <Table
            columns={columns}
            dataSource={map(groupData?.attributes.members.data || [], 'attributes')}
            loading={loading}
          />
        </section>
      </StyledWrapper>
    </>
  );
};

export default Bookings;
