import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment-jalaali';
import { v4 } from 'uuid';
import { Button } from 'components/elements';
import AddReminder from 'components/addReminder';
import RemoveReminderList from 'components/removeReminderList';
import EventCard from 'components/eventCard';
import { zDateDetails, zStartEndOfMonth, zMonth, zYear, EventType } from '@polgozar/utils';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { maxWinSize } from 'settings/style';
import {
  LegendIcon,
  StyledWrapper,
  WeekDayWrapper,
  DayWrapper,
  WeekRowWrapper,
  CalendarWrapper,
  HeaderNavWrapper,
  HeaderNavTitle,
  HeaderNavAction,
  WeekHeaderWrapper,
  CalendarDetailsWrapper,
  DetailsHeader,
  DetailsContainer,
  EventBar,
  AddEventWrapper,
  LegendWrapper,
  LegendRow,
  PersianDate,
  GeorgianDate,
  PartyDay,
  NaborDay,
  ZEvent,
  ZDayName,
  ZDetails,
  EventDay,
  PersianDay,
  GeorgianDay,
  InnerContent,
  EventSpaceBar,
  EventWrapper,
  EventTitle,
} from './index.styled';
import { useGetMyAccountQuery } from 'graphql/types';

const today = moment();
let weekDays = maxWinSize('md') ? moment.weekdaysShort() : moment.weekdays();

const isStartOnMonday = true;
if (isStartOnMonday) {
  weekDays = [...weekDays.slice(1), weekDays[0]];
}
const weekTypeFormat = isStartOnMonday ? 'isoWeek' : 'week';

const Calendar = () => {
  const { data: accountResult } = useGetMyAccountQuery();

  const [currentMonth, setCurrentMonth] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment());
  const history = useHistory();

  const findUserBooking = useCallback(
    (date: moment.Moment) => {
      const bookings = accountResult?.myAccount?.data?.attributes?.bookings?.data;
      if (!bookings || !bookings.length) {
        return [];
      }
      return bookings
        .filter((booking) => {
          const { startTime, endTime } = booking.attributes.event.data.attributes;
          return date.isSameOrAfter(moment(startTime)) && date.isSameOrBefore(moment(endTime));
        })
        .map((booking) => booking.attributes.event.data);
    },
    [accountResult],
  );

  const selectedDateUserEvents = useMemo(() => {
    const events = findUserBooking(selectedDate);
    if (!events || !events.length) {
      return null;
    }
    return (
      <EventWrapper>
        <EventTitle>Your Scheduled Event</EventTitle>
        {events.map((event) => (
          <EventCard {...event.attributes} />
        ))}
      </EventWrapper>
    );
  }, [findUserBooking, selectedDate]);

  const georgianNames = useMemo(() => {
    const startEndMonth = zStartEndOfMonth(currentMonth.clone());
    if (startEndMonth[0].format('MMM') !== startEndMonth[1].format('MMM')) {
      return [startEndMonth[0].format('MMMM'), startEndMonth[1].format('MMMM')];
    }
    return [startEndMonth[0].format('MMMM')];
  }, [currentMonth]);

  const renderDays = () => {
    const days = weekDays.map((weekName) => <WeekDayWrapper key={weekName}>{weekName}</WeekDayWrapper>);

    return <WeekHeaderWrapper>{days}</WeekHeaderWrapper>;
  };

  interface EventProps {
    event: EventType;
    disabled?: boolean;
    spacer?: boolean;
  }
  const Event = ({ event, disabled = false, spacer = false }: EventProps) => {
    if (event.hideOnCalendar) {
      return null;
    }
    return (
      <>
        {event.duration ? (
          <EventBar
            span={event.duration}
            openEnded={event.openEnded}
            openStarted={event.openStarted}
            disabled={disabled}
            type={event.type}
          >
            {event.en}
          </EventBar>
        ) : (
          <div>{event.en}</div>
        )}
        {spacer && <EventSpaceBar />}
      </>
    );
  };

  const renderCells = () => {
    const startEndMonth = zStartEndOfMonth(currentMonth.clone());
    const monthStart = startEndMonth[0]; // currentMonth.clone().startOf('month');
    const monthEnd = startEndMonth[1]; // monthStart.clone().endOf('month');
    const startDate = monthStart.clone().startOf(weekTypeFormat);
    const endDate = monthEnd.clone().endOf(weekTypeFormat);
    const dateFormat = 'D';
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = '';
    let globalSpacer: number[] = [];
    while (day <= endDate) {
      for (let i = 0; i < 7; i += 1) {
        formattedDate = day.format(dateFormat);
        const clonedDay = day.clone();
        const zData = zDateDetails(day, weekTypeFormat);
        const disabled = zMonth(day).month !== zMonth(monthStart).month;
        const spacer = zData.events.filter((item) => !!item.duration).map((item) => item.duration);
        const globalSpacerCount = globalSpacer.length;
        globalSpacer = [...globalSpacer, ...spacer];

        let spaceCount = globalSpacer.length || 0;
        let preSpacer: JSX.Element[] = [];
        if (globalSpacerCount) {
          preSpacer = [...Array(globalSpacerCount)].map(() => <EventSpaceBar />);
          spaceCount -= globalSpacerCount;
        }
        globalSpacer = globalSpacer.map((space) => space - 1).filter((item) => !!item);

        const events = findUserBooking(day);

        days.push(
          <DayWrapper
            disabled={disabled} //! monthStart.isSame(day, 'month')
            selected={selectedDate.isSame(day, 'day')}
            isToday={today.isSame(day, 'day')}
            key={v4()}
            onClick={() => onDateClick(clonedDay)}
            labelColor={zData.events && zData.events.length && zData.events[0]?.labelColor}
            bgImage={zData.events && zData.events.length && zData.events[0]?.bgImage}
          >
            <InnerContent>
              <GeorgianDay disabled={disabled}>{formattedDate}</GeorgianDay>
              <PersianDay disabled={disabled}>{zData.day}</PersianDay>
              <ZDetails>
                <ZDayName disabled={disabled}>{zData.name.en}</ZDayName>
                {!!zData.events && (
                  <ZEvent disabled={disabled}>
                    {preSpacer}
                    {zData.events.map((e) => {
                      const isSpacer = spaceCount > 0;
                      spaceCount -= 1;
                      return <Event event={e} disabled={disabled} spacer={isSpacer} />;
                    })}
                  </ZEvent>
                )}
              </ZDetails>
              {zData.isNabor && (
                <NaborDay disabled={disabled}>
                  <span role="img" aria-label="nabor image">
                    🌱
                  </span>
                  Nabor
                </NaborDay>
              )}
              {zData.isParyDay && (
                <PartyDay>
                  <span role="img" aria-label="party image">
                    ♫
                  </span>
                </PartyDay>
              )}
            </InnerContent>
            {!!events.length && (
              <EventDay>
                <span role="img" aria-label="event image">
                  🔥
                </span>
              </EventDay>
            )}
            {/* </div> */}
          </DayWrapper>,
        );
        day = day.add(1, 'day');
      }
      rows.push(<WeekRowWrapper key={v4()}>{days}</WeekRowWrapper>);
      days = [];
    }
    return <div>{rows}</div>;
  };

  const onDateClick = (day: moment.Moment) => {
    setSelectedDate(day);
  };

  const nextMonth = () => {
    const startEndMonth = zStartEndOfMonth(currentMonth.clone());
    const a = startEndMonth[1].clone().add(1, 'day');

    setCurrentMonth(a);
  };

  const prevMonth = () => {
    const startEndMonth = zStartEndOfMonth(currentMonth.clone());
    setCurrentMonth(startEndMonth[0].subtract(2, 'day'));
  };

  const gotToToday = () => {
    setCurrentMonth(moment());
  };

  const todayZdata = zDateDetails(selectedDate, weekTypeFormat);
  const currentZmonth = useMemo(() => {
    return zMonth(currentMonth);
  }, [currentMonth]);
  const curentZyear = zYear(currentMonth);

  const renderHeader = () => {
    return (
      <HeaderNavWrapper>
        <HeaderNavTitle>
          <GeorgianDate>
            {georgianNames.join(' - ')} {currentMonth.format('YYYY')}
          </GeorgianDate>
          <PersianDate>
            {currentZmonth.name.en} {curentZyear}
          </PersianDate>
        </HeaderNavTitle>
        <HeaderNavAction>
          <Button onClick={prevMonth} danger type="primary" ghost leftIcon="icon-chevron-left">
            Previous Month
          </Button>
          <Button onClick={gotToToday} danger type="primary" ghost>
            Today
          </Button>
          <Button onClick={nextMonth} danger type="primary" ghost rightIcon="icon-chevron-right">
            Next Month
          </Button>
        </HeaderNavAction>
      </HeaderNavWrapper>
    );
  };
  return (
    <StyledWrapper>
      <Row>
        <Col xs={24} lg={16}>
          <CalendarWrapper>
            {renderHeader()}
            {renderDays()}
            {renderCells()}
          </CalendarWrapper>
        </Col>
        <Col xs={24} lg={8}>
          <CalendarDetailsWrapper>
            <DetailsHeader>
              More about the month of {currentZmonth.name.en} and the day of {todayZdata.name.en}
            </DetailsHeader>
            <DetailsContainer>
              <ul>
                <li>{selectedDate.format('D MMMM YYYY')} in Gregorian calendar</li>
                <li>
                  {todayZdata.day} {currentZmonth.name.en} {curentZyear} in Zoroastrian calendar
                </li>
                {todayZdata.isNabor && <li>Its Nabor day</li>}
                <li>Name of the day is {todayZdata.name.en}</li>
                {todayZdata.events.map((event) => (
                  <li>{event.en}</li>
                ))}
                {!!todayZdata.name.info && <li>{todayZdata.name.info}</li>}
              </ul>
            </DetailsContainer>
            {selectedDateUserEvents}
            <RemoveReminderList />
            <AddReminder />
            <AddEventWrapper>
              <Button onClick={() => history.push('/events')} type="primary">
                Add More Events to the Calendar
              </Button>
            </AddEventWrapper>
            <LegendWrapper>
              <LegendRow>
                <LegendIcon type="today" /> Today date
              </LegendRow>
              <LegendRow>
                <LegendIcon type="important" /> Important days
              </LegendRow>
              <LegendRow>
                <LegendIcon type="semiImportant" /> Semi important days
              </LegendRow>
              <LegendRow>
                <LegendIcon type="selected" /> User Selected Day
              </LegendRow>
              <LegendRow>
                <LegendIcon type="gohanbar" /> Gāhānbār days
              </LegendRow>
              <LegendRow>
                <LegendIcon type="ziyarat" /> Zīyārat days
              </LegendRow>
              <LegendRow>
                <LegendIcon type="nabor">
                  <span role="img" aria-label="nabor image">
                    🌱
                  </span>
                </LegendIcon>
                Nabor Days
              </LegendRow>
              <LegendRow>
                <LegendIcon type="party">
                  <span role="img" aria-label="party image">
                    ♫
                  </span>
                </LegendIcon>
                Dei celebration Days
              </LegendRow>
            </LegendWrapper>
          </CalendarDetailsWrapper>
        </Col>
      </Row>
    </StyledWrapper>
  );
};

export default Calendar;
